const LANGUAGE = {
	HOW_TO_REGISTER: {
		EN: 'How to Register',
		JP: '登録方法',
		KR: '등록 방법',
		CN: '如何注册',
	},
	SUPPORT: {
		EN: 'Support',
		JP: 'サポート',
		KR: '지원',
		CN: '協助',
	},
	CONTACT_US: {
		EN: 'CONTACT US',
		JP: 'お問い合わせ',
		KR: '문의하기',
		CN: '联系我们',
	},
	TRAVEL_RESTRICTIONS: {
		EN: 'Travel Restrictions',
		JP: '渡航制限',
		KR: '여행 제한 조치',
		CN: '旅行限制',
	},
	FAQ: {
		EN: 'FAQ',
		JP: 'よくある質問',
		KR: '자주하는 질문',
		CN: '常問問題',
	},
	PRIVACY_STATEMENT: {
		EN: 'Privacy Statement',
		JP: 'プライバシーに関する声明',
		KR: '개인 정보 보호 정책',
		CN: '隐私声明',
	},
	HELP: {
		EN: 'Help',
		JP: 'ヘルプ',
		KR: '돕다',
		CN: '帮助',
	},
	HOTLINES: {
		EN: 'Hotlines',
		JP: 'ホットライン',
		KR: '직통 전화',
		CN: '熱線',
	},
	SOLUTIONS: {
		EN: 'Solutions',
		JP: 'ソリューション',
		KR: '해결책',
		CN: '解決方法',
	},
	GET_CONNECT: {
		EN: 'Get Connect With Us',
		JP: '私たちとつながる',
		KR: '연락처',
		CN: '與我們聯繫',
	},
	BUY_PHONE_SERVICE: {
		EN: 'Buy Phone Service',
		JP: '電話サービスを購入する',
		KR: '전화 서비스 구매',
		CN: '購買電話服務',
	},
	PROTECT_OURSELVES: {
		EN: 'Protect Ourselves',
		JP: '私たちの身を守る',
		KR: '자신을 보호하세요',
		CN: '保護自己',
	},
	PROTECT_LOVED_ONES: {
		EN: 'Protect Our Loved Ones',
		JP: '私たちの愛する人を守る',
		KR: '사랑하는 사람을 보호하세요',
		CN: '保護我們的親人',
	},
	PROTECT_COMMUNITY: {
		EN: 'Protect Our Community',
		JP: 'コミュニティを守る',
		KR: '우리의 커뮤니티를 보호 하세요',
		CN: '保護我們的社區',
	},
	ESTABLISHMENT_REGISTER: {
		EN: 'Establishment Register / Login',
		JP: '登録/ログイン',
		KR: '설립 등록 / 로그인',
		CN: '設立註冊/登入',
	},
	TRAVELER_REGISTER: {
		EN: 'Signup / Login',
		JP: 'サインアップ/ログイン',
		KR: '회원 가입 /로그인',
		CN: '註冊/登入',
	},
	TRAVELER_LOGGED_IN: {
		EN: 'Go to your Account',
		JP: 'アカウントに移動します',
		KR: '귀하의 계정으로 이동',
		CN: '前往您的帐户',
	},
	INSTRUCTIONAL_VIDEO: {
		EN: 'Watch Instructional Video',
		JP: '解説ビデオを見る',
		KR: '교육용 비디오 보기',
		CN: '觀看教學影片',
	},
	REGISTER_YOURSELF: {
		EN: 'Register Yourself With Us',
		JP: '私たちに登録してください',
		KR: '등록하기',
		CN: '向我們報名',
	},
	WHY_SAFE_TRAVELS: {
		EN: 'Why Travel Marianas',
		JP: '安全なマリアナ旅行を選ぶ理由',
		KR: '마리아나를 방문하는 이유',
		CN: '為何安全旅行北馬里亞納群島',
	},
	ORGANIZATIONS: {
		EN: 'Organizations & Government',
		JP: '組織と政府',
		KR: '조직 및 정부',
		CN: '政府組織',
	},
	BUSINESS_ESTABLISHMENTS: {
		EN: 'Business Establishments',
		JP: '事業所',
		KR: '사업 설립',
		CN: '商業機構',
	},
	TRAVELERS_NON_TRAVELERS: {
		EN: 'For Travelers and Non-Travelers',
		JP: '旅行者と非旅行者向け',
		KR: '여행자 및 비여행자용',
		CN: '對於旅行者和非旅行者',
	},
	CLICK_TO_REGISTER: {
		EN: 'Click To Register',
		JP: 'クリックして登録',
		KR: '가입하려면 클릭하세요',
		CN: '點擊註冊',
	},
	LEARN_HOW_TO_DOWNLOAD: {
		EN: 'Learn How to Download the App In the Browser',
		JP: 'ブラウズでアプリをダウンロードする方法',
		KR: '브라우저에서 앱 다운 로드 방법',
		CN: '了解如何在瀏覽器中下載應用程序',
	},
	LEARN_HOW: {
		EN: 'Learn How',
		JP: '方法を知る',
		KR: '방법',
		CN: '學習如何',
	},
	APPROVED: {
		EN: 'Approved By',
		JP: 'によって承認された',
		KR: '승인자',
		CN: '由...批准',
	},
	OWNED_BY: {
		EN: 'Owned By',
		JP: 'が所有している',
		KR: '소유자',
		CN: '擁有者',
	},
	SUPPORTED_BY: {
		EN: 'Supported By',
		JP: 'が支援',
		KR: '지원센터',
		CN: '支持者',
	},
	// Register
	REGISTER_CATALOG: {
		EN: 'Stay Healthy. Stay Safe.',
		JP: '健康を維持。お元気で。',
		KR: '당신의 건강과 안전이 최우선이빈다. ',
		CN: '保持健康及安全。',
	},
	SIGN_IN: {
		EN: 'Sign In',
		JP: 'サインイン',
		KR: '로그인',
		CN: '登入',
	},
	PRIVACY_NOTICE: {
		EN: 'By clicking Continue, you agree with the Terms of Use and Privacy Notice',
		JP: '[続行]をクリックすると、利用規約とプライバシー条項に同意したことになります',
		KR: '계속하기 위해 클릭 시 사용 약관 및 개인 정보 보호 고지에 동의함을 의미 합니다.',
		CN: '點擊繼續，即表示您同意使用條款和隱私聲明',
	},
	PRIVACY_NOTICE_HEADER: {
		EN: 'Privacy Notice',
		JP: 'プライバシー条項',
		KR: '개인 정보 보호 알림',
		CN: '隱私聲明',
	},
	GOOGLE: {
		EN: 'Sign In with Google',
		JP: 'Googleでサインイン',
		KR: '구글로 로그인',
		CN: '用谷歌登錄',
	},
	MOBILE_NUMBER: {
		EN: 'Sign In with Mobile Number',
		JP: '携帯番号でサインイン',
		KR: '휴대폰 번호로 로그인',
		CN: '用手機號登錄',
	},
	EMAIL: {
		EN: 'Sign In with Email Address',
		JP: 'メールアドレスでサインイン',
		KR: '이메일 주소로 로그인',
		CN: '使用電子郵件地址登錄',
	},
	FACEBOOK: {
		EN: 'Sign In with Facebook',
		JP: 'Facebookでサインイン',
		KR: '페이스북으로 로그인',
		CN: '用臉書登錄',
	},
	YAHOO: {
		EN: 'Sign In with Yahoo',
		JP: 'Yahooでサインイン',
		KR: '야후로 로그인',
		CN: '使用雅虎登錄',
	},
	MICROSOFT: {
		EN: 'Sign In with Microsoft',
		JP: 'Microsoftでサインイン',
		KR: '마이크로소프트로 로그인',
		CN: '使用 Microsoft 登錄',
	},
	WECHAT: {
		EN: 'WeChat',
		JP: 'WeChat',
		KR: '위챗',
		CN: '微信',
	},
	KAKAO: {
		EN: 'KakaoTalk',
		JP: 'カカオトーク',
		KR: '카카오 톡',
		CN: 'KakaoTalk',
	},
	LINE: {
		EN: 'Line',
		JP: 'LINE',
		KR: '라인',
		CN: '线',
	},
	// OTP
	VERIFY: {
		EN: 'Verify',
		JP: '確認',
		KR: '확인',
		CN: '核實',
	},
	DELETE: {
		EN: 'Delete',
		JP: '消去',
		KR: '삭제',
		CN: '删除',
	},
	CANCEL: {
		EN: 'Cancel',
		JP: 'キャンセル',
		KR: '취소',
		CN: '取消',
	},
	CONFIRM: {
		EN: 'CONFIRM',
		JP: '確認する',
		KR: '확인하다',
		CN: '确认',
	},
	// MENU
	VIEW_ATTACHMENT: {
		EN: 'View Attachment',
		JP: '添付ファイルを表示',
		KR: '첨부파일 보기',
		CN: '查看附件',
	},
	VACCINATION_QR_ID: {
		EN: 'Vaccination QR ID',
		JP: '予防接種QR ID',
		KR: '예방접종 QR ID',
		CN: '疫苗接種二維碼',
	},
	VACCINATION_CERTIFICATE: {
		EN: 'Vaccination Certificate',
		JP: '予防接種証明書',
		KR: '예방 접종 증명서',
		CN: '疫苗接種證書',
	},
	UPDATE_PROFILE: {
		EN: 'Update Profile',
		JP: 'プロフィールを更新',
		KR: '프로필 업데이트',
		CN: '更新個人訊息',
	},
	HEALTH_CHECK: {
		EN: 'Health Check',
		JP: '健康診断',
		KR: '건강 체크',
		CN: '健康檢查',
	},
	TRIP_HISTORY: {
		EN: 'Trip History',
		JP: '旅行履歴',
		KR: '여행 이력',
		CN: '旅遊紀錄',
	},
	VACCINATION_RECORD: {
		EN: 'Vaccination Record',
		JP: '予防接種記録',
		KR: '예방접종 기록',
		CN: '疫苗接種記錄',
	},
	RTPCR_ANTIGEN_RECORD: {
		EN: 'RT-PCR and Antigen Record',
		JP: 'RT-PCRと抗原記録',
		KR: 'RT-PCR 및 항원 기록',
		CN: 'RT-PCR 和抗原記錄',
	},
	ANTIGEN: {
		EN: 'Antigen',
		JP: '抗原',
		KR: '항원',
		CN: '抗原',
	},
	TERMS_PRIVACY_NOTICE: {
		EN: 'Terms and Privacy Notice',
		JP: '利用規約とプライバシー通知',
		KR: '약관 및 개인정보 보호정책',
		CN: '條款和隱私聲明',
	},
	LOGOUT: {
		EN: 'Logout',
		JP: 'ログアウト',
		KR: '로그 아웃',
		CN: '登出',
	},
	VACCINATION_QR_MODAL: {
		EN: 'Please keep this record card, which includes medical information about the vaccines you have received.',
		JP: 'あなたが受けたワクチンに関する医療情報が記載されたこの記録カードを保管してください。',
		KR: '귀하가 접종한 백신에 대한 의료 정보가 포함된 이 기록 카드를 보관하십시오.',
		CN: '請保留此記錄卡，其中包含有關您接種的疫苗的醫療訊息。',
	},
	DISMISS: {
		EN: 'Dismiss',
		JP: '解散',
		KR: '해지',
		CN: '解雇',
	},
	PRINT_VACCINATION_ID: {
		EN: 'Print Vaccination QR ID',
		JP: '予防接種QR IDを印刷する',
		KR: '예방접종 QR ID 인쇄',
		CN: '列印疫苗接種二维碼',
	},
	DOWNLOAD_QR_ID: {
		EN: 'Download Vaccination QR ID',
		JP: `予防接種のQR IDをダウンロード'`,
		KR: '백신 QR ID 다운로드',
		CN: '下載疫苗接種二维碼',
	},
	UPDATE_VACCINE_RECORD: {
		EN: 'UPDATE VACCINE RECORDS',
		JP: 'ワクチン記録の更新',
		KR: '백신 기록 업데이트',
		CN: '更新疫苗紀錄',
	},
	ADAI_AND_TIROW_FIRST_SENTENCE: {
		EN: 'The CNMI Government is committed to provide an easy experience to all users on the Site.',
		JP: 'CNMI 政府は、サイトを利用される全ユーザーに簡単な体験を提供することを約束します。',
		KR: 'CNMI 정부는 사이트의 모든 사용자에게 쉬운 경험을 제공하기 위해 최선을 다하고 있습니다.',
		CN: '美國北馬里亞納群島聯邦政府致力於在網站上提供使用者輕鬆的體驗。',
	},
	ADAI_AND_TIROW_SECOND_SENTENCE: {
		EN: "To help you do that, here are some simple instructions when you're here.",
		JP: 'そのために、ここにいるときの簡単な手順をいくつか示します。',
		KR: '이를 돕기 위해 여기에 당신이 이곳에 있을 때 몇 가지 간단한 지침이 있습니다.',
		CN: '當您到這時，為了幫助您做到這一點，這裡有一些簡單的說明供參考。',
	},
	NEW_AND_REGISTERED: {
		EN: 'New and Registered Travelers',
		JP: '新規および登録済みの旅行者',
		KR: '신규 및 등록된 여행자',
		CN: '新旅客和已登入之旅客',
	},
	NEW_AND_REGISTERED_FIRST_ITEM: {
		EN: 'Read Travel Restrictions prior to any travel',
		JP: '旅行前に旅行制限をお読みください',
		KR: '여행 전에 여행 제한 사항을 읽으십시오',
		CN: '在任何旅行出發之前閱讀旅遊注意事項',
	},
	NEW_AND_REGISTERED_SECOND_ITEM: {
		EN: 'Phone internet connectivity is required to access this platform during your travel. If you need phone service during your travel, please visit our Get Connected Page.',
		JP: '旅行中にこのプラットフォームにアクセスするには、電話によるインターネット接続が必要です。旅行中に電話サービスが必要な場合は、Get Connected ページにアクセスしてください。',
		KR: '여행 중 이 플랫폼에 액세스하려면 전화 인터넷 연결이 필요합니다. 여행 중 전화 서비스가 필요한 경우 연결 페이지를 방문하십시오.',
		CN: '在您旅途期間此平台需要與您電話網路連結。如果您在旅途期間需要電話服務，請連結至"Get Connected Page"頁面。',
	},
	NEW_AND_REGISTERED_THIRD_ITEM: {
		EN: 'Click on Signup / Login button',
		JP: 'サインアップ/ログインボタンをクリックします',
		KR: '회원가입/로그인 버튼 클릭',
		CN: '點擊註冊/登入按鈕',
	},
	NEW_AND_REGISTERED_FOURTH_ITEM: {
		EN: 'Follow the simple registration or login process',
		JP: '簡単な登録またはログインプロセスに従ってください',
		KR: '간단한 등록 또는 로그인 절차를 따르십시오.',
		CN: '遵循簡易的註冊或登入流程',
	},
	NEW_AND_REGISTERED_FIFTH_ITEM: {
		EN: 'For support inquiries, please visit our Support Page',
		JP: 'サポートに関するお問い合わせは、サポートページをご覧ください。',
		KR: '지원 문의는 지원 페이지를 방문하세요',
		CN: '如需協助，請連結到我們的"Support Page"頁面',
	},
	NEW_AND_REGISTERED_SIXTH_ITEM: {
		EN: 'For any emergencies, please visit our Hotline Page.',
		JP: '緊急の場合は、ホットラインページをご覧ください。',
		KR: '긴급 상황이 발생하면 핫라인 페이지를 방문하십시오.',
		CN: '如遇到任何緊急情況，請連結到我們的"Hotline Page"頁面。',
	},
	HOW_TO_BOOK: {
		EN: 'How to book a trip and fill your forms.',
		JP: '旅行の予約方法と申請書の記入方法',
		KR: '여행을 예약하고 양식을 작성하는 방법.',
		CN: '如何預訂行程和填寫表格。',
	},
	HOW_TO_BOOK_FIRST_ITEM: {
		EN: 'Click New Trip',
		JP: '[新しい旅行] をクリック',
		KR: '새 여행을 클릭하십시오',
		CN: '點擊新行程',
	},
	HOW_TO_BOOK_SECOND_ITEM: {
		EN: 'Fill in all your information',
		JP: 'すべての情報を入力ください',
		KR: '모든 정보를 입력하세요',
		CN: '填寫您的所有訊息',
	},
	HOW_TO_BOOK_THIRD_ITEM: {
		EN: 'Upload required documents',
		JP: '必要書類のアップロード',
		KR: '필요한 서류 등록하기',
		CN: '上傳所需文件',
	},
	HOW_TO_BOOK_FOURTH_ITEM: {
		EN: 'Sign.',
		JP: 'サイン',
		KR: '서명.',
		CN: '符號',
	},
	HOW_TO_BOOK_FIFTH_ITEM: {
		EN: "You're Done!",
		JP: '完了！',
		KR: '완료하였습니다.',
		CN: '你完成了！',
	},
	HOW_TO_BOOK_SIXTH_ITEM: {
		EN: 'Present your QR code to the Customs Officers at the Border.',
		JP: '国境の税関職員に QR コードを提示してください。',
		KR: '국경에 있는 세관원에게 QR 코드를 제시하세요.',
		CN: '向邊境海關官員出示您的二維碼。',
	},
	HOW_TO_BOOK_SEVENTH_ITEM: {
		EN: 'We look forward to your visit to the Marianas.',
		JP: 'マリアナへの来訪を心よりお待ちしております。',
		KR: '마리아나에서 뵙길 희망 합니다.',
		CN: '向边境海关/海关代理出示您的二维码。',
	},
	GILISOW: {
		EN: 'Phone internet connectivity is required to access this platform during your travel. If you need phone service during your travel, please visit our Get Connected Page.',
		JP: '旅行中にこのプラットフォームにアクセスするには、電話によるインターネット接続が必要です。必要な場合旅行中の電話サービスについては、Get Connected ページにアクセスしてください。',
		KR: '여행 중 이 플랫폼에 액세스하려면 전화 인터넷 연결이 필요합니다. 필요한 경우여행 중 전화 서비스를 이용하려면 연결 페이지를 방문하십시오.',
		CN: '在您旅途期間此平台需要與您的電話網路連接。如果您在旅途期間需要電話服務，請連結至"Get Connected Page"頁面。',
	},
	// PRIVACY STATEMENT
	PRIVACY_POLICY: {
		EN: 'TERMS OF USE & PRIVACY NOTICE',
		JP: '利用規約とプライバシー条項',
		KR: '이용 약관 및 개인 정보 보호 고지',
		CN: '使用條款和隱私聲明',
	},
	DELETE_ACCOUNT: {
		EN: 'Delete or Deactivate your account',
		JP: 'アカウントを削除または無効にする',
		KR: '계정 삭제 또는 비활성화',
		CN: '刪除或停用您的帳戶',
	},
	LAST_MODIFIED: {
		EN: 'Last Modified: September 2023',
		JP: '最終更新日: 2022 年9月20日',
		KR: '최종 수정일: 2022년 9월 20일',
		CN: '最後修改時間：2022 年9 月20 日',
	},
	INTRODUCTION: {
		EN: 'Introduction',
		JP: 'はじめに',
		KR: '소개',
		CN: '介紹',
	},
	INTRODUCTION_FIRST_PARAGRAPH: {
		EN: 'Welcome to',
		JP: 'へようこそ',
		KR: '환영합니다',
		CN: '歡迎來到',
	},
	INTRODUCTION_FIRST_PARAGRAPH_TWO: {
		EN: '(collectively the "the Site"), owned and operated by the Commonwealth of the Northern Mariana Islands ("CNMI") Governor’s Office and Department of Finance’s Office of Information Technology in collaboration with the Marianas Visitors Authority, Commonwealth Healthcare Corporation, and CNMI Customs and Quarantine (collectively “CNMI Government”).',
		JP: '(総称して「サイト」)は北マリアナ諸島自治連邦区(「CNMI」) 知事室および財務局情報技術室がマリアナ政府観光局、Commonwealth Healthcare Corporation、CNMI税関・検疫事務所と協力して所有および運営しています。(総称して「CNMI 政府」)',
		KR: '(통칭 "사이트"), 는 북마리아나 제도 연방(CNMI) 주지사 사무실,  재무부 정보 기술 사무소 (마리아나 관광청과 공동작업), 북마리아나  의료 법인 (CHC), CNMI 세관 검역국(총칭적으로 "CNMI 정부")과 공동으로 소유하고 운영하는 사이트입니다.',
		CN: '(統稱“網站”），由北馬里亞納群島聯邦(CNMI) 州長辦公室、財務部資訊處結合馬里亞納觀光局、聯邦醫療保健公司和CNMI海關和檢疫   局(統稱“CNMI 政府"）所共同營運管理。',
	},
	INTRODUCTION_FIRST_LINK: {
		EN: 'www.travel.mp',
		JP: 'www.travel.mp',
		KR: 'www.travel.mp',
		CN: 'www.travel.mp',
	},
	INTRODUCTION_SECOND_LINK: {
		EN: 'www.safetravelsmarianas.com',
		JP: 'www.safetravelsmarianas.com',
		KR: 'www.safetravelsmarianas.com',
		CN: 'www.safetravelsmarianas.com',
	},
	INTRODUCTION_THIRD_LINK: {
		EN: 'www.staysafemarianas.com',
		JP: 'www.staysafemarianas.com',
		KR: 'www.staysafemarianas.com',
		CN: 'www.staysafemarianas.com',
	},
	INTRODUCTION_SECOND_PARAGRAPH: {
		EN: "The CNMI Government is committed to providing a safe and positive experience to all users on our Site. To help us do that, we need you to follow a few basic rules while you're here.",
		JP: 'CNMI 政府は、当サイトのすべてのユーザーに安全でポジティブな滞在を提供することに取り組んでいます。そのためには、滞在時にいくつかの基本的な規則に従っていただく必要があります。',
		KR: 'CNMI 정부는 사이트의 모든 사용자에게 안전하고 긍정적인 경험을 제공하기 위해 최선을 다하고 있습니다. 이를 위해 계시는 동안 몇 가지 기본 규칙을 따라야 합니다.',
		CN: 'CNMI 政府致力於為我們網站上的所有使用者提供安全和正面的體驗。為了幫助我們做到這一點，我們需要您在這裡遵守一些基本規則。',
	},
	TERMS_OF_USE: {
		EN: 'TERMS OF USE',
		JP: '利用規約',
		KR: '이용약관',
		CN: '使用條款',
	},
	ACCEPTANCE: {
		EN: 'Acceptance of the Terms of Use',
		JP: '利用規約への同意',
		KR: '이용약관 동의',
		CN: '接受使用條款',
	},
	ACCEPTANCE_FIRST_PARAGRAPH: {
		EN: 'By accessing the Site, you agree to the following Terms of Use. These Terms of Use are entered into, by, and between you and the Site. The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Use"), govern your access to and use of the Site, including any content, functionality, and services offered on or through the Site, whether as a guest or a registered user. ',
		JP: 'サイトへのアクセスにより、次の利用規約に同意したことになります。これらの利用規約は、あなたとサイトの間で締結されます。以下の利用規約は、参照により明示的に組み込まれている文書(総称して「利用規約」) と共に、サイト上またはサイトを通じて提供されるコンテンツ、機能、およびサービスを含む、ゲストまたは登録ユーザーとしてのサイトへのアクセスおよび利用を管理します。',
		KR: '사이트에 액세스하면 다음 이용 약관에 동의하는 것입니다. 본 이용약관은 귀하와 사이트 사이에 체결됩니다. 다음 이용 약관은 명시적으로 참조로 통합된 문서(총칭하여 "이용 약관")와 함께 사이트에서 또는 사이트를 통해 제공되는 콘텐츠, 기능 및 서비스를 포함하여 사이트에 대한 액세스 및 사용을 통제합니다.',
		CN: '訪問本網站，即表示您同意以下使用條款。這些使用條款由您與本網站訂立、由您和本網站之間訂立。以下條款和條件，連同它們通過引用明確包含的任何文件（統稱為“使用條款”），管轄您對本網站的訪問和使用，包括在本網站上或通過本網站提供的任何內容、功能和服務，無論是作為訪客還是註冊用戶。',
	},
	ACCEPTANCE_SECOND_PARAGRAPH: {
		EN: 'Please read the Terms of Use carefully before you start to use the Site.',
		JP: 'サイトの利用を開始する前に、使用条件をよくお読みください。',
		KR: '사이트 사용을 시작하기 전에 이용 약관을 주의 깊게 읽으십시오.',
		CN: '在開始使用本網站之前，請仔細閱讀款。',
	},
	ACCEPTANCE_SECOND_PARAGRAPH_BOLD: {
		EN: ' By using the Site (or by clicking to accept or agree to the Terms of Use when this option is made available to you), you accept and agree to be bound and abide by these Terms of Use and our Privacy Notice, which is included with these Terms of Use.',
		JP: 'サイトを利用することにより(または、このオプションが利用可能になっている場合は利用規約に同意または同意するためにクリックすることにより)、これらの利用規約に含まれる規約とプライバシーに関する通知に従い、遵守することに同意したことになります。',
		KR: '귀하는 사이트를 사용함으로써(또는 이용약관에 동의하거나 동의하기 위해 클릭 하는 경우), 본 이용 약관과 본 이용 약관에 포함된 당사의 개인정보 보호통지에 동의하고 동의하는 것입니다.',
		CN: '通過使用本網站（或在向您提供此選項時點擊接受或同意使用條款），您接受並同意接受這些使用條款和我們的隱私聲明的约束和遵守，其中包含這些使用條款。',
	},
	ACCEPTANCE_SECOND_PARAGRAPH_TWO: {
		EN: 'If you do not want to agree to these Terms of Use or the Privacy Notice, you must not access or use the Site.',
		JP: 'これらの利用規約またはプライバシー通知に同意しない場合は、サイトへアクセスしたり利用してはなりません。',
		KR: '본 이용 약관 또는 개인 정보 보호 고지에 동의하지 않으려면 사이트에 접속 하지 않거나 사이트를 사용을 중지하여 주시기 바랍니다. ',
		CN: '如果您不想同意這些使用條款或隱私聲明，則不須連結或使用本網站。',
	},

	ACCEPTANCE_THIRD_PARAGRAPH: {
		EN: 'This Site is offered and available to users who are 18 years of age or older. By using this Site, you represent and warrant that you meet all the eligibility requirements. If you do not meet the requirements, you must not access or use the Site.',
		JP: 'このサイトは 18 歳以上のユーザーが提供および利用できます。このサイトを使用することにより、お客様はすべての資格要件を満たしていることを表明および保証するものとします。要件を満たしていない場合は、サイトにアクセスしたり、サイトを使用したりしてはなりません。',
		KR: '본 사이트는 18세 이상의 사용자에게 제공되고 이용 가능합니다. 이 사이트를 사용함으로써 귀하는 모든 자격 요건을 충족함을 진술하고 보증합니다. 요구 사항을 충족하지 않는 경우 사이트에 액세스하거나 사이트를 사용해서는 안 됩니다.',
		CN: '18 歲或以上的用戶可以連結使用。通過 使用 本網站，您聲明並保證您符合所有資格要求。如果您不符合要求，則不得訪問或使用本網站。',
	},
	CHANGES_TO_THE_TERMS: {
		EN: 'Changes to the Terms of Use',
		JP: '利用規約の変更',
		KR: '이용약관 변경',
		CN: '使用條款的變更',
	},
	CHANGES_TO_THE_TERMS_FIRST_PARAGRAPH: {
		EN: 'We may revise and update these Terms of Use and Privacy Notice from time to time at our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Site thereafter. However, any changes to the dispute resolution provisions set out in the Governing Law and Jurisdiction section will not apply to any disputes for which the parties have actual notice on or before the date the change is posted on the Site.',
		JP: '独自の裁量により、本利用規約を随時改訂および更新する場合があります。すべての変更は、掲載した時点で即時有効となり、その後のサイトへのすべてのアクセスおよび利用に適用されます。ただし、準拠法および裁判管轄のセクションに記載されている紛争解決規定の変更は、変更がサイトに掲載される日以前に当事者が実際に通知した紛争には適用されません。',
		KR: '당사는 당사의 재량에 따라 본 이용 약관 및 개인 정보 보호 고지를 수시로 개정 및 업데이트할 수 있습니다. 모든 변경 사항은 게시하는 즉시 적용되며 이후 사이트에 대한 모든 접속 및 사용에 적용됩니다. 그러나 준거법 및 관할 구역에 명시된 분쟁 해결 조항에 대한 변경은 변경사항이 홈페이지에 게시된 날 또는 이전에 양 당사자가 실제로 통지한 분쟁에는 적용되지 않습니다.',
		CN: '我們會不定時自行修訂和更新這些使用條款。所有更改在我們發布後立即生效，並適用於此後對本網站的所有訪問和使用。但是，對管轄法律和管轄權部分中規定的爭議解決條款的任何更改將不適用於雙方在更改發佈在網站上之日或之前實際通知的任何爭議。',
	},
	CHANGES_TO_THE_TERMS_SECOND_PARAGRAPH: {
		EN: 'Your continued use of the Site following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page each time you access the Site so you are aware of any changes, as they are binding on you.',
		JP: '改訂された利用規約の掲載後に引き続きサイトを利用することは、変更を受け入れて同意することを意味します。サイトにアクセスするたびにこのページを確認し、変更があなたに適用されるため、変更を認識いただく必要があります。',
		KR: '수정된 이용약관을 게시한 후 사이트를 계속 사용한다는 것은 변경 사항을 수락하고 동의한다는 것을 의미합니다. 귀하는 사이트에 액세스할 때마다 이 페이지를 확인하여 귀하에게 법적 구속력이 있는 변경사항을 확인하시기 바랍니다',
		CN: '您在發布修訂後的使用條款後仍繼續使用本網站意味著您接受並同意這些更改。您應在每次連結本網站時檢查此頁面，以便了解任何更改，因為它們對您是具有約束力',
	},
	ACCESSING_THE_SITE: {
		EN: 'Accessing the Site and Account Security',
		JP: 'サイトとアカウントのセキュリティへのアクセス',
		KR: '사이트 및 계정 보안 접속',
		CN: '連結站點和帳戶安全',
	},
	ACCESSING_THE_SITE_FIRST_PARAGRAPH: {
		EN: 'We reserve the right to withdraw or amend the Site, and any service or material we provide on the Site, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Site, or the entire Site, to users, including registered users.',
		JP: '独自の裁量により、予告なしに、本サイトおよび本サイトで提供するサービスまたは資料を撤回または修正する権利を留保します。何らかの理由でサイト全体または一部がどの期間でも利用できない場合、私共は責任を負いません。一時的にサイトの一部またはサイト全体へのアクセスを、登録ユーザーを含むユーザーに制限する場合があ',
		KR: '당사는 사전 통지 없이 단 독 재량으로 사이트와 사이트에서 제공하는 모든 서비스 또는 자료를 철회하거나 수정할 수 있는 권리를 갖고 있습니다. 어떤 이유로든 사이트의 전체 또는 일부를 사용할 수 없는 경우에도 당사는 책임을 지지  않습니다. 때때로 당사는 사이트의 일부 또는 전체 사이트에 대한 액세스를 사용자 및 등록된 사용자에게 제한할 수 있습니다.',
		CN: '我們保留自行決定撤銷或修改網站以及在網站上提供的任何服務或素材的權利，恕不另行通知。如果由於任何原因本網站的全部或任何部分在任何時間或任何時期不可用，我們將不承擔任何責任。有時，我們可能會限制用戶（包括註冊用戶）連結網站的某些部分或整個網站。',
	},
	ACCESSING_THE_SITE_BULLET: {
		EN: 'You are responsible for both:',
		JP: 'あなたは両方に対して責任があります:',
		KR: '다음 두 가지 모   두에 대한 책임은 귀하에게 있습니다.',
		CN: '您要對以下兩項負責：',
	},
	ACCESSING_THE_SITE_FIRST_BULLET: {
		EN: 'Making all arrangements necessary for you to have access to the Site.',
		JP: 'あなたが本サイトにアクセスするのに必要なすべての手配を行います。',
		KR: '본 사이트에 방문하기 위해 필요한 모든 준비를 하세요',
		CN: '為您連結本網站做出一切必要的安排。',
	},
	ACCESSING_THE_SITE_SECOND_BULLET: {
		EN: 'Ensuring that all persons who access the Site through your internet connection are aware of these Terms of Use and comply with them.',
		JP: 'インターネット接続を介しサイトにアクセスするすべての人が、これらの利用規約を認識、遵守することを確認します。',
		KR: '귀하의 인터넷 연결을 통해 사이트에 접속하는 모든 사람이 본 이용 약관을 인지하고 준수하도록 합니다.',
		CN: '確保所有通過您的網路連接到本網站的人都知道這些使用條款並遵守它們。',
	},
	ACCESSING_THE_SITE_SECOND_PARAGRAPH: {
		EN: 'To access the Site or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Site that all the information you provide on the Site is correct, current, and complete. You agree that all information you provide to register with this Site or otherwise, including, but not limited to, through the use of any interactive features on the Site, is governed by our Privacy Notice and you consent to all actions we take with respect to your information consistent with our Privacy Notice.',
		JP: 'サイトまたはサイトが提供する一部のリソースにアクセスするには、特定の登録の詳細またはその他の情報を提供するよう求められる場合があります。サイトで提供する全ての情報が正しく、最新で、完全であることがサイトの利用条件です。あなたは、本サイトまたはその他の方法で登録するために提供するすべての情報は(本サイトのインタラクティブ機能の使用を含む)、私達のプライバシー条項により管理されることに同意し、以下に関して当社が行うすべての措置に同意するものとします。',
		KR: '사이트 또는 사이트에서 제공하는 일부 리소스에 액세스하려면 특정 등록 세부 정보 또는 기타 정보를 제공하라는 요청을 받을 수 있습니다. 이 요청은 사이트에서 제공하는 모든 정보를 정확, 최신, 완전하게 하기 위한 조건입니다.  귀하는 본 사이트에 등록하기 위해 귀하가 제공하는 모든 정보(사이트의 모든 대화형 기능을 포함하되 이에 국한되지 않음)가 당사의 개인정보 보호통지의 적용을 받는다는 데 동의하며 귀하는 당사의 개인 정보 보호 알림과 일치하는 귀하의 정보에 대해 취하는 모든 조치에 동의합니다.',
		CN: '要連結本網站或其提供的某些資源，您可能會被要求提供某些註冊詳細信息或其他訊息。您使用本網站的條件是您在本網站上提供的所有訊息都是正確的、最新的且完整的。您同意您位在本網站註冊或以其他方式提供的所有訊息，包括但不限於通過使用本網站上的任何互動功能，均會受到我們的隱私聲明的約束，並且您同意我們針對以下內容採取的所有行動您的信息與我們的隱私聲明一致。',
	},
	ACCESSING_THE_SITE_THIRD_PARAGRAPH: {
		EN: 'If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Site or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.',
		JP: 'セキュリティ手順の一環としてユーザー名、パスワードまたはその他の情報を選択したり得た場合、その情報を機密情報として扱う必要があり、他人や団体に開示してはいけません。また、あなたのアカウントがあなた個人のものであることを認め、ユーザー名、パスワード、またはその他のセキュリティ情報を利用して、本サイトまたはその一部へのアクセスを他の人に提供しないことに同意するものとします。あなたは、ユーザー名またはパスワードへの不正アクセスまたは使用、またはその他のセキュリティ違反について、直ちに当社に通知することに同意するものとします。また、各セッションの終了時にアカウントを終了することに同意するものとします。他人があなたのパスワードやその他の個人情報を閲覧したり記録したりできないように、公共または共有のコンピューターからあなたの',
		KR: '보안 절차의 일부로 사용자 이름, 암호 또는 기타 정보를 선택했거나 제공받는 경우 해당 정보를 기밀로 취급해야 하며 다른 사람이나 단체에 공개해서는 안 됩니다. 또한 귀하는 귀하의 계정이 개인 계정임을 인정하고 귀하의 사용자 이름, 비밀번호 또는 기타 보안 정보를 사용하여 이 사이트 또는 사이트의 일부에 대한 액세스 권한을 다  른 사람에게 제공하지 않을 것에 동의합니다. 귀하는 귀하의 사용자 이름 또는 비   밀번호에 대한 무단 접속 및 사용 또는 기타 보안 위   반이 있는 경우 즉시 당사에 통지하는 데 동의합니다. 또한 각 세션이 끝날 때 계정을 종료하는 데 동의합니다. 공용   컴퓨터 또는 공유 컴퓨터에서 계정에 액세스할 때 다른 사용자가 암호 또는 기타 개인 정보를 보거나 기록할 수 없도록 각별히 주의해야 합니다.',
		CN: '如果您選擇或被提供用戶名、密碼或任何其他訊息作為我們安全程序的一部分，您必須將此類信息視為機密訊息，並且不得將其透露給任何其他人。您還承認您的帳戶是您的個人帳戶，並同意不向任何其他人提供使用您的用戶名、密碼或其他安全信息訪問本網站或其部分內容的權限。您同意立即通知我們任何未經授權訪問或使用您的用戶名或密碼或任何其他違反安全性的行為。您還同意確保在每次連結結束時退出您的帳戶。從公共或共享資源連結您的帳戶時，您應特別小心，以免他人查看或記錄您的密碼或其他個人訊息。',
	},
	ACCESSING_THE_SITE_FOURTH_PARAGRAPH: {
		EN: 'We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time, in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.',
		JP: '本利用規約の規定に従わないと判断した場合、あなたが選択したか、私達が選択したかを問わず、いつでも独自の裁量で理由の有無を問わず、ユーザー名、パスワード、またはその他の識別子を無効にする権利を有します',
		KR: '우리는 귀하가 이용 약관을 위반했다고 판단되는 경우를 포함하여 어떠한 이유나 또는이유 없이 언제든지 사용자 이름, 비밀번호 또는 기타 식별자의 사용 금지를 결정할 권리가 있습니다.',
		CN: '我們有權在任何時候出於任何理由或無理由自行決定禁用任何用戶名、密碼或其他標識符，無論是由您選擇還是由我們提供，包括如果我們認為您違反了任何 提供這些使用條款。',
	},
	INTEL_PROPERTY_RIGHTS: {
		EN: 'Intellectual Property Rights',
		JP: '知的財産権',
		KR: '지적 재  산',
		CN: '智慧財產權',
	},
	INTEL_PROPERTY_RIGHTS_FIRST_PARAGRAPH: {
		EN: 'The Site and its entire contents, features, and functionality (including, but not limited to, all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Site owner, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.',
		JP: 'サイトとその全体のコンテンツ、機能、機能(すべての情報、ソフトウェア、テキスト、表示、画像、ビデオ、オーディオ、およびそれらのデザイン、選択、配置を含むがこれらに限定されない) は、サイト所有者が所有します。そのライセンサー、またはそのような資料、他の提供者は、米国および国際的な著作権、商標、特許、企業秘密、およびその他の知的財産または所有権に関する法律によって保護されています。',
		KR: '사이트 및 사이트의 전체 콘텐츠, 특징 및 기   능(모든 정보, 소프트웨어, 텍스트, 디스플 레이, 이미지, 비디오 및 오디오를 포함하되 이에 국한되지 않음)은 사이트 소유자의 소유입니다. 해당 라이선스 제공자 또는 해당 자료의 기타 제공자는 미국 및 국제 저작권, 상표, 특허, 영업 비  밀 및 기  타 지적 재산권 또는 소유권 법률의 보호를 받습니다.',
		CN: '本網站及其全部內容、特性和功能（包括但不限於所有訊息、軟體、內文、顯示、圖像、影片和音訊，及其設計、選擇和安排）均歸網站所有者所有，此類素材的其他提供者，並受美國和國際版權、商標、專利、商業秘密和其他智慧產權或所有權法律的保護。',
	},
	INTEL_PROPERTY_RIGHTS_SECOND_PARAGRAPH: {
		EN: 'These Terms of Use permit you to use the Site for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Site, except as follows:',
		JP: 'これらの利用規約は、個人的、非営利目的でのみサイトを使用することを許可します。次の場合を除き、サイト上の資料を複製、配布、変更、派生物の作成、公に表示、公に実行、再発行、ダウンロード、保存、または送信してはなりません。',
		KR: '본 이용 약관은 귀하가 개인적, 비상업적 용도로만 사이트를 사용할 수 있도록 허용합니다. 다음을 제외하고 당사 사이트의 자료를 복제, 배포, 수정, 생성, 공개, 공개, 공연, 재게시, 다운로드,  저장또는 전  송해서는 안 됩니다',
		CN: '這些使用條款僅允許您將本網站用於個人非商業用途。您不得複制、分發、修改、創建衍生作品、公開展示、公開表演、重新發布、下載、存儲或傳輸我們網站上的任何素材，但以下情況除外：',
	},
	INTEL_PROPERTY_RIGHTS_FIRST_BULLET: {
		EN: 'Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.',
		JP: 'あなたのコンピュータは、これらの資料へのアクセスおよび表示に伴い、そのような資料のコピーをRAM に一時的に保存する場合があります。',
		KR: '귀하의 컴퓨터로 해당 자료에 액세스하고 보는 동안 해당 자료의 사본을 RAM에 임시로 저  장할 수 있습니다.',
		CN: '您的電腦可能會在您連結和查看這些素材時將這些資料的副本臨時存儲在RAM 中。',
	},
	INTEL_PROPERTY_RIGHTS_SECOND_BULLET: {
		EN: 'You may store files that are automatically cached by your web browser for display enhancement purposes.',
		JP: '表示を強化する目的で、Web ブラウザによって自動的にキャッシュされるファイルを保存できます。',
		KR: '디스플레이 향상을 위해 웹 브라우저에서 자동으로 캐시된 파일을 저  장할 수 있습니다',
		CN: '您可以存取由您的網絡瀏覽器自動緩存的資料以用於優化螢幕顯示目的。',
	},
	INTEL_PROPERTY_RIGHTS_THIRD_BULLET: {
		EN: 'You may print or download one copy of a reasonable number of pages of the Site for your own personal, non-commercial use and not for further reproduction, publication, or distribution.',
		JP: 'あなたは、あなた自身の個人的、非商業的使用のために、サイトの合理的な数のページの印刷またはダウンロードすることができますが、複製、出版、または配布を目的とすることはできません。',
		KR: '귀하는 추가적인 복제, 출판 또는 배포가 아닌 개인적, 비상업적 사용을 위해 본 사이트의 적절한 페이지 수의 복사본을 인쇄하거나 다운로드할 수 있습니다.',
		CN: '您可以列印或下載本網站合理數量的一份副本，用於您個人的非商業用途，不得進一步複製、出版或轉發。',
	},
	INTEL_PROPERTY_RIGHTS_FOURTH_BULLET: {
		EN: 'If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.',
		JP: 'デスクトップ、モバイル、またはその他のダウンロードアプリケーションを提供する場合、個人的、非商業的使用のみを目的として、エンドユーザーライセンス契約に従っていただければコンピュータまたはモバイルデバイスにコピーをダウンロードすることができます。',
		KR: '당사가 다운로드를 위해 데스크톱, 모바일 또는 기타애플리케이션을 제공하는 경우, 개인적이고 비상업적인 용도로만컴퓨터 또는 모바일 장치에 단일 복사본을 다운로드할 수 있습니다. 귀하는 이러한 애플리케이션에 대해 당사의 최종 사용자 라이선스 계약에 구속되는 것에 동의해야 합니다.',
		CN: '如果我們提供電腦、手機或其他應用程序供下載，您可以將單一複製檔下載到您的電腦或手機上，僅用於您個人的非商業用途，前提是您同意受我們的最終用戶許可協議的約束。應用程序。',
	},
	INTEL_PROPERTY_RIGHTS_FIFTH_BULLET: {
		EN: 'If we provide social media features with certain content, you may take such actions as are enabled by such features.',
		JP: 'ソーシャルメディア機能で特定のコンテンツを提供する場合、そのような機能により可能となる行動を取ることができます。',
		KR: '당사가 특정 콘 텐츠와 함께 소셜 미디어 기능을 제공하는 경우, 해당 기  능에 의해 활성화되는 작업을 수행할 수 있습니다.',
		CN: '如果我們為某些內容提供社交媒體功能，您可以採取此類功能啟用的操作。',
	},
	INTEL_PROPERTY_RIGHTS_MUST_NOT: {
		EN: 'You must not:',
		JP: '以下をあなたはしてはいけません：',
		KR: '하지 마세요',
		CN: '你不能：',
	},
	INTEL_PROPERTY_RIGHTS_SEVENTH_BULLET: {
		EN: 'Modify copies of any materials from the Site.',
		JP: 'サイトからの資料のコピーを変更すること',
		KR: '사이트에서 모든 자료의 사본을 수정합니다.',
		CN: '修改網站上任何素材的副本。',
	},
	INTEL_PROPERTY_RIGHTS_EIGHTH_BULLET: {
		EN: 'Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Site.',
		JP: 'サイトの資料のコピーから著作権、商標、またはその他の所有権通知を削除または変更すること',
		KR: '사이트의 자료 사본에서 저작권, 상표 또는 기   타 소유권 고지를 삭제하거나 변경합니다.',
		CN: '刪除或更改本網站素材副本中的任何版權、商標或其他所有權聲明。',
	},
	INTEL_PROPERTY_RIGHTS_THIRD_PARAGRAPH: {
		EN: 'You must not access or use for any commercial purposes any part of the Site or any services or materials available through the Site.',
		JP: '本サイト、または本サイトを通じて利用可能なサービスまたは資料にアクセスしたり、商業目的で使用してはなりません',
		KR: '귀하는 본 사이트의 어떤 부분이나 본 사이트를 통해 제공되는 서비스 또는 자료에 액세스하여 상업적 목적으로 사용해서는 안 됩니다.',
		CN: '您不得出於任何商業目的連結或使用本網站的任何部分或通過本網站提供的任何服務或素材。',
	},
	INTEL_PROPERTY_RIGHTS_FOURTH_PARAGRAPH: {
		EN: 'If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Site in breach of the Terms of Use, your right to use the Site will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by the Site. Any use of the Site not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.',
		JP: '利用規約に違反して、印刷、コピー、変更、ダウンロード、またはその他の方法で利用したり、他の人にサイトの一部へのアクセスを提供したりした場合、サイトを使用する権利は直ちに停止され、私どもの指示により、作成した資料のコピーを返却または破棄いただきます。本サイトまたは本サイトのコンテンツに対する権利、権原、または利益があなたに譲渡されることはなく、明示的に付与されていないすべての権利は本サイトに帰属します。これらの利用規約で明示的に許可されていないサイトの利用は著作権、商標、およびその他の法律に違反する可能性があります。',
		KR: '인쇄, 복사, 수정, 다운로드 또는 사용자가 사용 약관을 위반하여 사이트의 모든 부분에 대한 액세스 권한을 제공하는 경우 사이트 사용 권한이 즉시 중지되며 당사의 선택에 따라 다음을 수행해야 합니다.  귀하가    만든 자료의 사본을 반환하거나 파기합니다. 사이트 또는 사이트의 콘텐츠에 대한  권리, 소유권 또는 이   권은 귀하에게 양도되지 않으며 명시적으로 부여되지 않은 모든  권리는 사이트가 보유합니다. 본 이용약관에서 명시적으로 허용하지 않은 사이트 사용은 저작권, 상표권 및 기  타법률을 위 반할 수 있습니다.',
		CN: '如果您違反使用條款列印、複製、修改、下載或以其他方式使用或向任何其他人提供連結本網站任何部分的權限，您使用本網站的權利將立即停止，您必需根據我們的選項、返還或銷毀您製作的素材的任何副本。本網站或本網站上的任何內容的任何權利、所有權或利益均未轉讓給您，本網站保留未明確授予的所有權利。本使用條款未明確允許的對本網站的任何使用可能違反版權、商標和其他法律。',
	},
	TRADEMARKS: {
		EN: 'Trademarks',
		JP: '商標',
		KR: '상표권',
		CN: '商標',
	},
	TRADEMARKS_PARAGRAPH: {
		EN: 'The Site and all related names, logos, product and service names, designs, and slogans are trademarks. You must not use such marks without the prior written permission of the owner of such trademarks. All other names, logos, product and service names, designs, and slogans on this Site are the trademarks of their respective owners.',
		JP: 'サイトおよび関連するすべての名前、ロゴ、製品およびサービス名、デザイン、およびスローガンは商標です。かかる商標の所有者の事前の書面による許可なしに、かかる商標を使用してはなりません。このサイト上のその他すべての名前、ロゴ、製品およびサービス名、デザイン、およびスローガンは、それぞれの所有者の商標です。',
		KR: '사이트 및 모든 관련 이름, 로고, 제품 및 서비스 이름, 디자인 및 슬로건은 상표입니다. 해당 상  표소유자의 사전 서면 허가 없이 해당 상표를 사용할 수 없습니다. 이 사이트의 다른 모든 이   름, 로고, 제품 및 서비스 이름, 디자인 및 슬로건은 해당 소유자의 상표입니다.',
		CN: '本網站和所有相關名稱、標誌、產品和服務名稱、設計和標語均為商標。未經此類商標所有者的事先書面許可，您不得使用此類商標。本網站上的所有其他名稱、標誌、產品和服務名稱、設計和標語均為其各自所有者的商標。',
	},
	PROHIBITED_USES: {
		EN: 'Prohibited Uses',
		JP: '禁止事項',
		KR: '사용 금지',
		CN: '禁止使用',
	},
	PROHIBITED_USES_BULLET: {
		EN: 'You may use the Site only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Site:',
		JP: 'あなたは合法的な目的、本利用規約に従ってのみ本サイトを利用することができます。あなたは、サイトを利用しないことに同意します:',
		KR: '귀하는 본 이용약관에 따라 합법적인 목적으로만 사이트를 사용할 수 있습니다. 귀하는 다음 사이트를 사용하지 않을 것에 동의합니다.',
		CN: '您只能出於合法目的並根據這些使用條款使用本網站。您同意不使用本網站：',
	},
	PROHIBITED_USES_FIRST_BULLET: {
		EN: 'In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the United States of America or other countries).',
		JP: '適用される連邦、州、地方、または国際法または規制(アメリカ合衆国またはその他の国との間のデータまたはソフトウェアの輸出入に関する法律を含むがこれらに限定されない) に違反する場合。',
		KR: '적용 가  능한 연방, 주, 지역 또는 국제 법률이나 규정(미국 또는 다른 국가와의 데이터 또는 소프트웨어 수 출에 관한 법률을 제한  없이 포함)을 위 반하는 방식.',
		CN: '以任何方式違反任何適用的聯邦、州、地方或國際法律或法規（包括但不限於有關向美國或其他國家/地區出口數據或軟體的任何法律）。',
	},
	PROHIBITED_USES_SECOND_BULLET: {
		EN: 'Display, send, receive, or store obscene or inappropriate content.',
		JP: 'わいせつまたは不適切なコンテンツを表示、送信、受信、または保存する。',
		KR: '외설적이거나 부적절한 콘텐츠를 표시, 전송, 수신 또는 저장하는 행위.',
		CN: '顯示、發送、接收或存儲淫穢或不當內容。',
	},
	PROHIBITED_USES_THIRD_BULLET: {
		EN: 'Advertise, promote, endorse, or market, directly or indirectly, any third-party commercial products, services, solutions, or other technologies.',
		JP: '第三者の商用製品、サービス、ソリューション、またはその他の技術を、直接的または間接的に宣伝、プロモート、推奨、または販売すること。',
		KR: '타사 상용 제품, 서비스, 솔루션 또는 기타 기술을 직간접적으로 광고, 홍보, 보증 또는 마케팅하는 행위.',
		CN: '直接或間接地宣傳、推廣、認可或行銷任何第三方商業產品、服務、解決方案或其他技術。',
	},
	PROHIBITED_USES_FOURTH_BULLET: {
		EN: 'For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.',
		JP: '未成年者を不適切なコンテンツにさらしたり、個人を特定できる情報を要求したり、その他の方法で未成年者を搾取したり、危害を加えたり、または未成年者を搾取または危害を加えようとする目的。',
		KR: '미성년자를 부적절한 콘텐츠에 노출시키거나, 개인 식  별 정보를 요청하는 등 어떤 식으로든 미성년자를 착취 또는 해를 입히려는 목적.',
		CN: '以任何方式剝削、傷害或試圖剝削或傷害未成年人，讓他們接觸不適當的內容、要求提供個人身份訊息或其他方式。',
	},
	PROHIBITED_USES_FIFTH_BULLET: {
		EN: 'To send, knowingly receive, upload, download, use, or re-use any material that does not comply with these Terms of Use.',
		JP: '本利用規約に準拠していない資料を送信、故意に受信、アップロード、ダウンロード、使用、または再使用すること。',
		KR: '본 이용 약관을 준수하지 않는 자료를 전송, 고의로 수신, 업로드, 다운로드, 사용 또는 재사용하는 것.',
		CN: '發送、有意接收、上傳、下載、使用或重複使用任何不符合本使用條款的素材。',
	},
	PROHIBITED_USES_SIXTH_BULLET: {
		EN: 'To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail", "chain letter", "spam", or any other similar solicitation.',
		JP: 'ジャンクメール、チェーンレター、スパムまたはその他の同様の勧誘を含む、事前の書面による同意なしに、広告または販促資料を送信する、または送信を調達すること',
		KR: '당사의 사전 서면 동의 없이 "정크 메일", "연속 편지" , "스팸" 또는 기  타 유사한 요청을 포함하여 모든 광고 또는 홍보 자료를 전송하거나 전 달하는 행위.',
		CN: '未經我們事先書面同意，傳輸或促使發送任何廣告或促銷素材，包括任何“垃圾郵件”、“連鎖信”、“垃圾郵件”或任何其他類似的招攬。',
	},
	PROHIBITED_USES_SEVENTH_BULLET: {
		EN: 'To impersonate or attempt to impersonate the Site, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).',
		JP: 'サイト、別のユーザーまたはその他の個人または団体に(前述のいずれかに関連あるメールアドレスまたはスクリーン名を使用することを含むが、これらに限定せず)なりすます、またはなりすましを試みること。',
		KR: '다른 사용자 또는 다른 사람이나 단체를 가장하거나 가 장하려고 시도하는 행위(앞서 설명한 항목 중 하나와 연결된 전자 메일 주소 또는 화면 이름을 포함)',
		CN: '冒充或試圖冒充本網站、其他用戶或任何其他個人或實體（包括但不限於使用與上述任何內容相關的電子郵件或屏幕名稱）',
	},
	PROHIBITED_USES_SEVENTH_TWO_BULLET: {
		EN: "To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site, or which, as determined by us, may harm the owners or users of the Site, or expose them to liability.",
		JP: '誰かによる本サイトの利用または享受を制限または阻害する、または本サイトの所有者またはユーザーに損害を与えたり、責任を負わせたりする可能性があると判断される行為に関わること。',
		KR: '다른 사람의 사이트 사용을 제한 및 금지하거나 사이트 소유자 또는 사용자에게 해를 입히거나 책 임을 지도록 하는 기타 행위에 참여하는 것.',
		CN: '從事任何其他限製或禁止任何人使用本網站的行為，或者我們認為可能損害本網站所有者或用戶或使他們承擔責任的任何其他行為。',
	},
	PROHIBITED_USES_EIGHTH_BULLET: {
		EN: 'Authorize, permit, enable, induce, or encourage any third-party to do any of the above.',
		JP: '第三者が上記のいずれかを行うことを承認、許可、有効化、誘導または奨励すること。',
		KR: '제3자가 위의 행위를 하도록 승인, 허용, 활성화, 유도 또는 권유하는 행위',
		CN: '授權、允許、啟用、誘導或鼓勵任何第三方進行上述任何行為。',
	},
	PROHIBITED_USES_ADDITIONAL: {
		EN: 'Additionally, you agree not to:',
		JP: 'さらに、次のことを行わないことに同意します。',
		KR: '또한 다음과 같은 행위를 하지 않을 것에 동의합니다.',
		CN: '此外，您同意不：',
	},
	PROHIBITED_USES_NINTH_BULLET: {
		EN: 'Use the Site in any manner that could disable, overburden, damage, or impair the Site or interfere with any other party‘s use of the Site, including their ability to engage in real time activities through the Site.',
		JP: 'サイトを無効にする、過剰な負担をかける、損害を与える、または損なう可能性がある、またはサイトを通じてリアルタイムの活動に従事する能力を含め、他者のサイトの利用を妨害する可能性のある方法でサイトを利用すること',
		KR: '사이트를 비활성화, 과도한 부담 또는 손상시키거나 사이트를 통해 실시간 활동에 참여할 수 있는 능력을 포함하여 다른 당사자의 사이트 사용을 방해할 수 있는 방법으로 사이트를 사용하는 것',
		CN: '以任何可能禁用、過度負荷、損壞或損害本網站或乾擾任何其他方使用本網站的方式使用本網站，包括他們通過本網站進行即時活動的能力。',
	},
	PROHIBITED_USES_TENTH_BULLET: {
		EN: 'Use any robot, spider, or other automatic devices, process, or means to access the Site for any purpose, including monitoring or copying any of the material on the Site.',
		JP: 'サイト上の資料の監視またはコピーを含む等、目的に関わらす、ロボット、スパイダーまたはその他の自動デバイス、プロセスまたは手段を使用してサイトにアクセスすること。',
		KR: '로봇, 스파이더 또는 기   타 자동 장치, 프로세스 또는 수단을 사용하여 사이트에 있는 자료를 모니터링하거나 복사하는 등의 목적으로 사이트에 액세스하는 행위.',
		CN: '出於任何目的使用任何機器人、惡意程式或其他自動裝置、過程或手段訪問本網站，包括監控或複製本網站上的任何材料。',
	},
	PROHIBITED_USES_ELEVENTH_BULLET: {
		EN: 'Use any manual process to monitor or copy any of the material on the Site, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent',
		JP: '事前の書面による同意なしに、手動プロセスを使用してサイト上の資料を監視またはコピー、または明示的に許可されていない利用目的で使用すること。',
		KR: '사전 서면 동의 없이 매 뉴얼 프로세스를 사용하여 사이트의 자료를 모니터링 또는 복사하거나 본 이용 약관에서 명시적으로 승인하지 않은 다 른목적으로 사용하는 행위',
		CN: '未經我們事先書面同意，使用任何手動過程監控或複製網站上的任何素材，或用於本使用條款未明確授權的任何其他目的',
	},
	PROHIBITED_USES_TWELVETH_BULLET: {
		EN: 'Use any device, software, or routine that interferes with the proper working of the Site.',
		JP: 'サイトの適切な動作を妨げるデバイス、ソフトウェア、またはルーチンを使用すること。',
		KR: '사이트의 적절한 작동을 방해하는 모든 장치, 소프트웨어 또는 루틴을 사용하는 행위',
		CN: '使用任何干擾網站正常工作的設備、軟體或例程。',
	},
	PROHIBITED_USES_THIRTEEN_BULLET: {
		EN: 'Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.',
		JP: 'ウイルス、トロイの木馬、ワーム、論理爆弾、または悪意のある、または技術的に有害なその他の素材を導入すること。',
		KR: '악의적이거나 기술적으로 유해한 바이러스, 트로이 목마, 웜, 논리 폭탄 또는 기   타 자료를 도입하는 행위',
		CN: '引入任何病毒、特洛伊木馬、邏輯炸彈或其他惡意或技術有害的素材。',
	},
	PROHIBITED_USES_FOURTEEN_BULLET: {
		EN: 'Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site, the server on which the Site is stored, or any server, computer, or database connected to the Site.',
		JP: 'サイト、サイトが保存されているサーバー、またはサイトに接続されているサーバー、コンピューターまたはデータベースのいずれかの部分への不正アクセス、干渉、損傷、または混乱を試みること。',
		KR: '본 웹 사이트의 모든 부분, 저장된 서버 또는 본 웹 사이트에 연결된 모든 서버, 컴퓨터 또는 데이터베이스에 대한 무단 액세스, 방해, 손상 또는  파괴를 시도하는 행위',
		CN: '試圖未經授權連結、干擾、損壞或破壞本網站的任何部分、存儲本網站的服務器或連接到本網站的任何服務器、電腦或數據庫。',
	},
	PROHIBITED_USES_FIFTHTEEN_BULLET: {
		EN: 'Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.',
		JP: 'サービス拒否攻撃または分散型サービス拒否攻撃を介してサイトを攻撃すること。',
		KR: '서비스 거부 공 격 또는 분   산 서비스 거부 공 격을 통해 사이트를 공격하는 행위',
		CN: '通過阻斷服務攻擊或分佈式阻斷服務攻擊來攻擊站點。',
	},
	PROHIBITED_USES_SIXTEEN_BULLET: {
		EN: 'Otherwise attempt to interfere with the proper working of the Site.',
		JP: 'それ以外、サイトの適切な動作を妨害しようとすること。',
		KR: '혹은 사이트의 올바른 작동을 방해하는 행위',
		CN: '否則試圖干擾網站的正常工作。',
	},
	PROHIBITED_USES_SIXTEEN_TWO_BULLET: {
		EN: 'If you violate these Terms of Use, your access to the Site may be terminated immediately and without notice. In other words, just be respectful and use the Site for its intended purpose.',
		JP: 'これらの利用規約に違反した場合、サイトへのアクセスは、予告なしに直ちに終了される場合があります。言い換えれば、敬意を払い、本来の目的のために本サイトを利用ください。',
		KR: '이 이용 약관을 위반하는 경우 사이트에 대한 액세스가 통지 없이 즉시 종료 될 수 있습니다. 다시 말해서, 의도된 목적을 존중하여 사이트를 사용하십시오.',
		CN: '如果您違反這些使用條款，您對本網站的連結可能會立即終止，恕不另行通知。 換句話說，只要您尊重的出於預期目的使用本網站。',
	},
	PROHIBITED_USES_SIXTEEN_THREE_BULLET: {
		EN: 'We may limit or terminate our service, remove hosted content and take technical and legal steps to keep users off the Site if we think they are creating problems or acting inconsistently with the letter or spirit of our policies. Please report problems, offensive content, and policy violations by emailing us at',
		JP: 'ユーザーが問題を引き起こしている、またはポリシーの文言や精神に反する行動をしていると思われる場合、サービスを制限または終了、ホストされているコンテンツを削除し、技術的および法的措置でユーザーをサイトから遠ざけることがあります。問題、攻撃的なコンテンツ、およびポリシー違反は、次のアドレスにご報告ください。',
		KR: '당사는 사용자가 문제를 일으키거나 당사 정책의 서신 또는 정신과 일치하지 않는 행동을 한다고 생각되는 경우 서비스를 제한 또는 종료, 호스팅된 콘 텐츠를 제 거하고, 기술적 및 법적 조치를 취하여 사이트에서 사용자를 차단할 수 있습니다. 다음 주소로 이메일을 보내 문제, 불쾌감을 주는 콘텐츠 및 정책 위  반을 신고하세요.',
		CN: '如果我們認為用戶正在製造問題或行為不符合我們政策的文字或精神時，我們可能會限製或終止我們的服務、刪除託管內容並採取技術和法律措施阻止用戶連結本網站。 請通過電子郵件向我們反映問題、攻擊性內容和違反政策的行為：',
	},
	PROHIBITED_USES_SIXTEEN_THREE_BULLET_LINK: {
		EN: 'info@travel.mp',
		JP: 'info@travel.mp',
		KR: 'info@travel.mp',
		CN: 'info@travel.mp',
	},
	CHANGES_TO_THE_WEBSITE: {
		EN: 'Changes to the Website',
		JP: 'ウェブサイトの変更',
		KR: '웹사이트 변경',
		CN: '網站變更',
	},
	CHANGES_TO_THE_WEBSITE_PARAGRAPH: {
		EN: 'We may update the content on this Site from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Site may be out of date at any given time, and we are under no obligation to update such material.',
		JP: '本サイトのコンテンツを随時更新することがありますが、そのコンテンツは必ずしも完全または最新のものではありません。サイト上の資料は、古い可能性がありますが、そのような資料を更新する義務はありません。',
		KR: '당사는 이 사이트의 콘텐츠를 수시로 업데이트할 수 있지만 해당 콘   텐츠가 꼭 완벽하거나 최신 상태이지 않을수 있습니다. 사이트의 자료는 언제든지 최신이 아닐 수 있으  며 당사는 그러한 자료를 업데이트할 의무가 없습니다.',
		CN: '我們可能會不時更新本網站上的內容，但其內容不一定完整或最新。本網站上的任何素材在任何給定時間都可能過時，我們沒有義務更新此類素材。',
	},
	INFORMATION_ABOUT_YOU: {
		EN: 'Information About You and Your Visits to the Site',
		JP: 'あなたとサイトへの訪問に関する情報',
		KR: '귀하 및 귀하의 사이트 방문에 대한 정보',
		CN: '關於您和您訪問網站的訊息',
	},
	INFORMATION_ABOUT_YOU_PARAGRAPH: {
		EN: 'All information we collect on this Site is subject to our Privacy Notice. By using the Site, you consent to all actions taken by us with respect to your information in compliance with the Privacy Notice. By using the Site, you agree to the collection, transfer, storage and use of your personal information by the CNMI Government on servers located in United States of America, as further described in our Privacy Notice.',
		JP: 'このサイトで収集するすべての情報は、プライバシー通知の対象となります。サイトを利用することで、プライバシー通知に従ってあなたの情報に関して私達が行うすべての措置に同意するとみなします。サイトを利用することにより、当社のプライバシー通知で詳しく説明されているように、アメリカ合衆国にあるサーバー上でCNMI 政府によるあなたの個人情報の収集、転送、保管、および使用に同意するものとします',
		KR: '이 사이트에서 수집하는 모든 정보는 개인정보 보호정책의 적용을 받습니다. 사이트를 사용함으로써귀하는 개인정보 보호정책에 따라 귀하의 정보와 관련하여 당사가 취하는 모든 조치에 동의하는 것입니다. 사이트를 사용함으로써 귀하는 개인 정보 보호 고지에 자세히 설명된 대로 CNMI 정부가 미국에 위치한 서버에서 귀하의 개인 정보를 수집, 전송, 저장 및 사용하는 데 동의합니다.',
		CN: '在本網站上收集的所有訊息均受我們的隱私聲明的約束。通過使用本網站，您同意我們根據隱私聲明對您的訊息採取的所有行動。通過使用本網站，您同意北馬里亞納群島聯邦政府在位於美國的服務器上收集、傳輸、存儲和使用您的個人訊息，如我們的隱私聲明中所述。',
	},
	LINKING_TO_THE_SITE: {
		EN: 'Linking to the Site and Social Media Features',
		JP: 'サイトおよびソーシャル メディア機能へのリンク',
		KR: '사이트 및 소셜 미디어 기능에 대한  링크',
		CN: '連結到網站和社交媒體功能',
	},
	LINKING_TO_THE_SITE_PARAGRAPH: {
		EN: 'You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our expressed written consent.',
		JP: '私達の評判を傷つけず、公正かつ合法的に行なう場合は、私達のホームページにリンクいただくことは出来ますが、私どもの書面による同意なしに、私共側の承認または支持や何らかの形の関連性を示唆するような形でリンクを設定してはなりません。',
		KR: '귀하는 공정하고 합법적인 방식으로 당사 홈페이지에 링크할 수 있으며, 당사의 평판을 손상시키거나 이용하지 않는 한  링크를 개설할 수 있지만, 당사의 명시적인 서면 동의 없이 당사에 어떠한 형태 의 협회, 승인 또는 보증 할 수 없습니다.',
		CN: '您可以連結到我們的主頁，前提是您以公平合法的方式這樣做，並且不會損害或利用我們的聲譽，但您不得以暗示任何形式的關聯的方式建立連接，未經我們明確的書面同意，批准或背書。',
	},
	GEOGRAPHIC_RESTRICTIONS: {
		EN: 'Geographic Restrictions',
		JP: '地理的制限',
		KR: '지리적 제한',
		CN: '地域限制',
	},
	GEOGRAPHIC_RESTRICTIONS_PARAGRAPH: {
		EN: 'The owner of the Site is based in the CNMI, a territory of the United States of America. We provide this Site for use only by persons located in the CNMI. We make no claims that the Site or any of its content is accessible or appropriate outside of the CNMI. Access to the Site may not be legal by certain persons or in certain countries. If you access the Site from outside the CNMI, you do so on your own initiative and are responsible for compliance with local laws.',
		JP: 'サイトの所有者は、アメリカ合衆国の領土であるCNMI に拠点を置いています。私達は、CNMI に所在する方のみが使用できるように、このサイトを提供しています。私達はサイトまたはそのコンテンツのいずれもCNMI 外でアクセス可能または適切であるとは主張しません。サイトへのアクセスは、特定の個人または特定の国では合法ではない場合があります。CNMI の外部からサイトにアクセスする場合は、自らの意思でアクセスし、現地の法律を遵守する責任があります。',
		KR: '사이트 소유자는 미국 영토인 북마리아나제도(CNMI)에 기 반을 두고 있습니다. 우리는 북마리아나제도에 위치한 사람들만 사용할 수 있도록 이 사이트를 제공합니다. 우 본 사이트 또는 해당 컨텐츠가 CNMI 외부에서 액세스 가능하거나 적절하다고 주장하지 않습니다. 특정 사람이나 특정 국가에서는 사이트에 대한 액세스가 합법적이지 않을 수 있습니다.  CNMI 외부에서 사이트에 액세스하는 경우 사용자는 스스로 액세스하고 현지 법 률을 준수해야 할 책임이 있습니다.',
		CN: '本網站的所有人位於美眾國領土北馬里亞納群島。我們提供本網站僅供位於北馬里亞納群島的人員使用。我們不聲稱本網站或其任何內容可在北馬里亞納群島之外訪問或適用。某些人或在某些國家連結本網站可能不合法。如果您從北馬里亞納群島以外的地方連結本網站，您應主動連結並負責遵守當地法律。',
	},
	DISCLAIMER_OF_WARRANTIES: {
		EN: 'Disclaimer of Warranties',
		JP: '保証の否認',
		KR: '보증 거부',
		CN: '免責聲明',
	},
	DISCLAIMER_OF_WARRANTIES_FIRST_PARAGRAPH: {
		EN: 'You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Site will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data.',
		JP: 'あなたはインターネットまたはサイトからダウンロードできるファイルにウイルスやその他の破壊的なコードが含まれていないことを保証または保証できないことを理解します。ウイルス対策保護とデータの入力と出力の正確さに関する特定の要件を満たすために十分な手順とチェックポイントを実装し、失われたデータを再構築するためのサイト外の手段を維持することは、あなたの責任です。',
		KR: '귀하는 당사가 인터넷 또는 사이트에서 다운로드할 수 있는 파일에 바이러스 또는 기타파괴적인 코드가 없음을 보증할 수 없고 보증하지 않는다는 점을 알고 계셔야 합니다. 안티바이러스 보호 및 데이터 입력 및 출력 정확도에 대한 특별 요구 사항을 충족하기 위해 충분한 절차 및 체크포인트를 구현하고 손실된 데이터를 재구성하기 위해 웹 사이트 외부의 방법을 유지하는 책임이 있습니다.',
		CN: '您了解我們不能也不保證或保證可從網路或網站下載的文件沒有病毒或其他破壞性代碼。您有責任實施足夠的程序和檢查，以滿足您對防病毒保護和數據輸入和輸出準確性的特殊要求，並負責維護我們網站外部的方法來重建任何遺失的數據資料。',
	},
	DISCLAIMER_OF_WARRANTIES_SECOND_PARAGRAPH: {
		EN: 'TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE LINKED TO IT.',
		JP: '法律で規定されている最大限の範囲で、あなたが本サイトまたは本サイトを通じて取得したサービスまたはアイテムの使用、または本サイト、または本サイトにリンクされたサイトに掲載された資料のダウンロードに伴う分散型サービス拒否攻撃、ウイルス、またはお客様のコンピュータ機器、コンピュータプログラム、データ、またはその他に影響を与える可能性のあるその他の技術的に有害な素材によって引き起こされた損失または損害について私達は責任を負いません。',
		KR: '법이 제공하는 최대한의 범위 내에서, 우리는 배포된 서비스 거부 공 격, 바이러스 또는 기타기술적으로 유해한 방법로 인해 발생하는 손실이나 손상에 대해 책임을 지지  않습니다. 귀하의 사이트 사용, 사이트를 통해 얻은 서비스나 항목을 사용, 사이트에 게시된 자료 또는 이에 연결된 사이트를 다운로드하여 소유하는 행위로 당신의 컴퓨터를 감염 시킬 수 있습니다.',
		CN: '在法律規定的最大範圍內，我們不對由分佈式阻斷服務攻擊、病毒或其他可能影響您的電腦設備、電腦程序、數據或其他技術的有害材料造成的任何損失或損害負責專有素材由於您使用本網站或通過本網站獲得的任何服務或項目，或您下載發佈在本網站或與本網站連接的任何網站上的任何素材。',
	},
	DISCLAIMER_OF_WARRANTIES_THIRD_PARAGRAPH: {
		EN: 'YOUR USE OF THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE CNMI GOVERNMENT NOR ANY PERSON ASSOCIATED WITH THE CNMI GOVERNMENT MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE CNMI GOVERNMENT NOR ANYONE ASSOCIATED WITH THE CNMI GOVERNMENT REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.',
		JP: 'サイト、そのコンテンツ、およびサイトを通じて取得したサービスまたはアイテムの使用はあなた自身の責任で行ってください。サイト、そのコンテンツ、およびサイトを通じて取得したサービスまたはアイテムは、明示または黙示を問わず、いかなる種類の保証もなく、「現状のまま」および「利用可能なまま」で提供されます。CNMI 政府またはCNMI 政府に関連する人物は、サイトの完全性、セキュリティ、信頼性、品質、正確性、または可用性に関して、いかなる保証または表明も行いません。上記を制限することなく、CNMI 政府またはCNMI 政府に関連するいかなる人物も、サイト、そのコンテンツ、またはサイトを通じて取得したサービスまたはアイテムが、正確で、信頼性が高く、エラーがなく、または中断されず、欠陥のあるものであることを表明または保証しません。サイトまたはそれを利用できるようにするサーバーにウイルスやその他の有害なコンポーネントが含まれていないこと、またはサイトまたはサイトを通じて取得したサービスまたはアイテムがお客様のニーズまたは期待を満たすことを保障するものではありません。',
		KR: '사이트, 사이트 콘텐츠 및 사이트를 통해 얻은 모든 서비스 또는 항목을 사용하는 데 따른 위험은 귀하가 감수해야 합니다.사이트, 사이트의 콘텐츠 및 사이트를 통해 얻은 모든 서비스 또는 항목은 명시적이든 묵시적이든 어떤 종류의 보증 없이 "있는 그대로" "이용 가  능한 대로" 제공됩니다.CNMI 정부나 CNMI 정부 관계자는 사이트의 완전성, 보안, 신뢰성, 품질, 정확성 또는 가용성과 관련하여 어  떠한 보증이나 확인을 하지 않습니다.기술된 내용뿐만 아니라 북마리아나 제도 정부와 관련된 누구도 북마리아나 정부를 대표하여 해당 사이트, 그안의 컨텐츠 또는 사이트를 통해 얻은 모든 항목, 또는 바이러스가 없다는 보증등을 할수 없습니다.  당사 사이트 또는 이를 사용할 수 있게 하는 서버에 바이러스 또는 기타 유해한 구성 요소가 없거나 사이트 또는 사이트를 통해 얻은 서비스 또는 항목이 문제가 없는경우 귀하의 요구 사항을 충족하거나 충족할 것입니다.',
		CN: '通過本網站獲得的任何服務或物品均由您自行承擔風險。本網站、其內容以及通過本網站獲得的任何服務或項目均按“原樣”和“現有”基礎提供，不提供任何形式的明示或暗示保證。北馬里亞納群島政府或與有關的任何人均不對本網站的完整性、安全性、可靠性、質量、準確性或可用性作出任何保證或陳述。在不限制上述規定的情況下，北馬里亞納群島政府或與相關的任何人都不代表或保證本網站、其內容或通過本網站獲得的任何服務或項目將是準確、可靠、無錯誤或不間斷的，即缺陷將更正，我們的網站或提供該網站的服務器沒有病毒或其他有害組件，或者該網站或通過該網站獲得的任何服務或項目將滿足您的需求或期望。',
	},
	DISCLAIMER_OF_WARRANTIES_FOURTH_PARAGRAPH: {
		EN: 'TO THE FULLEST EXTENT PROVIDED BY LAW, THE CNMI GOVERNMENT HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.',
		JP: '法律で規定されている最大限の範囲で、CNMI 政府は、明示的か黙示的か、法定かどうかにかかわらず、商品性、非侵害、および特定の目的への適合性の保証を含むがこれらに限定されない、あらゆる種類のすべての保証を否認します。',
		KR: '사이트, 사이트의 콘텐츠 및 사이트를 통해 얻은 모든 서비스 또는 항목은 명시적이든 묵시적이든 어떤 종류의 보증 없이 "있는 그대로" "이용 가  능한 대로" 제공됩니다.',
		CN: '在法律規定的最大範圍內， 北馬里亞納群島政府特此否認任何形式的明示或暗示、法定或其他形式的保證，包括但不限於對適銷性、非侵權性和特定用途適用性的任何保證。',
	},
	DISCLAIMER_OF_WARRANTIES_FIFTH_PARAGRAPH: {
		EN: 'THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.',
		JP: '上記は、適用法の下で除外または制限できない保証には影響しません。',
		KR: 'CNMI 정부나 CNMI 정부 관계자는 사이트의 완전성, 보안, 신뢰성, 품질, 정확성 또는 가용성과 관련하여 어  떠한 보증이나 진술도 하지 않습니다.',
		CN: '上述內容不影響任何根據適用法律不得排除或限制的保證。',
	},
	LIMITATION_ON_LIABILITY: {
		EN: 'Limitation on Liability',
		JP: '責任の制限',
		KR: '책임 제한',
		CN: '責任限制',
	},
	LIMITATION_ON_LIABILITY_FIRST_PARAGRAPH: {
		EN: 'TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE CNMI GOVERNMENT AND AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED ZERO U.S. DOLLARS (US$0.00).',
		JP: '法律で規定されている最大限の範囲で、CNMI 政府とその関連会社、およびそれらのライセンサー、サービスプロバイダー、従業員、代理人、役員、および取締役の責任は、いずれの当事者に対しても(訴訟の形式に関係なく、契約上、不法行為またはその他の場合) 50 米ドル(US$50.00) を超えることはない。',
		KR: '법률이 제공하는 최대한의 범위 내에서, 어떠한 경우에도 CNMI 정부와 계열사, 그 사용  허가자, 서비스 제공자, 직원, 대리인, 임원 및 이사도 (계약 형태에 관계 없이) 어떠한 당사자에게도 미화00달러(US$00.00)를 초과하는 집단적 책  임도 지지  않습니다.',
		CN: '在法律規定的最大範圍內，在任何情況下，北馬里亞納群島政府及其附屬機構及其許可方、服務提供商、員工、代理人、官員和董事均不對任何一方承擔集體責任（無論採取何種行動形式，無論在合約、侵權或其他方面）超過五十美元（50.00 美元）。',
	},
	LIMITATION_ON_LIABILITY_SECOND_PARAGRAPH: {
		EN: 'THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.',
		JP: '上記は、適用法の下で除外または制限できない責任には影響しません。',
		KR: '기재된 내용은 해당 법률에 따라 제외되거나 제한 될 수 없는 모든 책임에 영 향을 미치지 않습니다.',
		CN: '上述規定不影響適用法律下不能排除或限制的任何責任。',
	},
	INDEMNIFICATION: {
		EN: 'Indemnification',
		JP: '補償',
		KR: '면책',
		CN: '保障',
	},
	INDEMNIFICATION_PARAGRAPH: {
		EN: "You agree to defend, indemnify, and hold harmless the CNMI Government, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Site, including, but not limited to any use of the Site's content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Site.",
		JP: 'あなたは、CNMI 政府、その関連会社、ライセンサー、およびサービスプロバイダー、ならびにそのそれぞれの役員、取締役、従業員、請負業者、代理人、ライセンサー、サプライヤー、後継者、および譲受人を防御し、補償し、無害に保つことに同意、本利用規約で明示的に許可されている以外のサイトのコンテンツ、サービス、および製品の使用、またはサイトから取得した情報の使用を含み本利用規約の違反または本サイトの使用に起因または関連する請求、責任、損害、判決、裁定、損失、費用、出費、または料金(合理的な弁護士費用を含む)の責任を追わないことに同意します。',
		KR: '귀하는 CNMI 정부, 그 부속 기관, 면허소지자 및 서비스 제공업체와 각각의 임원, 이사, 직원, 계약자, 대리인, 면허소지자, 공급자, 후임자 및 양수인을 피해로부터 보호하고 배상하는 데 동의합니다. 본 이용약관의 위반 또는 본 이용약관의 사용으로 인한 청구, 책임, 손해배상, 판결, 판결, 손실, 비용, 비용 또는 비용(합리적인 변호사 비용 포함)은 본 이용약관의 명시적 승인 외에 본 사이트의 내용, 서비스 및 제품의 사용 또는 본 사이트에서 얻은 정보의 사용을 포함하되 이에 국한되지 않습니다.',
		CN: '您同意保護、賠償北馬里亞納島政府、其附屬機構、許可方和服務提供商及其各自的高級職員、董事、僱員、承包商、代理人、許可方、供應商、繼任者和受讓人免受任何損害，並使其免受任何損害。因您違反本使用條款或您對本網站的使用而引起或與之相關的索賠、責任、損害賠償、判決、裁決、損失、成本、費用或費用（包括合理的律師費），包括但不限於除本使用條款明確授權外，對本網站內容、服務和產品的任何使用，或您對從本網站獲得的任何訊息的使用。',
	},
	GOVERNING_LAW: {
		EN: 'Governing Law and Jurisdiction',
		JP: '準拠法および管轄',
		KR: '준거법 및 관할',
		CN: '治理法律和管轄區',
	},
	GOVERNING_LAW_PARAGRAPH: {
		EN: 'All matters relating to the site and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of the Commonwealth of the Northern Mariana Islands without giving effect to any choice or conflict of law provision or rule.',
		JP: 'サイトおよび本利用規約に関するすべての事項、およびそこから生じるまたは関連する紛争または請求(いずれの場合も、非契約上の紛争または請求を含む) は、法の規定または規則の選択または抵触に影響を与えることなく、北マリアナ諸島の連邦法に準拠し、同法に従って解釈されるものとします。',
		KR: '사이트 및 본 이용 약관과 관련된 모든 문제, 그로부터 발생하거나 이와 관련된 모든 분쟁 또는 청구(각각의 경우 비계약 분쟁 또는 청구 포함)는 영연방 법률의 적용을 받고 이에 따라 해석됩니다. 법 조항이나 규칙의 선택이나 충돌에 영 향을 미치지 않고 북마리아나 제도의 본 이용약관 또는 사이트와 관련하여 발생하거나 이와 관련하여 발생하는 모든 법적 소송, 조치 또는 절차는 당사가 귀하의 거주 국가 또는 기타 관련 국가에서 본 이용 약관 위반에 대해 귀하를 상대로 소송, 조치 또는 소송을 제기할 수 있습니다. 귀하는 해당 법원의 관할권 행사 및 해당 법원의 재판지에 대한 모든 이의를 포기합니다',
		CN: '與本網站和本使用條款有關的所有事項，以及由此產生或與之相關的任何爭議或索賠（在每種情況下，包括非合約爭議或索賠），均應受聯邦法律管轄並按其解釋北馬里亞納群島的任何選擇或衝突的法律規定或規則。由本使用條款或本網站引起或與之相關的任何法律訴訟、訴訟或程序應僅在北馬里亞納群島美國地方法院或北馬里亞納群島法院提起，儘管我們保留在您居住的國家/地區或任何其他相關國家/地區因您違反這些使用條款而對您提起任何訴訟、訴訟或訴訟。您放棄對此類法院對您行使管轄權和在此類法院審理的任何和所有異議。',
	},
	GOVERNING_LAW_TWO_PARAGRAPH: {
		EN: 'Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Site shall be instituted exclusively in the U.S. District Court of the Northern Mariana Islands or the courts of the CNMI, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.',
		JP: '本利用規約またはサイトに起因または関連する訴訟、訴訟、または手続きは、北マリアナ諸島の米国地方裁判所またはCNMI の裁判所でのみ提起されるものとします。あなたの居住国またはその他の関連する国で、これらの利用規約に違反したとして、あなたに対して訴訟、訴訟、または訴訟を起こすことはあり得ます。あなたは、そのような裁判所による管轄権の行使およびそのような裁判所の裁判地に対するあらゆる異議を放棄します。',
		KR: '본 이용약관 또는 사이트와 관련하여 발생하거나 이와 관련하여 발생하는 모든 법적 소송, 조치 또는 절차는 당사가 귀하의 거주 국가 또는 기타 관련 국가에서 본 이용 약관 위반에 대해 귀하를 상대로 소송, 조치 또는 소송을 제기할 수 있습니다. 귀하는 해당 법원의 관할권 행사 및 해당 법원의 재판지에 대한 모든 이의를 포기합니다.',
		CN: '由本使用條款或本網站引起或與之相關的任何法律訴訟、訴訟或程序應僅在北馬里亞納群島美國地方法院或北馬里亞納群島法院提起，儘管我們保留 在您居住的國家/地區或任何其他相關國家/地區因您違反這些使用條款而對您提起任何訴訟、訴訟或訴訟。 您 放棄對此類法院對您行使管轄權和在此類法院審理的任何和所有異議。',
	},
	ARBITRATION: {
		EN: 'Arbitration',
		JP: '仲裁',
		KR: '중재',
		CN: '仲裁',
	},
	ARBITRATION_PARAGRAPH: {
		EN: 'At the CNMI Government’s sole discretion, it may require you to submit any disputes arising from these Terms of Use or use of the Site, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying CNMI law.',
		JP: 'CNMI 政府の独自の裁量により、これらの利用規約またはサイトの使用に起因する紛争(解釈、違反、無効、不履行、または終了に起因または関連する紛争を含む) を最終およびCNMI 法を適用する米国仲裁協会の仲裁規則に基づく拘束力のある仲裁とすることがあり得ます。',
		KR: 'CNMI 정부의 단독 재량에 따라 본 사이트의 해석, 위반, 무효, 불이행 또는 종료로 인한 또는 이와 관련된 분 쟁을 포함하여 본 사이트의 이용 약관 또는 이용 약관에서 발생하는 모든 분쟁을 CNMI 법을 적용한 미국중재협회의 중재.규칙에 따른 최종적이고 구속력 있는 중재에 제출할 것을 요구할 수 있습니다',
		CN: '北馬里亞納群島政府可全   權酌情要求您將因本  使用條款或網站使用引起的任何爭議，包括因解釋、違反、無效、不履行或終止而引起或與之相關的爭議，提交給最終和根據適用北馬里亞納群島法律的美國仲裁協會仲裁規則進行具有約束力的仲裁。',
	},
	LIMITATION_ON_TIME: {
		EN: 'Limitation on Time to File Claims',
		JP: '訴訟請求を提出する時間の制限',
		KR: '청구 제  출 기간 제한',
		CN: '提出索賠的時間限制',
	},
	LIMITATION_ON_TIME_PARAGRAPH: {
		EN: 'ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED, UNLESS AN APPLICABLE STATUTE OF LIMITATIONS FROM CNMI LAW OTHERWISE APPLIES.',
		JP: '本利用規約または本サイトに起因または関連するお客様の訴訟または請求の原因は、訴訟原因が発生してから1 年以内に開始する必要があります。それ以外の場合、CNMI 法による該当する制限法規が別途適用されない限り、そのような訴因または請求は永久に禁止されます。',
		KR: '본 이용약관 또는 사이트와 관련하여 귀하가 제기할 수 있는 조치 또는 청구의 원인은 조치의 원인이 발생한 후 1년 이 내에 시작되어야 합니다. 그렇지 않으면, CNMI 법의 적용 가능한 제한 규정이 달리 적용되지 않는 한, 그러한 조치 또는 청구의 원인은 영구적으로 금지됩니다.',
		CN: '您可能因本使用條款或本網站而引起或與之相關的任何訴訟理由或索賠必須在訴訟理由發生後一(1) 年內開始；否則，此類訴訟或索賠將被永久禁止，除非北馬里亞納群島法律的適用限制法規另有適用。',
	},
	WAIVER_AND_SEVERABILITY: {
		EN: 'Waiver and Severability',
		JP: '権利放棄と可分性',
		KR: '면책 및 계약의 가분성',
		CN: '棄權和可分割性',
	},
	WAIVER_AND_SEVERABILITY_PARAGRAPH: {
		EN: 'No waiver by the CNMI Government of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the CNMI Government to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.',
		JP: 'これらの利用規約に定められた条項または条件の CNMI 政府による放棄は、そのような条項または条件のさらなるまたは継続的な放棄、または他の条項または条件の放棄とはみなされず、CNMI 政府が主張を怠ったものとはみなされません。 本利用規約に基づく権利または条項は、そのような権利または条項の放棄を構成するものではありません。',
		KR: '본 이용약관에 명시된 조건에 대한 북마리아나제도 정부의 포기는 해당 조건에 대한 추가 또는 계속적인 포기 또는 다른 조건의 포기 및 북마리아나제도 정부가 다음을 주장하지 않는 것으로 간주되지 않습니다. 북마리아나제도 정부가 본 이용약관에 따른권리나 조항을 주장하지 못한다고 해서 그러한 권리나 조항의 포기로 간주되지 않습니다.',
		CN: '北馬里亞納群島聯邦政府對本使用條款中規定的任何條款或條件的棄權不應被視為對該條款或條件的進一步或持續棄權或對任何其他條款或條件的棄權，並且北馬里亞納群島聯邦政府未能主張本使用條款下的權利或規定不構成對該權利或規定的放棄。',
	},
	WAIVER_AND_SEVERABILITY_SECOND_PARAGRAPH: {
		EN: 'If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.',
		JP: 'これらの利用規約のいずれかの条項が、管轄権を有する裁判所またはその他の法廷によって、何らかの理由で無効、違法、または執行不能であると判断された場合、そのような条項は削除されるか、または利用規約の残りの条項が最小限に制限されるように制限されるものとし、その他の利用規約は完全な効力を持ち続けます。',
		KR: '본 이용약관의 어떤 조항이 법원이나 관할권 있는 기   타 재판소에서 어떤 이유로든 무효, 불법 또는 집행 불가능하다고 판결하는 경우, 해당 조항은 제거되거나 이용약관의 나머지 조항이 최소한의 범위로 제한됩니다. 사용은 계속 유효합니다.',
		CN: '如果本使用條款的任何條款被具有管轄權的法院或其他法庭裁定為無效、非法或因任何原因無法執行，則該條款應被取消或限制在最低限度，以使本條款的其餘條款 使用將繼續完全有效。',
	},
	ENTIRE_AGREEMENT: {
		EN: 'Entire Agreement',
		JP: '完全合意',
		KR: '전체 계약',
		CN: '整個協議',
	},
	ENTIRE_AGREEMENT_PARAGRAPH: {
		EN: 'The Terms of Use and our Privacy Notice constitute the sole and entire agreement between you and the CNMI Government regarding the Site and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Site.',
		JP: '利用規約とプライバシー通知は、サイトに関するお客様とCNMI 政府との間の唯一かつ完全な合意を構成し、サイトに関する書面および口頭の以前および同時期のすべての了解、合意、表明、および保証に優先します。',
		KR: '이용 약관 및 개인 정보 보호 통지는 사이트에 대한 귀하와 CNMI 정부 사이의 단독 및 전체 계약을 구성하며 사이트에 대한 서면 및 구두의 모든 이전 및 동시 이해, 계약, 표현 및 보증을 대체합니다.',
		CN: '使用條款和我們的隱私聲明構成您與北馬里亞納群島聯邦政府之間關於本網站的唯一完整協議，並取代所有先前和同時期關於本網站的書面和口頭的理解、協議、陳述和保證。',
	},
	YOUR_COMMENTS: {
		EN: 'Your Comments and Concerns',
		JP: 'あなたのコメントと懸念',
		KR: '귀하의 의견 및 우려 사항',
		CN: '您的意見和疑慮',
	},
	YOUR_COMMENTS_PARAGRAPH: {
		EN: 'All feedback, comments, requests for technical support, and other communications relating to the Site should be directed to:',
		JP: 'サイトに関する全てのフィードバック、コメント、テクニカルサポートのリクエスト等は下記へ連絡：',
		KR: '사이트와 관련된 모든  피드백, 의견, 기술 지원 요청 및 기타 커뮤니케이션은 다음 주소로 보내야 합니다.',
		CN: '所有與本網站有關的反饋、評論、技術支援請求和其他通信都應發送至：',
	},
	PRIVACY_NOTICE_FIRST_PARAGRAPH: {
		EN: "The Site serves as the CNMI Government's platform for border protection. It is designed to streamline the process of traveler declarations through both desktop and mobile versions. The system facilitates the electronic submission of Marianas Visitors Authority and CNMI Customs Biosecurity declaration forms by travelers. These forms are a mandatory requirement for all visitors entering CNMI, whether they are arriving domestically or internationally. This process helps to ensure efficient border protection and biosecurity measures. As part of our Privacy Notice Agreement, we want to ensure that travelers understand how their personal information is collected, used, and protected within this system.",
		JP: 'サイトはCNMI 政府の国境保護プラットフォームです。デスクトップ版とモバイル版で利用できるこのシステムは、国境でのワクチン記録の検証と入国前の確認、マリアナ政府観光局の申告書、CNMI税関検疫申告書の管理を補完して、個人が国内でも海外からでもCNMIへ入国する前にリマインドすることを目的としています。このシステムは、パンデミック、伝染病、および新興疾患の症例管理と接触追跡を提供するSafe Travels Marianas パンデミック緩和プラットフォームとリンクされています。',
		KR: '사이트는 북마리아나제도 정부의 국경 보호 플랫폼입니다. 데스크톱 및 모  바일 버전으로 제공되는 이 시스템은 국경 및 입국 전 백신 기록 확인 관리, 마리아나 방문자 당국 신고서, 북마리아나제도 세관 및 검역 신고서 관리를 보완하여 개인이 여행하기 전에 상기시켜줍니다. 이 시스 템은 팬데믹, 전염병 및 신 흥 질병에 대한 사례 관리 및 접촉추적을 제공하는 Safe Travels Marianas 팬데믹 완화 플랫폼과 연결되어 있습니다.',
		CN: '該站點是北馬里亞納群島聯邦政府的邊境保護平台。 該系統提供桌面版和移動版，旨在補充邊境和入境前的疫苗記錄驗證管理、馬里亞納遊客管理局申報表和北馬里亞納群島聯邦海關與檢疫申報表，以提醒個人在入境前北馬里亞納群島聯邦在國內和國際上都有。 該系統與 Safe Travels Marianas 流行病緩解平台相關聯，該平台為流行病、流行病和新出現的疾病提供病例管理和接觸者追踪。',
	},
	PRIVACY_NOTICE_SECOND_PARAGRAPH: {
		EN: 'Any reference to “WE” or “OUR” means the Site and/or—collectively—the CNMI Government, platform, and its partner institutions.',
		JP: '私たち」または「私たちの」への言及は、サイトおよび/または総称してCNMI政府、プラットフォーム、およびそのパートナー機関を意味します。',
		KR: '"WE" 또는 "OUR"에 대한  언급은 사이트 및/또는 CNMI 정부, 플랫폼 및 그 파트너 기관을 의미합니다.',
		CN: '對“我們”或“我們的 ”的任何提及均指本網站和/或（統稱）北馬里亞納群島聯邦政府、平台及其合作機構。',
	},
	PRIVACY_NOTICE_THIRD_PARAGRAPH: {
		EN: 'This Privacy Notice explains what information we collect, process, and share; why we do so; and your rights with regard to your information.',
		JP: 'このプライバシー通知では、私たちが収集、処理および共有する情報について説明しています。なぜそうするのか;あなたの情報に関するあなたの権利。',
		KR: '이 개인 정보 보호 통지는 수집, 처리 및 공유되는 정보, 수집 및 처리 이유, 사용자의 정보에 대한 사용자의 권리를 설명합니다.',
		CN: '本隱私聲明解釋了我們收集、處理和共享的訊息；我們為什麼這樣做；以及您對您的訊息的權利。',
	},
	PRIVACY_NOTICE_FOURTH_PARAGRAPH: {
		EN: 'We may amend this Privacy Notice from time to time. When certain changes require your consent, we shall communicate the changes to you and ask for your consent. Otherwise, changes take effect immediately upon posting.',
		JP: '私たちは、このプライバシー通知を随時修正することがあります。特定の変更にあなたの同意が必要な場合、変更点を通知し同意を求めます。それ以外の場合、変更は即時有効になります。',
		KR: '당사는 이 개인정보 보호정책을 수시로 수정할 수 있습니다. 특정 변경 사항이 귀하의 동의를 필요로 하는 경우, 당사는 귀하에게 변경 사항을 알리고 귀하의 동의를 구할 것입니다. 그렇지 않으면 변경 사항이 게시되는 즉시 적용됩니다.',
		CN: '我們可能會不時修改本隱私聲明。 當某些更改需要您的同意時，我們會將更改傳達給您並徵求您的同意。 否則，更改將在發布後立即生效。',
	},
	INFORMATION_WE_COLLECT: {
		EN: 'WHAT INFORMATION WE COLLECT',
		JP: '収集する情報',
		KR: '우리가 수집하는 정보',
		CN: '我們收集什麼訊息',
	},
	INFORMATION_WE_COLLECT_FIRST_PARAGRAPH: {
		EN: 'We prioritize data privacy and only collect information that is essential for the application to function. No data will be collected from our users until they have agreed to our privacy policy’s terms and conditions and provided their consent. To register an account, we only require users to provide their mobile numbers. Mobile numbers are used for verification through a One-Time-Password (OTP) system and to allow respective local government offices (LGOs) to communicate with users regarding Marianas Visitors Authority and CNMI Customs Biosecurity declarations.',
		JP: '私たちはデータのプライバシーを主張し、アプリケーションがその機能を実行するために完全に必要なものだけを要求することを保証します。ユーザーがプライバシーポリシーに記載されている条件に同意するまで、ユーザーから情報が収集されることはありません。アカウント登録のために必要なのは携帯電話番号のみです。携帯電話番号は、ワンタイムパスワード(OTP) 機能と、それぞれの地方自治体(LGU) の連絡先情報を介して検証ツールとして使用され、健康、マリアナ政府観光局、CNMI 税関、および税関検疫事務所に情報を通信します。',
		KR: '우리는 데이터 프라이버시를 옹호하며애플리케이션이 기능을 수행하는 데 반드시 필요한 것만요구할 것임을 보 장합니다. 사용자가 개인 정보 보호 정책에 제공된 이용 약관에 동의하고 동의할 때까지 사용자로부터 어떠한 정보도 수집되지 않습니다. 계정을 등록하려면 사용자의 휴대폰 번호만있으면 됩니다. 휴대폰 번호는 OTP(일회성 비밀번호) 기능을 통해 확인 도구로 사용되며 해당 지방 정부 단위(LGU)의 연락처 정보는 건강, 마리아나 관광청, 북마리아나제도 세관 및 검역 신고 정보와 공유합니다.',
		CN: '我們提倡資料保護，並確保我們只需要應用程序執行其功能完全必要的內容。 在我們的用戶同意我們的隱私政策中提供的條款和條件並徵得他們的同意之前，我們不會從他們那裡收集任何訊息。 要註冊一個帳戶，我們只需要用戶提供他們的手機號碼。 手機號碼通過一次性密碼 功能和各自當地政府部門的聯繫信息用作驗證工具，以便與他們溝通您的健康、馬里亞納旅遊局、北馬里亞納群島聯邦海關和檢疫申報訊息。',
	},
	INFORMATION_WE_COLLECT_SECOND_PARAGRAPH: {
		EN: 'After registration, the system collects information required by the Marianas Visitors Authority and CNMI Customs Biosecurity for their respective declaration forms. The application generates a QR ID for these records, which can be scanned by authorized CNMI Government personnel at the border to facilitate seamless processing.',
		JP: '登録時にシステムは健康宣言に関してのみ健康および健康担当者がアクセスできるように個人の健康情報を収集します。同様に、CNMI 政府は、CNMI 税関検疫およびマリアナ政府観光局の申告情報のために個人情報を収集します。システムは許可されたCNMI 政府職員により国境でスキャンされるこれらの記録のQR ID を生成します。',
		KR: '등록 시 해당되는 경우 시스템은 건강 설문지와 관련된 건강 및 건강 관리 담당자가 액세스할 수 있는 개인 건강 정보를 수집합니다. 마찬가지로 북마리아나제도 정부는 북마리아나제도 세관 및 검역소 및 마리아나 관광청 신고 정보를 위해 개인 정보를 수집합니다. 시스템은 승인된 북마리아나제도 정부 직원이 국경에서 스캔할 수 있도록 이러한 기록에 대한 QR ID를 생성합니다.',
		CN: '註冊後，如果適用，系統將收集個人健康訊息，供健康和健康官員代表連結，僅與健康聲明有關。 同樣，北馬里亞納群島聯邦政府為北馬里亞納群島聯邦海關檢疫和馬里亞納旅遊局的申報信息收集個人信息。 系統會為這些記錄生成一個 QR ID，由授權的 北馬里亞納群島聯邦政府人員在邊境掃描。',
	},
	INFORMATION_WE_COLLECT_THIRD_PARAGRAPH: {
		EN: 'We take data privacy seriously and ensure that your information is handled with the utmost care and in compliance with relevant regulations. Your data will only be used for the purposes described above and accessed by authorized personnel as needed for the application’s functionality.',
		JP: 'パンデミック、伝染病、または新興疾患の間、システムはデジタル日誌を管理、提供され情報にサイトのデジタル日誌機能からアクセスすることを許可するかどうかを尋ねられます。サイトを使用すると、次の情報も取得される場合があります。',
		KR: '대유행, 전염병 및/또는 새로운 질병이 발생하는 동안 시스템에서 디지털 로그북을 관리할 수 있으며사이트 디지털 로그북 기능을 통해 제공한 정보에 액세스할 수 있는지 여부를 묻는 메시지가 표시됩니다. 사이트를 사용할 때 다음 정보도 얻을 수 있습니다.',
		CN: '在大流行、流行病和/或新發疾病期間，可以啟用系統來管理數位日誌，並且將詢問他們是否允許站點數字日誌功能訪問他們提供的訊息。 當您使用本網站時，還可能獲得以下訊息：',
	},
	INFORMATION_WE_COLLECT_FOURTH_PARAGRAPH: {
		EN: 'During a pandemic, epidemic, and or emerging disease, the system can be enabled to manage digital logbooks, and they will be asked if they allow the information they have provided to be accessed by the Site website/app digital logbook feature. When you use the Site, the following information may also be obtained:',
		JP: 'パンデミック、伝染病、または新興疾患の間、システムはデジタル日誌を管理、提供され情報にサイトのウェブサイト/アプリのデジタル日誌機能からアクセスすることを許可するかどうかを尋ねられます。サイトを使用すると、次の情報も取得される場合があります。',
		KR: '전염병 또는 새로운 질병이 발생하는 동안 시스템에서 디지털 로그인을 관리할 수 있으며 사이트 디지털 로그인 기능을 통해 제공된 정보에 액세스할 수 있는지 여부를 묻습니다. 사이트를 사용할 때 다음 정보도 얻을 수 있습니다.',
		CN: '在大流行、流行病和/或新發疾病期間，系統可以啟用管理數位日誌，並且將詢問他們是否允許站點網站/應用程序數字日誌功能訪問他們提供的訊息。 當您使用本網站時，還可能獲得以下訊息：',
	},
	INFORMATION_WE_COLLECT_FIRST_BULLET: {
		EN: 'Information contained in any communication or report that you submit to the Site, including metadata associated with such communication; and',
		JP: 'あなたが本サイトに送信する連絡またはレポートに含まれる情報(そのような連絡に関連するメタデータを含む)と；',
		KR: '해당 통신과 관련된 메 타데이터를 포함하여 귀하가 사이트에 제출하는 모든 통신 또는 보고서에 포함된 정보 그리고',
		CN: '您提交給本網站的任何通訊或報告中包含的訊息，包括與此類通信相關的元數據；和',
	},
	INFORMATION_WE_COLLECT_SECOND_BULLET: {
		EN: 'Information that you post to the Site or submit for publication on the internet, including your nickname/alias, and the content of your post/s App Permission Matrix Scan QR Code (Optional).',
		JP: 'ニックネーム/エイリアス、および投稿のコンテンツを含むサイトに投稿、インターネット上で公開するために送信された情報、およびアプリ許可マトリックススキャンQR コード(オプション)',
		KR: '닉네임, 별칭, 게시물 콘텐츠 앱 권한 매트릭스 스캔QR 코드를 포함하여 사이트에 게시하거나 인터넷 게시를 위해 제출하는 정보(선택 사항)',
		CN: '您發佈到網站或提交在網上發布的訊息，包括您的暱稱/別名，以及您發布的內容/應用權限矩陣掃描二維碼（可選）',
	},
	INFORMATION_WE_COLLECT_THIRD_BULLET: {
		EN: 'The app will only access your camera for QR code scanning and logbook purposes only. No pictures or videos will be saved.',
		JP: 'アプリは、QR コードのスキャンとログブックの目的でのみカメラにアクセスします。写真や動画は保存されません。',
		KR: '이 앱은 QR 코드 스  캔 및 로그북 용도로만 카메라에 액세스합니다. 사진이나 동영상이 저장되지 않습니다.',
		CN: '該應用程序僅出於二維碼掃描和日誌目的訪問您的相機。 不會保存圖片或視頻。',
	},
	CONFIDENTIALITY: {
		EN: 'CONFIDENTIALITY',
		JP: '守秘義務',
		KR: '비밀 보안',
		CN: '保密',
	},
	CONFIDENTIALITY_FIRST_PARAGRAPH: {
		EN: 'Information collected through the system is shared only with the CNMI Government Agency Designated Authorized Staff on a need-to-know basis. Personally identifiable information is never shared with outside agencies, such as immigration authorities or employers. The databases for the Marianas Visitors Authority (MVA) and CNMI Customs Biosecurity declaration modules are kept separate and are only accessible by their respective entities, ensuring a strict level of data confidentiality and security.',
		JP: 'システムのパンデミック緩和モジュールがパンデミック、流行、および/または新興疾患の際に有効になると、CNMI 政府機関の指定認定スタッフが感染者のプライバシーを保護するための措置を講じます。調査中、感染者と接触した可能性があることを通知しますが、感染者の名前は公開せず、最小限の情報しか伝えません。',
		KR: '시스템의 팬데믹 완화 모   듈이 팬데믹, 전염병 및 신  흥 질병 중에 활성화되면 CNMI 정부 기관 지정 승인 직원이 감염된 사람의 개인 정보를 보호하기 위한 조치를 취합니다. 조사 과정에서 감염자와 접촉했을 가 능성이 있다는 통보를 받게 되지만, 감염자의 이  름은 공개되지 않고 최소한의 정보만전달됩니다.',
		CN: '在大流行、流行病和/或新發疾病期間啟用系統的大流行緩解模塊時，北馬里亞納群島聯邦政府機構指定授權人員會採取措施保護感染者的隱私。在調查期間，將通知一個人他們可能與感染者有過接觸，但不會透露感染者的姓名，也只會傳達最少的訊息。',
	},
	CONFIDENTIALITY_SECOND_PARAGRAPH: {
		EN: 'Information collected is only shared with the CNMI Government Agency Designated Authorized Staff and the local authorized health officials and only on a need to know basis. Personally identifiable information is NEVER shared with outside agencies, such as immigration authorities or employers. The system databases for MVA and Customs declaration modules are separate from the health information database and accessible only to those entities respectively',
		JP: '収集された情報は、CNMI 政府機関の指定認定スタッフおよび地元の認定された保健当局とのみ知る必要がある場合にのみ共有されます。個人を特定できる情報は、入国管理局や雇用主などの外部機関と共有されることはありません。マリアナ政府観光局と税関申告モジュールのシステムデータベースは健康管理情報データベースと分離されており、それぞれの組織のみがアクセスできます。',
		KR: '수집된 정보는 북마리아나제도 정부 기관 지정 권한이 있는 직원 및 지역의 권한 있는 보건 공무원과 공유되며 필요한 경우에만 공유됩니다. 개인 식  별 정보는 이민 당국이나 고용주와 같은 외부 기관과 절대 공유되지 않습니다. MVA 및 세관 신고 모듈에 대한 시스템 데이터베이스는 개별적이며각각독립적으로 액세스할 수 있습니다.',
		CN: '收集的訊息僅在需要了解的情況下與北馬里亞納群島聯邦政府機構指定授權人員和當地授權衛生官員共享。個人身份訊息絕不會與移民當局或雇主等外部機構共享。馬里亞納觀光局和海關申報模塊的系統數據庫是分開的，只有這些實體可以分別連結。',
	},
	CHILDRENS_PRIVACY: {
		EN: "CHILDREN'S PRIVACY",
		JP: `子供のプライバシー'`,
		KR: '아동의 개인정보',
		CN: '兒童隱私',
	},
	CHILDRENS_PRIVACY_PARAGRAPH: {
		EN: 'The Site is not intended for children under thirteen (13) years of age. No one under age thirteen (13) may provide any information to or on the Site. We do not knowingly collect or solicit personal information from children below thirteen (13) years of age, and we do not knowingly allow such persons to use the Site. In the event that we learn that we collected personal information from children below thirteen (13) years old without verified parental consent, we will immediately delete the information with notice. If you believe that we might have collected such information, please contact us immediately through the contact information below.',
		JP: '本サイトは、13 歳未満の子供を対象としていません。13 歳未満の者は、本サイトまたは本サイト上で情報を提供することはできません。13 歳未満の子供から故意に個人情報を収集または要求することはありません。13 歳未満の子供から保護者の同意を確認せずに個人情報を収集したことが判明した場合、通知の上、直ちに情報を削除します。私達がそのような情報を収集した可能性があると思われる場合は、すぐに下記の連絡先までご連絡ください。',
		KR: '사이트는 13세 미 만의 어 린이를 대상으로 하지 않습니다. 13세 미 만인 사람은 사이트에 정보를 제공하거나 사이트에 정보를 제공할 수 없습니다. 우리는 13세 미 만의 어 린이로부터 고의로 개인 정보를 수집하거나 요청하지 않으며 그러한 사람이 SafeTravelsMarianas.com을 사용하는 것을 허용하지 않습니다. 확인된 부모의 동의 없이 만13세 미 만 아동의 개인정보를 수집한 사실을 알게 되는 경우, 해당 정보를 통지하고 즉시 삭제합니다. 당사가 그러한 정보를 수집했다고 생  각되시면 아래 연락처 정보를 통해 즉시 당사에 연락해 주십시오.',
		CN: '本網站不適用於十三(13) 歲以下的兒童。未滿十三(13) 歲的任何人不得向本網站或在本網站上提供任何訊息。我們不會故意收集或索取十三(13) 歲以下兒童的個人訊息，我們也不會故意允許這些人使用SafeTravelsMarianas.com。如果我們得知我們在未經父母核實同意的情況下收集了十三(13) 歲以下兒童的個人訊息，我們將立即刪除該信息並發出通知。如果您認為我們可能收集了此類信息，請立即通過以下聯繫信息與我們聯繫。',
	},
	COLLECT_YOUR_INFORMATION: {
		EN: 'WHY WE COLLECT YOUR INFORMATION',
		JP: 'あなたの情報を収集する理由',
		KR: '귀하의 정보를 수집하는 이유',
		CN: '為什麼我們收集您的資訊',
	},
	COLLECT_YOUR_INFORMATION_FIRST_PARAGRAPH: {
		EN: 'We collect your information to:',
		JP: '私たちはあなたの情報を次の目的で収集します：',
		KR: '다음을 위해 귀하의 정보를 수집합니다.',
		CN: '我們收集您的資訊以：',
	},
	COLLECT_YOUR_INFORMATION_FIRST_BULLET: {
		EN: 'Enable you to report your health condition and provide you with basic medical information and the recommended actions of the Government based on your condition.',
		JP: '健康状態を報告、基本的な医療情報と状態に従って政府が推奨する行動をお知らせするため。',
		KR: '귀하의 건강 상태를 보고하고 상태에 따라 기본 의료 정보와 정부의 권장 조치를 제공할 수 있습니다.',
		CN: '讓您回報您的健康狀況，並為您提供基本的醫療訊息和政府根據您的狀況建議採取的行動。',
	},
	COLLECT_YOUR_INFORMATION_SECOND_BULLET: {
		EN: 'Provide you with information on emergency contact details, medical services, distribution of relief goods and other forms of government assistance, and Government alerts and announcements.',
		JP: '緊急連絡先の詳細、医療サービス、救援物資の配布およびその他の形態の政府支援、ならびに政府の警報および発表に関する情報を提供します。',
		KR: '긴급 연락처 정보, 의료 서비스, 구호품 배포 및 기타 형태의 정부 지원, 정부 경보 및 발표에 대한 정보를 제공합니다.',
		CN: '為您提供有關緊急聯繫方式、醫療服務、救濟物資分發和其他形式的政府援助以及政府警報和公告的資訊。',
	},
	COLLECT_YOUR_INFORMATION_THIRD_BULLET: {
		EN: 'Your mobile number is collected to prevent fraud through a One-Time-Password (OTP) and for cybersecurity purposes.',
		JP: '携帯電話番号は、ワンタイムパスワード(OTP) による詐欺を防止、またサイバーセキュリティの目的で収集されます。',
		KR: '귀하의 휴대폰 번호는 OTP(One-Time-Password)를 통한 사기 방지 및 사이버 보안 목적으로 수집됩니다.',
		CN: '收集您的手機號碼是為了防止通過一次性密碼 (OTP) 進行欺詐 並出於網  絡安全目的。',
	},
	COLLECT_YOUR_INFORMATION_FOURTH_BULLET: {
		EN: 'Send you SMS and in-app notifications related to the Site.',
		JP: '本サイトに関連するSMS およびアプリ内通知を送信するため。',
		KR: '마리아나 여행과 관련된 SMS 및 인앱 알림을 보냅니다.',
		CN: '向您發送與Travel Marianas 相關的簡訊和內建應用程式通知。',
	},
	COLLECT_YOUR_INFORMATION_FIFTH_BULLET: {
		EN: 'Ensure that the Site works as it should and you are able to browse the Site safely and securely.',
		JP: '本サイトが正常に機能し、安全かつ確実にサイトを閲覧できるようにするため。',
		KR: 'Travels Marianas가 정상적으로 작동하고 사이트를 안전하고 안전하게 탐색할 수 있는지 확인하십시오.',
		CN: '確保Travels Marianas 網站正常運行，並且您能夠安全可靠地瀏覽本網站。',
	},
	COLLECT_YOUR_INFORMATION_SECOND_PARAGRAPH: {
		EN: 'We also collect your information to track bugs, errors, and usage statistics. In case of any security incident or data breach, we may also use your information in our investigative reporting.',
		JP: 'また、バグ、エラーおよび使用統計を追跡するためにお客様の情報を収集します。セキュリティ問題またはデータ侵害が発生した場合、調査レポートのためにお客様の情報を使用することもあります。',
		KR: '또한 버그, 오류 및 사용 통계를 추적하기 위해 귀하의 정보를 수집합니다. 보안 사고 또는 데이터 침해가 발생한 경우 조사 보고에 귀하의 정보를 사용할 수도 있습니다.',
		CN: '我們還收集您的資訊以追踪錯誤和使用統計訊信。如果發生任何安全事件或數據洩露，我們也可能在調查報告中使用您的資訊。',
	},
	USE_SHARE_RETAIN_INFORMATION: {
		EN: 'HOW WE USE, SHARE, AND RETAIN YOUR INFORMATION',
		JP: 'あなたの情報の使用、共有、保持方法について',
		KR: '귀하의 정보를 사용, 공유 및 유지하는 방법',
		CN: '我們如何使用、共享和保留您的資訊',
	},
	USE_SHARE_RETAIN_INFORMATION_USE: {
		EN: 'Use',
		JP: '使用',
		KR: '사용',
		CN: '使用',
	},
	USE_SHARE_RETAIN_INFORMATION_FIRST_PARAGRAPH: {
		EN: 'When we can, we submit collected information in an aggregated manner to ensure Users’ privacy. We use your information to compile reports for the CNMI Government to inform its policies and actions in response to border protection. The information provided will be used to deliver and provide the following actions:',
		JP: '可能な限りユーザーのプライバシー保護のため、収集した情報を集約して送信します。私たちはあなたの情報を使用して、CNMI政府が国境保護に対応する政策と行動を知らせるためのレポートを作成します。提供された情報は、次のアクションを実行および提供するために使用されます。',
		KR: '가능한 경우 사용자의 개인 정보를 보호하기 위해 수집된 정보를 집계 방식으로 제출합니다. 우리는 CNMI 정부가 국경 보호에 대응하여 정책과 조치를 알리기 위해 보고서를 작성하는 데 귀하의 정보를 사용합니다. 제공된 정보는 다음 조치를 제공하고 제공하는 데 사용됩니다.',
		CN: '在可能的情況下，我們會以匯總的方式提交收集到的信息，以確保用戶的隱私。我們使用您的信息為北馬里亞納群島聯邦政府編制報告，以告知其響應邊境保護的政策和行動。所提供的信息將用於交付和提供以下行動：',
	},
	USE_SHARE_RETAIN_INFORMATION_FIRST_TWO_PARAGRAPH: {
		EN: 'Also, by registering and having access to the Site, you agree to only use it for the following purpose:',
		JP: 'また、サイトに登録してアクセスすることにより、次の目的でのみ使用することに同意したことになります。',
		KR: '또한 사이트에 등록하고 액세스하면 다음 목적으로 사용하는 데 동의하는 것입니다.',
		CN: '此外，通過註冊和連結本網站，您同意僅將其用於以下目的：',
	},
	USE_SHARE_RETAIN_INFORMATION_TWO_FIRST_BULLET: {
		EN: 'Submitting forms such as Customs Biosecurity Declaration and MVA Surveys for the trip.',
		JP: '旅行の健康申告書、証明書、税関申告書、マリアナ政府観光局調査等のフォームの提出。',
		KR: '여행을 위한 건강 신고서, 증명서, 세관 신고서 및 MVA 조사와 같은 양식을 제출합니다.',
		CN: '提交旅行健康聲明、證明、海關申報書和馬里亞納觀光局調查等表格。',
	},
	USE_SHARE_RETAIN_INFORMATION_TWO_SECOND_BULLET: {
		EN: 'Uploading of vaccination records, RT-PCR and Antigen.',
		JP: 'ワクチン接種記録、RT-PCR、抗原のアップロード。',
		KR: '백신 예방 접종 기록, RT-PCR 및 항원 업로드.',
		CN: '上傳疫苗接種記錄、RT-PCR 和抗原檢測。',
	},
	USE_SHARE_RETAIN_INFORMATION_TWO_THIRD_BULLET: {
		EN: 'Printing of QR ID.',
		JP: 'QR IDの印刷。',
		KR: 'QR ID 인쇄.',
		CN: '列印QR ID',
	},
	USE_SHARE_RETAIN_INFORMATION_TWO_FOURTH_BULLET: {
		EN: 'Downloading and Printing of Vaccination QR ID.',
		JP: '予防接種QR IDのダウンロードと印刷。',
		KR: '백신 예방접종 QR ID 다운로드 및 인쇄',
		CN: '下載和列印疫苗接種QR ID。',
	},
	USE_SHARE_RETAIN_INFORMATION_TWO_FIFTH_BULLET: {
		EN: 'Downloading and Printing of Vaccination Certificates.',
		JP: '予防接種証明書のダウンロードと印刷。',
		KR: '백신예방 접종 증명서 다운로드 및 인쇄.',
		CN: '下載和列印疫苗接種證明。',
	},
	USE_SHARE_RETAIN_INFORMATION_TWO_SIXTH_BULLET: {
		EN: 'Viewing of Traveler’s Trip History.',
		JP: '旅行者の旅行履歴の閲覧。',
		KR: '여행자의 여행 기록 보기.',
		CN: '查看旅遊者的旅行紀錄。',
	},
	USE_SHARE_RETAIN_INFORMATION_TWO_SEVENTH_BULLET: {
		EN: 'Updating of Profile',
		JP: 'プロフィール、健康診断、医療記録の更新。',
		KR: '프로필, 건강 검  진 및 의료 기록 업데이트.',
		CN: '更新個人資料、健康檢查和醫療紀錄。',
	},

	USE_SHARE_RETAIN_INFORMATION_SECOND_PARAGRAPH: {
		EN: 'Health Declaration during Pandemic, Epidemic, and Emerging Diseases Module',
		JP: 'パンデミック、エピデミック、および新興疾患に関する健康宣言モジュール',
		KR: '팬데믹, 전염병 및 신  흥 질병에 대한 건강 신고.',
		CN: '大流行病、流行病和新發疾病時健康聲明模組',
	},
	USE_SHARE_RETAIN_INFORMATION_SECOND_TWO_PARAGRAPH: {
		EN: 'Manual Contact Tracing - upon the local government unit (“LGU”) designating a CNMI Government Agency Designated Authorized Staff, we use the information from the the Site for the following:',
		JP: '手動接触者追跡- CNMI 政府機関の指定認定スタッフを指定する地方自治体単位(「LGU」) の場合、当社はサイトからの情報を次の目的で使用します。',
		KR: '수동 접  촉추적 - 지방 정부 기관("LGU")이 CNMI 정부 기관 지정 승인 직원을 지정하면 다음을 위해 사이트의 정보를 사용합니다.',
		CN: '手動接觸者追踪- 在指定北馬里亞納群島聯邦政府機構指定授權人員的當地政府部門(“LGU”) 後，我們將來自網站的資訊用於以下用途：',
	},
	USE_SHARE_RETAIN_INFORMATION_CONTACT_TRACING_FIRST_BULLET: {
		EN: 'maintaining and updating a master list of citizens assigned to be contact traced;',
		JP: '接触追跡が割り当てられた市民のマスターリスト維持および更新。',
		KR: '접촉추적에 할당된 시민의 마스터 목록을 유지하고 업데이트합니다.',
		CN: '維護和更新被分配追踪接觸者的公民總清單；',
	},
	USE_SHARE_RETAIN_INFORMATION_CONTACT_TRACING_SECOND_BULLET: {
		EN: 'locating close contacts, profile, assess, and manage them through the assistant of Covid task force response team, local police officers, and fire department staff;',
		JP: 'COVIDタスクフォース対応チーム、地元警察および消防スタッフの協力を得て、濃厚接触者の特定、プロファイリング、評価、および管理を行なう。',
		KR: 'COVID 대책 대  응팀, 지역 경  찰 및 소방서 직원을 통해 밀접 접 촉자를 찾고 프로필을 작성하고 평가하고 관리합니다.',
		CN: '通過Covid 工作組響應小組的助理、當地警察和消防部門工作人員查找密切接觸者，對其進行描述、評估和管理；',
	},
	USE_SHARE_RETAIN_INFORMATION_CONTACT_TRACING_THIRD_BULLET: {
		EN: 'profiling, assessing symptoms, and managing close contacts through health and allied health workers within the CNMI Government Agency Designated Authorized Staff, assisted by staff from local population office and disaster risk reduction management offices, and',
		JP: 'プロファイリング、症状の評価、および CNMI 政府機関指定認定スタッフ内の医療従事者および関連医療従事者による濃厚接触者の管理、および地元の人口事務所および災害リスク軽減管理事務所のスタッフによる支援、および',
		KR: 'CNMI 정부 기관 지정 공인 직원인 보건 및 관련 의료 종사자를 통한 프로파일링, 증상 평가 및 긴   밀한 연락 관리를 위해 지역 인구 사무소 및 재해 위험 감소 관리 사무소의 직원의 도움을 받습니다',
		CN: '在當地人口辦公室和減少災害風險管理辦公室的工作人員的協助下，通過 北馬里亞納群島聯邦政府機構指定授權工作人員內的衛生和專職衛生人員對密切接觸者進行分析、評估症狀和管理密切接觸者，以及在當地人口辦公室和減少災害風險管理辦公室的工作人員的協助下，通過北馬里亞納群島聯邦政府機構指定授權工作人員內的衛生和專職衛生人員對密切接觸者進行分析、評估症狀和管理密切接觸者，以及',
	},
	USE_SHARE_RETAIN_INFORMATION_CONTACT_TRACING_FOURTH_BULLET: {
		EN: 'ensuring safety, welfare of CNMI Government Agency Designated Authorized Staff and close contacts through the local police officers and members of law enforcement and fire department.',
		JP: 'CNMI 政府機関の指定認定スタッフの安全と福利を確保し、地元警察と法執行機関と消防署のメンバーを通じて密接な連絡先を確保。',
		KR: '북마리아나제도 정부 기관, 지정 승인 직원 및 현지 경 찰과 법  집행 기관 및 소방서를 통한 긴밀한 연락을 통해 안전을 확인받습니다.',
		CN: '通過當地警察和執法和消防部門成員確保北馬里亞納群島聯邦政府機構指定授權人員和密切接觸者的安全和福利',
	},
	USE_SHARE_RETAIN_INFORMATION_THIRD_PARAGRAPH: {
		EN: 'Upon the LGU designating a CNMI Government Agency Designated Authorized Staff member, we use the information from the Site for the following:',
		JP: 'LGU がCNMI 政府機関の指定認定スタッフメンバーを指定すると、私たちはサイトからの情報を次の目的で使用します',
		KR: 'LGU가 북마리아나제도 정부 기관 지정 승인 직원을 지정하면 당사는 사이트의 정보를 다음 용도로 사용합니다.',
		CN: '在當地政府部門(LGU)指定北馬里亞群島聯邦政府機構指定授權工作人員後，我們將來自網站的資訊用於以下用途：',
	},
	USE_SHARE_RETAIN_INFORMATION_FIRST_BULLET: {
		EN: 'maintaining and updating a master list of citizens;',
		JP: '市民のマスターリストの維持および更新。',
		KR: '주민 정보 목록 유지 및 업데이트',
		CN: '維護和更新公民總清單；',
	},
	USE_SHARE_RETAIN_INFORMATION_SECOND_BULLET: {
		EN: 'locating close contacts, profile, assess, and manage them through the assistant of CNMI Government Agency Designated Authorized Staff, local police officers, and Bureau of Fire Protection (BFP) staff;',
		JP: 'CNMI 政府機関の指定認定スタッフ、地元警察、防火局(BFP) スタッフの協力を得て、濃厚接触者の特定、プロファイリング、評価、および管理を行う。',
		KR: '북마리아나제도 정부 기관 지정 승인 직원, 지역 경  찰, 소방국(BFP) 직원의 보조원 통해 밀접 접촉자를 찾고, 프로필을 작성하고, 평가하고, 관리합니다.',
		CN: '通過北馬里亞納群島聯邦政府機構指定授權人員、當地警察和消防局(BFP) 工作 人員的助 理查找密切接觸者，對其進行描述、評估和管理；',
	},
	USE_SHARE_RETAIN_INFORMATION_THIRD_BULLET: {
		EN: 'profiling, assessing symptoms, and managing close contacts through health and allied health workers within the CNMI Government Agency Designated Authorized Staff, assisted by staff from local population office and disaster risk reduction management offices, and',
		JP: 'プロファイリング、症状の評価、および CNMI 政府機関指定認定スタッフ内の医療従事者および関連医療従事者による濃厚接触者の管理、および地元の人口事務所および災害リスク軽減管理事務所のスタッフによる支援、および',
		KR: '북마리아나제도 정부 기관인 지역 인구 사무소 및 재난 위험 감소 관리 사무소의 지정된 보건 및 관련의료 종사자를 통해 프로파일링, 증상 평가 및 긴   밀한 접 촉 관리를 합니다. 그리고',
		CN: '在當地人口辦公室和減少災害風險管理辦公室的工作人員的協助下，通過北馬里亞納群島聯邦政府機構指定授權工作人員內的衛生和專職衛生人員對密切接觸者進行分析、評估症狀和管理密切接觸者，以及',
	},
	USE_SHARE_RETAIN_INFORMATION_FOURTH_BULLET: {
		EN: 'ensuring safety, welfare of the CNMI Government Agency Designated Authorized Staff and close contacts through the local police officers and members of law enforcement and fire department.',
		JP: 'CNMI 政府機関の指定認定スタッフの安全と福利を確保し、地元警察と法執行機関と消防署のメンバーを通じて緊密な連絡を取り合う。',
		KR: '북마리아나제도 정부 기관, 지정 승인 직원 및 현지 경 찰과 법  집행 기관 및 소방서를 통한 긴밀한 연락을 통해 안전을 확인받습니다.',
		CN: '通過當地警察和執法和消防部門的成員，確保北馬里亞納群島聯邦政府機構指定授權人員和密切接觸者的安全與福利。',
	},
	USE_SHARE_RETAIN_INFORMATION_FOURTH_PARAGRAPH: {
		EN: 'Marianas Visitors Authority (“MVA”) and CNMI Customs Biosecurity (“Customs”) Declaration Forms',
		JP: 'マリアナ政府観光局およびCNMI 税関および検疫申告書',
		KR: '마리아나 관광청 및 북마리아나제도 세관 및 검역 신고서',
		CN: '馬里亞納觀光局和北馬里亞納群島聯邦海關和檢疫申報表',
	},
	USE_SHARE_RETAIN_INFORMATION_FIFTH_PARAGRAPH: {
		EN: 'We use your information to maintain and improve the Site. Only authorized personnel of the Site have access to your information on how you use the Site. The third-party provider of our data centers does not have access to information that will enable your identification',
		JP: 'あなたの情報は、本サイトを維持および改善するために使用されます。本サイトの許可された担当者のみが、本サイトの使用方法に関する情報にアクセスできます。データセンターの外部プロバイダーは、お客様の識別を可能にする情報にアクセス出来ません。',
		KR: '당사는 귀하의 정보를 사용하여 사이트를 유지 및 개선합니다. 사이트의 인증된 담당자만 사이트 사용 방법에 대한 사용자의 정보에 액세스할 수 있습니다. 데이터 센터의 타사 공급자는 사용자의 신원을 확인할 수 있는 정보에 액세스할 수 없습니다.',
		CN: '我們使用您的信息來維護和改進www.safetravelsmarianas.com。只有www.safetravelsmarianas.com 的授權人員才能連結有關您如何使用www.safetravelsmarianas.com 的資訊。我們數據中心的第三方提供商無權連結可識別您身份的訊息。',
	},
	RETENTION: {
		EN: 'Retention',
		JP: '保持',
		KR: '보관',
		CN: '保留',
	},
	RETENTION_FIRST_PARAGRAPH: {
		EN: 'Borders Protection data shall be retained within 60 days to cover end-to-end processes.',
		JP: '国境保護データは、エンドツーエンドのプロセスをカバーするため60 日以内保持されるものとします。',
		KR: '국경 보호국 데이터는 종단 간 프로세스를 포함하기 위해 60일 이 내에 보관되어야 합니다.',
		CN: '邊境保護數據應在60 天內被保留下來，以涵蓋端對端流程。',
	},
	RETENTION_SECOND_PARAGRAPH: {
		EN: 'Digital logbook data is handled with the highest degree of confidentiality and shall and will only be retained in the backend for 30 days.',
		JP: 'デジタル日誌データは最高度の機密性で処理され、バックエンドに30 日間保持されます。',
		KR: '디지털 로그북 데이터는 최고 수준의 기밀로 처리되며30일 동안 만 백엔드에 보관됩니다.',
		CN: '數位日誌數據以最高程度的機密性處理，並將僅在後端保留30 天。',
	},
	RETENTION_THIRD_PARAGRAPH: {
		EN: 'Users may request to have their data deleted at any given time throughout the retention period by contacting us; refer to the link provided below.',
		JP: 'ユーザーは、私たちに連絡し、データ保持期間中いつでもデータの削除を要求できます。以下のリンクを参照してください。',
		KR: '사용자는 당사에 연락하여 보유 기간 동안 언제든지 데이터 삭제를 요청할 수 있습니다. 아래 제공된 링크를 참조하십시오.',
		CN: '用戶可以在整個保留期內的任何給定時間通過聯繫我們要求刪除他們的數據資料；請參閱下面提供的連結。',
	},
	RETENTION_FOURTH_PARAGRAPH: {
		EN: 'MVA & Customs data shall be retained indefinitely.',
		JP: 'マリアナ政府観光局と税関のデータは無期限に保持されます。',
		KR: 'MVA 및 관세 데이터는 무기한 보관됩니다',
		CN: '馬里亞納觀光局和海關數據會無限期保留',
	},
	RETENTION_FIFTH_PARAGRAPH: {
		EN: 'Health Declaration data shall be retained indefinitely. At the discretion of the traveler, their health information may be deleted from the system; see deactivate and disable account section.',
		JP: '健康宣言データは無期限に保持されます。旅行者の意志により、健康情報がシステムから削除される場合があります。アカウントの無効化と無効化セクションを参照してください。',
		KR: '건강 설문지 데이터는 무기한 보관됩니다. 여행자의 재량에 따라 건강 정보가 시스템에서 삭제될 수 있습니다. 계정 비활성화 및 비활성화 섹션을 참조하십시오.',
		CN: '健康聲明數據會無限期保留。根據旅行者的判斷，他們的健康資訊可能會從系統中刪除；請參閱停用和禁用帳戶部分。',
	},
	RETENTION_FIRST_BULLET: {
		EN: 'Manual Contact Tracing data shall be retained within 60 days to cover end-to-end processes for contact tracing for first, second, and third generation of close contacts, most especially for contact tracing processes in areas with community transmission.',
		JP: '手動接触者追跡データは、特に市中感染が発生している地域での接触者追跡プロセスを中心に、第1、第2、および第3濃厚接触者の接触者追跡のエンドツーエンドプロセスのために60 日以内に保持されるものとします。',
		KR: '수동 접  촉추적 데이터는 1, 2, 3세대  밀접 접 촉자의 접  촉추적을 위한 종단 간 프로세스, 특히지역사회 전염이 있는 지역의 접촉추적 프로세스를 포괄하기 위해 60일 이 내에 보관해야 합니다.',
		CN: '手動接觸者追踪數據應在 60 天內保留，以涵蓋第一代、第二代和第三代密切接觸者的接觸者追踪的端到端流程，尤其是社區傳播地區的接觸者追踪流程。',
	},
	RETENTION_SECOND_BULLET: {
		EN: 'Digital logbook data is handled with the highest degree of confidentiality and shall and will only be retained in the backend for 30 days.',
		JP: 'デジタル日誌データは最高度の機密性で処理され、バックエンドに 30 日間のみ保持されます。',
		KR: '디지털 로그북 데이터는 최고 수준의 기밀로 처리되며30일 동안 만 백엔드에 보관됩니다.',
		CN: '數位日誌數據以最高程度的機密性處理，並將僅在後端保留30 天。',
	},
	RETENTION_THIRD_BULLET: {
		EN: 'Users may request to have their data deleted at any given time throughout the retention period by contacting us; refer to the link provided below.',
		JP: 'ユーザーは、当社に連絡することにより、保持期間中いつでもデータの削除を要求できます。 以下のリンクを参照してください。',
		KR: '사용자는 당사에 연락하여 보유 기간 동안 언제든지 데이터 삭제를 요청할 수 있습니다. 아래 제공된 링크를 참조하십시오.',
		CN: '用戶可以在整個保留期內的任何時間通過聯繫我們要求刪除他們的數據； 請參閱下面提供的連結。',
	},
	RETENTION_FOURTH_BULLET: {
		EN: 'MVA & Customs data  shall be retained indefinitely.',
		JP: 'マリアナ政府観光局と税関のデータは無期限に保持されます。',
		KR: 'MVA 및 관세 데이터는 무기한 보관됩니다.',
		CN: '馬里亞納觀光局和海關數據將無限期保留。',
	},
	RETENTION_FIFTH_BULLET: {
		EN: 'Health Declaration data shall be retained indefinitely. At the discretion of the traveler, their health information may be deleted from the system; see deactivate and disable account section.',
		JP: '健康宣言データは無期限に保持されます。 旅行者の裁量により、健康情報がシステムから削除される場合があります。 アカウントの無効化と無効化セクションを参照してください。',
		KR: '건강 선  언 데이터는 무기한 보관됩니다. 여행자의 재량에 따라 건강 정보가 시스템에서 삭제될 수 있습니다. 계정 비활성화 및 비활성화 섹션을 참조하십시오.',
		CN: '健康聲明資料將無限期保留。 旅客可自行決定從系統中刪除其健康資訊； 請參閱停用和禁用帳戶部分。',
	},
	DESTRUCTION: {
		EN: 'Destruction',
		JP: '破壊',
		KR: '파기',
		CN: '破壞',
	},
	DESTRUCTION_FIRST_PARAGRAPH: {
		EN: 'The use of contact tracing data in the Site shall conform to existing and applicable rules as stipulated. Following are guidelines on destruction of data in the Site:',
		JP: 'サイトでの接触者追跡データの使用は、規定されている既存の適用可能な規則に従います。以下はサイトのウェブサイト/アプリでのデータの破棄に関するガイドラインです。',
		KR: '현장에서의 접촉추적 데이터의 사용은 규정된 기존 및 적용 가능한 규칙을 준수해야 한다. 다음은 사이트의 데이터 파기 지침입니다.',
		CN: '本網站中資料的使用應符合規定的現有和適用規則。以下是有關在網站/應用程序中刪除資訊的指南：',
	},
	DESTRUCTION_FIRST_BULLET: {
		EN: 'Users can completely delete their registered data within the platform.',
		JP: 'ユーザーは、プラットフォーム内の登録データを完全に削除できます。手動接触者追跡データは60 日後に破棄されます。',
		KR: '사용자는 플랫폼 내에서 등록된 데이터를 완전히 삭제할 수 있습니다. 수동 접  촉추적 데이터는 60일 후에 파기됩니다.',
		CN: '用戶可以完全刪除自己在平台內的註冊資料。手動聯繫追踪資訊應在60 天後銷毀。',
	},
	DESTRUCTION_SECOND_BULLET: {
		EN: 'All other processing of information which will be required.',
		JP: '必要となるその他のすべての情報の処理。デジタル接触者追跡データは、30 日後、または民間企業が要求した時点で破棄されます。',
		KR: '기타 필요한 정보 처리. Digital Borders Protection 데이터는 30일 후 또는 사설 시설에서 요구하는 경우 언제든지 파기됩니다.',
		CN: '所有其他需要的訊息處理。 數位聯繫追踪資料應在 30 天後或私營機構要求的任何時間銷毀。',
	},
	DESTRUCTION_THIRD_BULLET: {
		EN: 'Should you wish to have your Personal Information deleted and destroyed or you wish to withdraw your consent in the processing of your personal information or delete/deactivate your account, you may do so by clicking the link below.',
		JP: '個人情報の削除および破棄を希望する場合、または個人情報の処理に対する同意の撤回、またはアカウントの削除/無効化を希望する場合は、下記のリンクをクリックしてください。',
		KR: '귀하의 개인정보가 삭제 및 파기되기를 원하거나 개인정보 처리에 대한 동의를 철회하 거나 계정을 삭제/비활성화하려면 계정 삭제 또는 비활성화 버튼을 클릭하면 됩니다.',
		CN: '如果您希望刪除和銷毀您的個人資料，或者您希望在處理您的個人資料時撤回您的同意或刪除/停用您的帳戶，您可以點擊以下連結進行操作。',
	},
	DESTRUCTION_SECOND_PARAGRAPH: {
		EN: 'Should you wish to have your Personal Information deleted and destroyed or you wish to withdraw your consent in the processing of your personal information or delete/deactivate your account, you may do so by clicking the link below.',
		JP: '個人情報の削除および破棄を希望する場合、または個人情報の処理に対する同意の撤回またはアカウントの削除/無効化を希望する場合は、以下のリンクをクリックしてください。',
		KR: '귀하의 개인정보가 삭제 및 파기되기를 원하거나 개인정보 처리에 대한 동의를 철회하 거나 계정을 삭제/비활성화하려면 아래링크를 클릭하면 됩니다',
		CN: '如果您希望刪除和銷毀您的個人資料，或者您希望在處理您的個人資料時撤回您的同意或刪除/停用您的帳戶，您可以點擊以下連結進行操作。',
	},
	COOKIES: {
		EN: 'Cookies',
		JP: 'クッキー',
		KR: `쿠키'`,
		CN: '瀏覽',
	},
	COOKIES_PARAGRAPH: {
		EN: 'Cookies are small files that we use to make your use of the Site better. We store cookies on your device that are necessary for the operation of this website, to learn your preferences, and understand how you interact with the Site. For all other types of cookies, we need your permission.',
		JP: 'クッキーは、サイトをより快適に利用するために使用する小さなファイルです。このウエブサイトの操作に必要なクッキーをデバイスに保存し、ユーザーの嗜好を判断、サイトとのやり取りを理解します。他のすべての種類のクッキーについては、あなたの許可を求めます。',
		KR: '쿠키는 귀하의 사이트 사용을 개선하기 위해 사용하는 작은 파일입니다. 당사는 이 웹사이트의 운영에 필요한 쿠키를 귀하의 장치에 저  장하여 귀하의 선호도를 파악하고 귀하가 사이트와 상호 작용하는 방식을 이해합니다. 다른 모든 유   형의 쿠키에 대해서는 귀하의 허가가 필요합니다.',
		CN: 'Cookies是我們用來讓您更好地使用本網站的小文件。我們在您的設備上存儲運行本網站所必需的Cookies以了解您的  偏好並了解您如何與本網站互動。對於所有其他類型的Cookies，我們需要您的許可。',
	},
	SECURE_YOUR_INFORMATION: {
		EN: 'HOW WE SECURE YOUR INFORMATION',
		JP: 'あなたの情報を保護する方法',
		KR: '귀하의 정보를 보호하는 방법',
		CN: '我們如何保護您的資料',
	},
	SECURE_YOUR_INFORMATION_PARAGRAPH: {
		EN: 'We implement reasonable and appropriate physical, technical, and organizational measures to prevent the loss, destruction, misuse, or alteration of your information. While there is no guarantee that information sent over the internet is fully secured, we keep and protect your information using a secured server behind a firewall, encryption, and security controls on servers in the United States of America.',
		JP: '私達は合理的かつ適切な物理的、技術的、および組織的な措置を講じて、あなたの情報の紛失、破壊、誤用または改ざんを防止します。インターネット経由で送信される情報が完全に安全であるという保証はありませんが、米国内のサーバーでファイアウォール、暗号化、およびセキュリティ制御の背後にある安全なサーバーを使用して、情報を保持および保護します。',
		KR: '우리는 귀하의 정보의 손실, 파괴, 오용 또는 변경을 방지하기 위해 합리적이고 적절한 물리적, 기술적, 조직적 조치를 시행합니다. 인터넷을 통해 전송된 정보가 완전히 안전하다는 보장은 없지만 방화벽뒤에 있는 보안 서버, 암호화 및 미국 서버의 보안 제어를 사용하여 귀하의 정보를 유지하고 보호합니다.',
		CN: '我們實施合理和適當的物理、技術和組織措施，以防止您的資料遺失、破壞、濫用或更改。雖然不能保證通過網路發送的資訊是完全安全的，但我們使用位於美國的伺服器上的防火牆、加密和安全控制後端的安全服務器來保存和保護您的資料。',
	},
	// Emergency Hotline
	EMERGENCY_HOTLINE: {
		EN: 'Emergency Hotline',
		JP: '緊急ホットライン',
		KR: '긴급 핫라인',
		CN: '緊急熱線',
	},

	OTHER_HOTLINES: {
		EN: 'Other Hotlines',
		JP: 'その他のホットライン',
		KR: '기타 핫라인',
		CN: '其他热线',
	},
	CNMI_PORTS: {
		EN: 'CNMI Ports',
		JP: 'CNMIポート',
		KR: 'CNMI 포트',
		CN: '北马里亚纳群岛港口',
	},
	CNMI_CUSTOMS_BIOSECURITY: {
		EN: 'CNMI Customs Biosecurity',
		JP: 'CNMI税関バイオセキュリティ',
		KR: 'CNMI 세관 차단방역',
		CN: '北马里亚纳群岛海关生物安全',
	},
	EMERGENCY_HOTLINE_FIRST_PARAGRAPH: {
		EN: "The CHCC hospital is open 24/7 and may be reached at (670)234-8950. If you are experiencing a medical emergency or believe someone's life is in immediate danger, please call 911.",
		JP: 'CHCC 病院は24 時間365 日営業しており、電話番号は(670)234-8950 です。医療上の緊急事態が発生した場合、または誰かの生命が差し迫った危険にさらされていると思われる場合は、911 に電話ください。',
		KR: 'CHCC 병원은 연중무휴로 운영되며(670)234-8950으로 연락할 수 있습니다. 의료 응급 상황을 겪고 있 거나 누군가의 생명이 즉각적인 위험에 처해 있다고 생각되면 911에 전화하십시오.',
		CN: '塞班島上的CHCC 醫院24小時開放，可撥打(670)234-8950聯繫。如果您遇到急需醫療緊急情況或認為某人的生命處於立即危險之中，請撥打911。',
	},
	EMERGENCY_HOTLINE_SECOND_PARAGRAPH: {
		EN: 'If you are experiencing symptoms of COVID-19 (e.g., fever, cough, shortness of breath or difficulty breathing, fatigue, new loss of taste or smell, etc.) see your provider or call the CHCC Tele-Triage hotline at (670) 233-2067. The hotline runs 24 hours a day, 7 days a week.',
		JP: 'COVID-19 の症状(発熱、咳、息切れまたは呼吸困難、疲労、新たな味覚や嗅覚の喪失など) がある場合は、医療提供者に相談するか、CHCC テレトリアージホットライン(670)  233-2067に電話してください。ホットラインは、1 日24 時間、週7 日稼働しています。',
		KR: 'COVID-19의 증상(예: 발열, 기침, 숨가쁨 또는 호   흡곤란, 피로, 새로운 미각 또는 후   각 상실 등)을 경험하는 경우 도움을 요청 하거나 CHCC Tele-Triage 핫라인(670)233-2067.에 전화하십시오. 핫라인은 주 7일, 하루 24시간 운영됩니다.',
		CN: '如果您出現COVID-19 的症狀（例如發燒、咳嗽、呼吸急促或呼吸困難、疲勞、新出現的味覺或嗅覺喪失等），請諮詢您的醫療服務人員或撥打CHCC 電話分診熱線 (670) 233-2067。 該熱線每週7 天、每天24 小時開通 。',
	},
	// Support
	LETS_TALK: {
		EN: 'Lets Talk',
		JP: 'レッツトーク',
		KR: '문의',
		CN: '讓我們談談',
	},
	LETS_TALK_PARAGRAPH: {
		EN: 'Get in touch with us directly or fill out the contact form and we will get back to you promptly.',
		JP: '直接ご連絡いただくか、お問い合わせフォームにご記入ください。すぐにご連絡いたします。',
		KR: '당사에 직접 연락하거나 문의 양식을 작성해 주시면 신속하게 연락드리겠습니다.',
		CN: '直接與我們聯繫或填寫聯絡表格，我們會及時回覆您。',
	},
	BACK_TO_HOME: {
		EN: 'Back to Home',
		JP: '家に帰る',
		KR: '홈으로',
		CN: '回到首页',
	},
	BACK_TO_HOME_PAGE: {
		EN: 'Go Back to home page.',
		JP: 'ホームページに戻る',
		KR: '홈페이지로 돌아가기',
		CN: '返回主页。',
	},
	YOUR_NAME: {
		EN: 'Your Name',
		JP: 'あなたの名前',
		KR: '당신의 이름',
		CN: '你的名字',
	},
	YOUR_EMAIL: {
		EN: 'Your Email',
		JP: 'あなたの電子メール',
		KR: '귀하의 이메일',
		CN: '你的邮件',
	},
	MESSAGE: {
		EN: 'Message',
		JP: 'メッセージ',
		KR: '메시지',
		CN: '信息',
	},
	SEND_MESSAGE: {
		EN: 'Send Message',
		JP: 'メッセージを送る',
		KR: '문자 보내',
		CN: '发信息',
	},
	MENU: {
		EN: 'Menu',
		JP: 'メニュー',
		KR: '메뉴',
		CN: '菜单',
	},
	NO_ACTIVE_TRIP_MESSAGE: {
		EN: 'You have no existing trip. Create a trip and get verified',
		JP: '既存の旅行はありません。旅行を作成して認証を受ける',
		KR: '기존 여행이 없습니다. 여행을 만들고 인증을 받으세요',
		CN: '您没有现有的行程。创建行程并获得验证',
	},
	ONGOING_TRIP_MESSAGE: {
		EN: 'You have not yet completed your trip.',
		JP: 'まだ旅行は完了していません。',
		KR: '아직 여행을 완료하지 않으셨습니다.',
		CN: '您还没有完成您的旅程',
	},
	LOADING: {
		EN: 'Loading',
		JP: '読み込み中',
		KR: '로드 중',
		CN: '加载中',
	},
	CREATE_NEW_TRIP: {
		EN: 'Create New Trip',
		JP: '新しい旅行の作成',
		KR: '새 여행 만들기',
		CN: '创造新旅程',
	},
	RESUME_TRIP: {
		EN: 'Resume Trip',
		JP: '旅行を再開する',
		KR: '여행 재개',
		CN: '恢复旅行',
	},
	PRINT_QR_ID: {
		EN: 'Print QR ID.',
		JP: 'QR IDを印刷します。',
		KR: 'QR ID를 인쇄하세요.',
		CN: '打印二维码。',
	},
	SHOW_QR_MESSAGE: {
		EN: 'You may show this QR ID to the agent.',
		JP: 'この QR ID をエージェントに提示することができます。',
		KR: '이 QR ID를 상담원에게 보여주시면 됩니다.',
		CN: '您可以向客服人员出示此 QR ID。',
	},
	ACTIVE_TRIP_ALERT_MESSAGE: {
		EN: 'We require to upload your RT-PCR or Antigen result. Click continue to proceed.',
		JP: 'RT-PCR または抗原の結果をアップロードする必要があります。 [続行] をクリックして続行します。',
		KR: 'RT-PCR 또는 항원 결과를 업로드해야 합니다. 계속하려면 계속을 클릭하세요.',
		CN: '我们需要上传您的 RT-PCR 或抗原结果。单击继续继续。',
	},
	CONTINUE: {
		EN: 'Continue',
		JP: '続く',
		KR: '계속하다',
		CN: '继续',
	},
	MVA_FORM: {
		EN: 'MVA Form',
		JP: 'MVA フォーム',
		KR: 'MVA 양식',
		CN: 'MVA表格',
	},
	HEALTH_DECLARATION: {
		EN: 'Health Declaration',
		JP: '健康宣言',
		KR: '건강 선언',
		CN: '健康声明',
	},
	HEALTH_DECLARATION_DESCRIPTION: {
		EN: 'Please check if you have any of the following at present or during the past 14 days:',
		JP: '現在または過去 14 日間に次のいずれかに該当していないかどうかを確認してください。',
		KR: '현재 또는 지난 14일 동안 다음 중 하나라도 해당되는지 확인하세요.',
		CN: '请检查您当前或过去 14 天内是否有以下任何情况：',
	},
	CUSTOMS_FORM: {
		EN: 'Customs Form',
		JP: '関税フォーム',
		KR: '세관신고서',
		CN: '海关表格',
	},
	LOGOUT_MODAL_MESSAGE: {
		EN: 'Are you sure you want to logout this account?',
		JP: 'このアカウントからログアウトしてもよろしいですか?',
		KR: '정말로 이 계정에서 로그아웃하시겠습니까?',
		CN: '您确定要退出该帐户吗？',
	},
	LOGOUT_HEADER: {
		EN: 'Logout on your account',
		JP: 'アカウントからログアウトします',
		KR: '귀하의 계정에서 로그아웃하세요',
		CN: '注销您的帐户',
	},
	FEATURE_UNAVAILABLE: {
		EN: 'This feature is currently unavailable!',
		JP: 'この機能は現在利用できません。',
		KR: '이 기능은 현재 사용할 수 없습니다!',
		CN: '此功能目前无法使用！',
	},
	NEW_TRIP: {
		EN: 'New Trip',
		JP: '新しい旅',
		KR: '새로운 여행',
		CN: '新旅程',
	},
	NOTIFICATIONS: {
		EN: 'Notifications',
		JP: '通知',
		KR: '알림',
		CN: '通知',
	},
	TOUR_APP: {
		EN: 'Tour App',
		JP: 'ツアーアプリ',
		KR: '투어 앱',
		CN: '旅游应用程序',
	},
	NO_TRAVEL_QR_CODE: {
		EN: 'No Travel QR Code',
		JP: '旅行用 QR コードなし',
		KR: '여행 QR 코드 없음',
		CN: '没有旅行二维码',
	},
	QR_ID: {
		EN: 'QR ID',
		JP: 'QRID',
		KR: 'QR 아이디',
		CN: '二维码',
	},
	CONTINUE_AS_GUEST: {
		EN: 'Continue As Guest',
		JP: 'ゲストとして続行',
		KR: '게스트로 계속',
		CN: '访客身份继续',
	},
	ENTER_YOUR_EMAIL_ADDRESS: {
		EN: 'Enter your Email Address',
		JP: 'メールアドレスを入力してください',
		KR: '이메일 주소를 입력하세요',
		CN: '请输入您的电子邮件地址',
	},
	ENTER_YOUR_MOBILE_NUMBER: {
		EN: 'Enter your Mobile Number',
		JP: '携帯電話番号を入力してください',
		KR: '휴대 전화번호를 입력하세요',
		CN: '请输入您的手机号码',
	},
	CHECK_EMAIL_OTP: {
		EN: 'Please check your Email and enter OTP to activate your account.',
		JP: 'メールを確認して、OTPを入力してアカウントをアクティブ化してください。',
		KR: '이메일을 확인하고 OTP를 입력하여 계정을 활성화하십시오.',
		CN: '请查看您的电子邮件并输入 OTP 以激活您的账户。',
	},
	CHECK_MOBILE_OTP: {
		EN: 'Please check your SMS and enter OTP to activate your account.',
		JP: 'SMS を確認し、OTP を入力してアカウントを有効にしてください。',
		KR: 'SMS를 확인하고 OTP를 입력하여 계정을 활성화하세요.',
		CN: '请检查您的短信并输入一次性密码以激活您的帐户',
	},
	ENTER_OTP: {
		EN: 'ENTER ONE-TIME-PIN (OTP)',
		JP: 'ワンタイムパスワード(OTP)を入力してください',
		KR: '일회용 비밀번호 (OTP)를 입력하십시오',
		CN: '请输入一次性密码 (OTP)',
	},
	DIDNT_RECEIVE_OTP: {
		EN: "Didn't receive the OTP?",
		JP: 'OTPが届きませんでしたか?',
		KR: 'OTP를 받지 못하셨나요?',
		CN: '您没有收到 OTP?',
	},
	RESEND_OTP: {
		EN: 'Resend OTP via SMS',
		JP: 'SMS 経由で OTP を再送信する',
		KR: 'SMS를 통해 OTP 재전송',
		CN: '通过短信重新发送 OTP',
	},
	SEND_OTP_EMAIL: {
		EN: 'Send OTP via Email',
		JP: 'メールでOTPを送信する',
		KR: '이메일로 OTP 전송',
		CN: '通过电子邮件发送 OTP',
	},
	OTP_SENT_MESSAGE: {
		EN: 'OTP has been sent to your email.',
		JP: 'OTP があなたの電子メールに送信されました。',
		KR: '귀하의 이메일로 OTP가 발송되었습니다.',
		CN: 'OTP 已发送至您的电子邮件',
	},
	SENT: {
		EN: 'Sent',
		JP: '送信済み',
		KR: '전송된',
		CN: '发送',
	},
	INFORMATION_STATUS: {
		EN: 'Information Status',
		JP: '情報ステータス',
		KR: '정보현황',
		CN: '信息状态',
	},
	CLOSE: {
		EN: 'Close',
		JP: '近い',
		KR: '닫다',
		CN: '关闭',
	},
	STATUS: {
		EN: 'STATUS',
		JP: '状態',
		KR: '상태',
		CN: '地位',
	},
	NO_REMARKS: {
		EN: 'No remarks available',
		JP: 'コメントはありません',
		KR: '설명이 없습니다.',
		CN: '无可用备注',
	},
	NEW_FORM: {
		EN: 'You can submit another form to your next trip.',
		JP: '次回の旅行時に別のフォームを送信できます。',
		KR: '다음 여행 시 다른 양식을 제출할 수 있습니다.',
		CN: '您可以在下次旅行时提交另一份表格。',
	},
	ACTIVE_TRIP_MESSAGE: {
		EN: 'You have an active trip',
		JP: 'アクティブなご旅行になりましたね',
		KR: '활성 여행이 있습니다.',
		CN: '您有一个活跃的旅行',
	},
	TRIP_STATUS: {
		EN: 'Trip Status',
		JP: '旅行状況',
		KR: '여행현황',
		CN: '行程状态',
	},
	SELECT_TRAVEL_TYPE: {
		EN: 'Select your travel type',
		JP: '旅行のタイプを選択してください',
		KR: '여행 유형을 선택하세요',
		CN: '选择您的旅行类型',
	},
	US_CITIZEN: {
		EN: 'US Citizen',
		JP: '米国民',
		KR: '미국 시민권 자',
		CN: '美国公民',
	},
	LOCAL_CITIZEN_MESSAGE: {
		EN: 'For travellers that are travelling from international country (local citizen)',
		JP: '海外からの旅行者（国内在住者）へ',
		KR: '해외에서 여행하는 여행자(현지인)',
		CN: '对于从国际国家（当地公民）出发的旅客',
	},
	FOREIGN_CITIZEN: {
		EN: 'Foreign Citizen',
		JP: '外国人',
		KR: '외국인 시민',
		CN: '外国公民',
	},
	INTERNATIONAL_CITIZEN_MESSAGE: {
		EN: 'For travellers that are travelling international country',
		JP: '海外旅行される方へ',
		KR: '해외여행을 떠나는 여행자를 위한',
		CN: '对于前往国际国家旅行的旅客',
	},
	COPY_QR_LINK: {
		EN: 'Copy QR Link.',
		JP: 'QRリンクをコピーします。',
		KR: 'QR 링크를 복사하세요.',
		CN: '复制二维码链接。',
	},
	SEND_QR_LINK_EMAIL: {
		EN: 'Send QR via Email',
		JP: 'メールが送信されました',
		KR: '메시지가 전송되었습니다',
		CN: '邮件已发送',
	},
	EMAIL_SENT_ALREADY: {
		EN: 'Email Sent',
		JP: 'メールでQRを送信する',
		KR: '이메일로 QR 전송',
		CN: '无效的邮件地址',
	},
	INVALID_EMAIL: {
		EN: 'Invalid Email',
		JP: '無効な電子メール',
		KR: '잘못된 이메일',
		CN: '通过电子邮件发送 QR',
	},
	DOWNLOAD_TRIP_QR: {
		EN: 'Download Trip QR ID',
		JP: '旅行QR IDをダウンロード',
		KR: '여행 QR ID 다운로드',
		CN: '下載行程二維碼',
	},
	COMPLETE_TRIP: {
		EN: 'Download Trip QR ID',
		JP: '旅行を完了する',
		KR: '여행 완료',
		CN: '完整的旅程',
	},
	COMPLETED_TRIP_MESSAGE: {
		EN: 'You have successfully completed your trip. Are you sure you want to leave this page?',
		JP: '無事に旅行が完了しました。このページを離れてもよろしいですか?',
		KR: '여행을 성공적으로 마쳤습니다. 정말로 이 페이지를 떠나시겠습니까?',
		CN: '您已成功完成您的旅程。您确定要离开此页面吗',
	},
	LINK_COPIED_TO_CLIPBOARD: {
		EN: 'Link copied to clipboard',
		JP: 'リンクがクリップボードにコピーされました',
		KR: '링크가 클립보드에 복사되었습니다.',
		CN: '链接已复制到剪贴板',
	},
	LOADING_DOTS: {
		EN: 'Loading...',
		JP: '読み込み中...',
		KR: '로드 중...',
		CN: '加载中...',
	},
	DOMESTIC: {
		EN: 'Domestic',
		JP: '国内',
		KR: '국내의',
		CN: '国内的',
	},
	INTERNATIONAL: {
		EN: 'International',
		JP: '国際的',
		KR: '국제적인',
		CN: '国际的',
	},
	INTERNATIONAL_MESSAGE: {
		EN: 'For travellers that are travelling from international country.',
		JP: '海外からお越しの旅行者向け。',
		KR: '해외에서 여행하는 여행객을 위한 상품입니다.',
		CN: '对于从国际国家旅行的旅客。',
	},
	DOMESTIC_MESSAGE: {
		EN: 'For travellers that are travelling locally',
		JP: '現地を旅行する旅行者向け',
		KR: '현지 여행을 떠나는 여행자를 위한',
		CN: '对于在当地旅行的旅客',
	},
	TABULATED_MESSAGE: {
		EN: 'This information helps us to ensure the quality of your visit to the CNMI. Your answers are confidential and are tabulated for statistical purposes only.',
		JP: 'この情報は、CNMI訪問の質を確保するために役立てられます。回答は秘密に扱われ、統計目的でのみ集計されます。',
		KR: '이 정보는 CNMI 방문의 질을 보장하기 위해 사용됩니다. 답변은 비밀로 유지되며 통계 목적으로만 집계됩니다.',
		CN: '此信息将用于确保您在CNMI的访问质量。您的回答将被保密，仅用于统计目的。',
	},
	LEAVE_MESSAGE: {
		EN: 'Are you sure you want to leave this page?',
		JP: 'このページを離れてもよろしいですか?',
		KR: '정말로 이 페이지를 떠나시겠습니까?',
		CN: '您确定要离开此页面吗',
	},
	FOOTER_YEAR_NOTE: {
		EN: '2024 Travel Marianas.',
		JP: '2024 年のマリアナ旅行。',
		KR: '2024년 마리아나 여행.',
		CN: '2024 年马里亚纳群岛之旅。',
	},
	FOOTER_COPYRIGHT: {
		EN: 'All rights reserved.',
		JP: '無断転載を禁じます。',
		KR: '판권 소유.',
		CN: '版权所有。',
	},
	OR: {
		EN: 'or',
		JP: 'または',
		KR: '또는',
		CN: '或者',
	},
	SIGNATURE_INTRO: {
		EN: 'You must sign the form to proceed',
		JP: '続行するにはフォームに署名する必要があります',
		KR: '계속하려면 양식에 서명해야 합니다.',
		CN: '您必须签署表格才能继续',
	},
	SIGNATURE_DATE: {
		EN: 'Date:',
		JP: '署名日：',
		KR: '서명 날짜:',
		CN: '签名日期：',
	},
	SIGNATURE_SPIEL: {
		EN: 'I CERTIFY THAT I HAVE READ AND UNDERSTAND THE REQUIREMENTS ON THIS FORM AND THAT ALL VERBAL AND WRITTEN STATEMENTS WHICH I HAVE MADE ARE A TRUTHFUL DECLARATION',
		JP: '私は、このフォームの要件を読んで理解しており、私が行ったすべての口頭および書面による声明が真実の宣言であることを証明します。',
		KR: '나는 이 양식의 요구 사항을 읽고 이해했으며, 내가 한 모든 구두 및 서면 진술이 진실한 선언임을 보증합니다.',
		CN: '我证明我已阅读并理解本表格的要求，并且我所做的所有口头和书面陈述均为真实声明',
	},
	CUSTOM_DECLARATION: {
		EN: 'Customs Declaration',
		JP: '税関申告',
		KR: '세관신고서',
		CN: '报关',
	},
	MVA: {
		EN: 'MVA Survey',
		JP: 'MVA 調査',
		KR: 'MVA 설문조사',
		CN: '制造业增值调查',
	},
	MEDICAL_RECORD: {
		EN: 'Medical Record',
		JP: '医療記録',
		KR: '의료 기록',
		CN: '医疗记录',
	},
	CONTINUE_TO_CUSTOM_DECLARATION: {
		EN: 'Continue to Customs Declaration',
		JP: '税関申告に進む',
		KR: '세관 신고로 계속',
		CN: '继续报关',
	},
	CONTINUE_TO_MEDICAL_RECORD: {
		EN: 'Continue to Medical Record',
		JP: '医療記録に進む',
		KR: '의료 기록으로 계속',
		CN: '继续查看病历',
	},
	CONTINUE_TO_MVA: {
		EN: 'Continue to MVA Survey',
		JP: 'MVA アンケートに進む',
		KR: 'MVA 설문조사로 계속',
		CN: '继续参加 MVA 调查',
	},
	CONTINUE_TO_APP: {
		EN: 'Continue to App',
		JP: 'アプリに進む',
		KR: '앱으로 계속',
		CN: '继续应用程序',
	},
	CONTINUE_TO_GENERATE_TRIP: {
		EN: 'Continue to Generate QR Trip',
		JP: 'QR トリップの生成を続ける',
		KR: '계속해서 QR 여행 생성하기',
		CN: '继续生成行程二维码',
	},
	THANK_YOU: {
		EN: 'Thank you.',
		JP: 'ありがとう。',
		KR: '감사합니다.',
		CN: '谢谢。',
	},
	SUCCESS_NOTE_FIRST: {
		EN: 'Great! Your data was sent successfully',
		JP: '素晴らしい！データは正常に送信されました',
		KR: '엄청난! 귀하의 데이터가 성공적으로 전송되었습니다',
		CN: '伟大的！您的数据已成功发送',
	},
	SUCCESS_NOTE: {
		EN: 'Please allow us some time to confirm the information you sent us.',
		JP: 'お送りいただいた情報を確認するため、しばらくお時間をいただきます。',
		KR: '보내주신 정보를 확인하는 데 잠시 시간을 투자해 주시기 바랍니다.',
		CN: '请给我们一些时间来确认您发送给我们的信息。',
	},
	SIGN_AGAIN: {
		EN: 'Sign Again',
		JP: 'もう一度署名します',
		KR: '다시 서명',
		CN: '再次签名',
	},
	SIGNATURE_GUIDE: {
		EN: 'Use your mouse/finger to sign.',
		JP: 'マウス/指を使用して署名します。',
		KR: '마우스/손가락을 사용하여 서명하세요.',
		CN: '使用鼠标/手指进行签名。',
	},
	CLEAR: {
		EN: 'Clear',
		JP: 'クリア',
		KR: '분명한',
		CN: '清除',
	},
	REQUIRED: {
		EN: 'Required',
		JP: '必須',
		KR: '필수의',
		CN: '必需的',
	},
	UPLOADING: {
		EN: 'Uploading...',
		JP: 'アップロード中...',
		KR: '업로드 중...',
		CN: '正在上传...',
	},
	UPLOAD_AGAIN: {
		EN: 'UPLOAD AGAIN',
		JP: 'もう一度アップロードしてください',
		KR: '다시 업로드',
		CN: '重新上传',
	},
	ATTACH_FILE: {
		EN: 'ATTACH FILE',
		JP: 'ファイルを添付する',
		KR: '파일을 첨부',
		CN: '附加文件',
	},
	IMG_EXT: {
		EN: 'PNG, JPG,GIF up to 2MB',
		JP: 'PNG、JPG、GIF 最大 2MB',
		KR: 'PNG, JPG, GIF 최대 2MB',
		CN: 'PNG、JPG、GIF 高达 2MB',
	},
	ENGLISH_FORM: {
		EN: 'Please complete the form in English.',
		JP: 'フォームに英語で記入してください',
		KR: '양식을 영어로 작성해주세요.',
		CN: '请用英文填写表格',
	},
	ADD_SIGNATURE: {
		EN: 'Click to add signature',
		JP: 'クリックして署名を追加します',
		KR: '서명을 추가하려면 클릭하세요.',
		CN: '点击添加签名',
	},
	SAVE: {
		EN: 'Save',
		JP: '保存',
		KR: '저장',
		CN: '存储',
	},
	NEXT: {
		EN: 'Next',
		JP: '次',
		KR: '다음',
		CN: '下一个',
	},
	HEALTH_DECLARATION_FORM: {
		EN: 'Health Declaration Form',
		JP: '健康申告書',
		KR: '건강 신고서',
		CN: '健康申报表',
	},
	I_CONSENT: {
		EN: 'I consent',
		JP: '私は同意します',
		KR: '동의합니다',
		CN: '我同意',
	},
	NONE_OF_THE_ABOVE: {
		EN: 'None of the Above',
		JP: '上記のどれでもない',
		KR: '위에 해당사항 없음',
		CN: '以上都不是',
	},
	CONTINUE_ATTESTATION: {
		EN: 'Continue to Attestation',
		JP: '認証に進む',
		KR: '계속해서 증명하기',
		CN: '继续认证',
	},
	US_RESIDENT: {
		EN: 'U.S CITIZEN OR U.S IMMIGRANT (RESIDENT) ATTESTATION',
		JP: '米国国民または米国移民（居住者）の証明書',
		KR: '미국 시민 또는 미국 이민자(거주) 증명',
		CN: '美国公民或美国移民（居民）证明',
	},
	SIGNATURE_BELOW: {
		EN: 'Sign your signature below :',
		JP: '以下に署名してください。',
		KR: '아래에 서명하세요.',
		CN: '请在下面签名：',
	},
	FULLY_VACCINATED: {
		EN: 'Fully Vaccinated',
		JP: '完全にワクチン接種済み',
		KR: '백신 접종 완료',
		CN: '已全面接种疫苗',
	},
	FULLY_VACCINATED_MESSAGE: {
		EN: 'I attest that I am (or the person I am attesting on behalf of is) fully vaccinated against COVID-19',
		JP: '私は、私が（または私が代理して証明している人が）新型コロナウイルス感染症のワクチンを完全に接種していることを証明します。',
		KR: '본인(또는 본인을 대신하여 증명하는 사람)이 코로나19 백신 접종을 완료했음을 증명합니다.',
		CN: '我证明我（或我代表的证明人）已完全接种了 COVID-19 疫苗',
	},
	NOT_FULLY_VACCINATED: {
		EN: 'Not Fully Vaccinated',
		JP: '完全にワクチン接種を受けていない',
		KR: '백신 접종을 완전히 하지 않은 경우',
		CN: '未完全接种疫苗',
	},
	NOT_FULLY_VACCINATED_MESSAGE: {
		EN: 'I am not fully vaccinated and attest that I am (or the person I am attesting on behalf of is) excepted from the requirement to present Proof of Being Fully Vaccinated Against COVID-19 based on one of the following (check only one box, as applicable):',
		JP: '私は完全にワクチン接種を受けていませんが、次のいずれかに基づいて、私 (または私が代理で証明している人) が、新型コロナウイルス感染症に対する完全なワクチン接種を受けている証明書の提示要件から除外されていることを証明します (ボックスを 1 つだけチェックしてください)該当する）：',
		KR: '나는 백신 접종을 완전히 완료하지 않았으며 다음 중 하나에 따라 코로나19 백신 접종을 완료했다는 증명서를 제출해야 하는 요구 사항에서 나(또는 내가 대신하여 증명하는 사람)가 제외되었음을 증명합니다. 해당되는):',
		CN: '我尚未完全接种疫苗，并证明我（或我代表的人）无需根据以下条件之一出示已完全接种 COVID-19 疫苗的证明（仅勾选一个框，如适用的）：',
	},
	ATTESTATION: {
		EN: 'Attestation',
		JP: '認証',
		KR: '증명',
		CN: '鉴证',
	},
	SELECT_VACCINATION: {
		EN: '- Select Vaccination Status -',
		JP: '- 予防接種状況を選択してください -',
		KR: '- 예방접종 현황을 선택하세요 -',
		CN: '- 选择疫苗接种状态 -',
	},
	I_AGREE_WITH_THAT: {
		EN: 'I agree with the',
		JP: '私はそれに同意します',
		KR: '나는 동의한다',
		CN: '我同意',
	},
	PRIVACY_ACT_STATEMENT: {
		EN: 'Privacy Act Statement',
		JP: 'プライバシー法の声明',
		KR: '개인 정보 보호법 성명',
		CN: '隐私法声明',
	},
	ENTER_YOUR_PRINTED_NAME: {
		EN: 'ENTER YOUR PRINTED NAME',
		JP: '印刷された名前を入力してください',
		KR: '정자체 이름을 입력하세요',
		CN: '输入您的正楷姓名',
	},
	MEDICAL_UPLOAD_DESCRIPTION: {
		EN: 'Upload your Vaccination record or RT-PCR Result or Antigen Result and we will verify your medical record',
		JP: 'ワクチン接種記録、RT-PCR 結果、抗原結果をアップロードしてください。医療記録を確認します。',
		KR: '예방접종 기록이나 RT-PCR 결과 또는 항원 결과를 업로드하시면 의료 기록을 확인해드립니다.',
		CN: '上传您的疫苗接种记录或RT-PCR结果或抗原结果，我们将验证您的医疗记录',
	},
	DOSE: {
		EN: 'Dose',
		JP: '用量',
		KR: '정량',
		CN: '剂量',
	},
	DOSE_COVID: {
		EN: 'Dose Covid-19',
		JP: 'Covid-19の線量',
		KR: '코로나19 복용량',
		CN: '剂量 Covid-19',
	},
	BOOSTER_SHOT: {
		EN: 'Booster Shot',
		JP: 'ブースターショット',
		KR: '부스터 샷',
		CN: '助推器射击',
	},
	ADD_DETAILS: {
		EN: 'ADD DETAILS',
		JP: '詳細を追加',
		KR: '세부정보 추가',
		CN: '添加详细信息',
	},
	ENTER_CERTIFICATES: {
		EN: 'Enter your dose details and certificates',
		JP: '線量の詳細と証明書を入力してください',
		KR: '복용량 세부 정보 및 인증서를 입력하세요.',
		CN: '输入您的剂量详细信息和证书',
	},
	MEDICAL_ALERT: {
		EN: "You haven't uploaded your RT-PCR or Antigen result. You may upload your results via menu.",
		JP: 'RT-PCR または抗原の結果をアップロードしていません。メニューから結果をアップロードできます。',
		KR: 'RT-PCR 또는 항원 결과를 업로드하지 않았습니다. 메뉴를 통해 결과를 업로드할 수 있습니다.',
		CN: '您尚未上传 RT-PCR 或抗原结果。您可以通过菜单上传您的结果。',
	},
	RTPCR_MESSAGE: {
		EN: 'RT-PCR Result is valid until 48 hours',
		JP: 'RT-PCR 結果は 48 時間まで有効です。',
		KR: 'RT-PCR 결과는 48시간까지 유효합니다.',
		CN: 'RT-PCR 结果的有效期为 48 小时。',
	},
	ANTIGEN_MESSAGE: {
		EN: 'Antigen Result is valid until 24 hours.',
		JP: '抗原結果は 24 時間まで有効です。',
		KR: '항원 결과는 24시간까지 유효합니다.',
		CN: '抗原结果有效期至 24 小时。',
	},
	ANTIGEN_MESSAGE_RESULT: {
		EN: 'Upload Antigen Result',
		JP: '抗原結果のアップロード',
		KR: '항원 결과 업로드',
		CN: '上传抗原结果',
	},
	OPTIONAL: {
		EN: 'Optional',
		JP: 'オプション',
		KR: '선택 과목',
		CN: '选修的',
	},
	ADDITIONAL_BOOSTER: {
		EN: 'Additional Booster Shot',
		JP: '追加ブースターショット',
		KR: '추가 부스터 샷',
		CN: '额外的助推器射击',
	},
	PATIENT_NUMBER: {
		EN: 'PATIENT NUMBER',
		JP: '患者番号',
		KR: '환자 번호',
		CN: '病人号码',
	},
	PRODUCT_NAME: {
		EN: 'PRODUCT NAME',
		JP: '商品名',
		KR: '상품명',
		CN: '产品名称',
	},
	LOT_NUMBER: {
		EN: 'LOT NUMBER',
		JP: '整理番号',
		KR: '로트 번호',
		CN: '批号',
	},
	DATE_ADMINISTERED: {
		EN: 'DATE ADMINISTERED',
		JP: '投与日',
		KR: '투여 날짜',
		CN: '给药日期',
	},
	HEALTHCARE: {
		EN: 'HEALTHCARE PROFESSIONAL / SITE',
		JP: '医療専門家 / 現場',
		KR: '의료 전문가/사이트',
		CN: '医疗保健专业人士/网站',
	},
	UPLOAD_VACCINE_CARD: {
		EN: 'Upload Vaccine Card',
		JP: 'ワクチンカードをアップロードする',
		KR: '백신 카드 업로드',
		CN: '上传疫苗卡',
	},
	DELETE_MESSAGE: {
		EN: 'Are you sure you want to delete?',
		JP: '消去してもよろしいですか？',
		KR: '삭제 하시겠습니까?',
		CN: '你确定你要删除？',
	},
	I_ATTEST_MESSAGE: {
		EN: 'I attest that the information stated above is true and accurate, and understand that the above information, if misrepresented, or incomplete, may be grounds for immediate investigation and/or penalties as specified by law.',
		JP: '私は、上記の情報が真実かつ正確であることを証明し、上記の情報が虚偽で伝えられた場合、または不完全な場合には、法律で定められた即時調査および/または罰則の根拠となる可能性があることを理解しています。',
		KR: '본인은 위에 명시된 정보가 사실이고 정확함을 증명하며, 위의 정보가 허위이거나 불완전할 경우 법률에 따라 즉각적인 조사 및/또는 처벌의 근거가 될 수 있음을 이해합니다.',
		CN: '我证明上述信息真实准确，并理解上述信息如果被歪曲或不完整，可能会导致立即调查和/或受到法律规定的处罚。',
	},
	MOBILE_INVALID_ERROR: {
		EN: 'Mobile Phones is Invalid',
		JP: '携帯電話は無効です',
		KR: '휴대폰이 유효하지 않습니다',
		CN: '手机号码无效',
	},
	HOME_INVALID_ERROR: {
		EN: 'Home Phones is Invalid',
		JP: '自宅の電話が無効です',
		KR: '집 전화가 유효하지 않습니다.',
		CN: '家庭电话无效',
	},
	WORK_INVALID_ERROR: {
		EN: 'Work Phones is Invalid',
		JP: '職場の電話は無効です',
		KR: '직장 전화가 유효하지 않습니다.',
		CN: '工作电话无效',
	},
	INTERNATIONAL_INVALID_ERROR: {
		EN: 'International Phones is Invalid',
		JP: '国際電話は無効です',
		KR: '국제전화가 유효하지 않습니다',
		CN: '国际电话无效',
	},
	MOBILE_DONT_MATCH_ERROR: {
		EN: "Mobile Phones don't match",
		JP: '携帯電話が一致しません',
		KR: '휴대폰이 일치하지 않습니다.',
		CN: '手机不匹配',
	},
	HOME_DONT_MATCH_ERROR: {
		EN: "Home Phones don't match",
		JP: '自宅の電話番号が一致しない',
		KR: '집 전화가 일치하지 않습니다',
		CN: '家庭电话不匹配',
	},
	WORK_DONT_MATCH_ERROR: {
		EN: "Work Phones don't match",
		JP: '勤務先の電話番号が一致しません',
		KR: '직장 전화가 일치하지 않습니다.',
		CN: '工作电话不匹配',
	},
	INTERNATIONAL_DONT_MATCH_ERROR: {
		EN: "International Phones don't match",
		JP: '国際電話が一致しません',
		KR: '국제전화가 일치하지 않습니다.',
		CN: '国际电话不匹配',
	},
	EMAIL_DONT_MATCH_ERROR: {
		EN: "Email Address don't match",
		JP: 'メールアドレスが一致しません',
		KR: '이메일 주소가 일치하지 않습니다',
		CN: '电子邮件地址不匹配',
	},
	DIGITAL_VACCINATION_QR_ID: {
		EN: 'DIGITAL VACCINATION QR ID',
		JP: 'デジタルワクチン接種QR ID',
		KR: '디지털 예방접종 QR ID',
		CN: '数字疫苗接种二维码',
	},
	COVID_VACCINATION_RECORD_CARD: {
		EN: 'COVID-19 Vaccination Record Card',
		JP: 'COVID-19 ワクチン接種記録カード',
		KR: '코로나19 예방접종 기록 카드',
		CN: 'COVID-19 疫苗接种记录卡',
	},
	PRINT_VACCINATION_QR_ID: {
		EN: 'Print Vaccination QR ID',
		JP: '予防接種QR IDを印刷する',
		KR: '예방접종 QR ID 인쇄',
		CN: '打印疫苗接种二维码',
	},
	DOWNLOAD_VACCINATION_QR_ID: {
		EN: 'Download Vaccination QR ID',
		JP: '予防接種QR IDをダウンロード',
		KR: '예방접종 QR ID 다운로드',
		CN: '下载疫苗二维码',
	},
	COMPLETE_PROFILE_HEADER: {
		EN: 'Complete Profile',
		JP: '完全なプロフィール',
		KR: '프로필 완성',
		CN: '完整的个人资料',
	},
	UPDATE_PROFILE_HEADER: {
		EN: 'Update Profile',
		JP: 'プロフィールを更新する',
		KR: '프로필 업데이트',
		CN: '更新个人信息',
	},
	// Start Help Component
	INTRO_HELP_FIRST_PARAGRAPH: {
		EN: 'We are thrilled to provide you with a user-friendly platform where you can effortlessly create your customs declarations and complete MVA surveys. It is designed to streamline the process and make it convenient for you. . This guide will provide you with an overview of the system and the steps you need to take to effectively use Travel Marianas app.',
		JP: '',
		KR: '',
		CN: '',
	},
	TRAVEL_MARIANAS: {
		EN: 'Travel Marianas',
		JP: '',
		KR: '',
		CN: '',
	},
	QUICK_START_GUIDE: {
		EN: 'Quick Start Guide',
		JP: '',
		KR: '',
		CN: '',
	},
	WELCOME_TRAVEL_MARIANAS: {
		EN: 'Welcome to the Travel Marianas',
		JP: '',
		KR: '',
		CN: '',
	},
	CONTENTS: {
		EN: 'Contents',
		JP: '',
		KR: '',
		CN: '',
	},
	PROCESS_FLOW: {
		EN: 'Process Flow',
		JP: '',
		KR: '',
		CN: '',
	},
	CREATING_PROFILE: {
		EN: 'Creating a profile',
		JP: '',
		KR: '',
		CN: '',
	},
	CREATING_CUSTOMS: {
		EN: 'Creating a Customs Declaration',
		JP: '',
		KR: '',
		CN: '',
	},
	SUBMIT_MVA: {
		EN: 'Submitting an MVA Survey',
		JP: '',
		KR: '',
		CN: '',
	},
	QR_CODE: {
		EN: 'QR Code',
		JP: '',
		KR: '',
		CN: '',
	},
	TRIP_CRUD: {
		EN: 'View, Edit, Delete Trip',
		JP: '',
		KR: '',
		CN: '',
	},
	INFO_TERMS_NOTICE: {
		EN: 'Info, Terms & Notice',
		JP: '',
		KR: '',
		CN: '',
	},
	HELP_DELETE_ACCOUNT: {
		EN: 'Delete Account',
		JP: '',
		KR: '',
		CN: '',
	},
	PROCESS_FLOW_FIRST_BULLET: {
		EN: 'Traveler goes to travel.mp',
		JP: '',
		KR: '',
		CN: '',
	},
	PROCESS_FLOW_SECOND_BULLET: {
		EN: 'Traveler creates a profile',
		JP: '',
		KR: '',
		CN: '',
	},
	PROCESS_FLOW_THIRD_BULLET: {
		EN: 'Traveler answers the Customs Declaration',
		JP: '',
		KR: '',
		CN: '',
	},
	PROCESS_FLOW_FOURTH_BULLET: {
		EN: 'Traveler answer the MVA survey',
		JP: '',
		KR: '',
		CN: '',
	},
	PROCESS_FLOW_FIFTH_BULLET: {
		EN: 'QR code is generated',
		JP: '',
		KR: '',
		CN: '',
	},
	PROCESS_FLOW_SIXTH_BULLET: {
		EN: 'Traveler shows the QR to Customs Office',
		JP: '',
		KR: '',
		CN: '',
	},
	// End Help Component
	// start customs and mva form
	date_of_arrival: {
		EN: 'DATE OF ARRIVAL IN THE CNMI',
		JP: '到着日を入力してください。',
		KR: 'CNMI 도착 날짜',
		CN: '到达北马里亚纳群岛联邦（CNMI）的日期',
	},
	type_of_transportation: {
		EN: 'WILL YOU BE ARRIVING INTO THE CNMI BY AIR OR SEA?',
		JP: 'CNMIへの到着は航空機または船でですか？',
		KR: '항공편으로 도착하시나요, 아니면 해상으로 도착하시나요?',
		CN: '您是乘飞机抵达还是通过海上到达北马里亚纳群岛联邦（CNMI）的?',
	},
	type_of_flight: {
		EN: 'TYPE OF FLIGHT',
		JP: 'フライトの種類',
		KR: '항공편',
		CN: '航班类型',
	},
	airline_flight: {
		EN: 'AIRLINE COMPANY',
		JP: '航空会社',
		KR: '항공회사',
		CN: '航空公司',
	},
	airline_flight_no: {
		EN: 'AIRLINE FLIGHT NO.',
		JP: '航空会社のフライト番号',
		KR: '항공편 번호',
		CN: '航班号码',
	},
	vessel_info: {
		EN: 'VESSEL INFORMATION',
		JP: '船舶情報',
		KR: '선박 정보',
		CN: '船只信息',
	},
	origin_country: {
		EN: 'ORIGINATING FROM',
		JP: '出発地',
		KR: '출발지',
		CN: '起始地',
	},
	first_name: {
		EN: 'FIRST NAME',
		JP: '名前（アルファベット順）',
		KR: '이름',
		CN: '名字',
	},
	middle_name: {
		EN: 'MIDDLE NAME',
		JP: 'ミドルネーム（アルファベット順）',
		KR: '중간 이름',
		CN: '中间名',
	},
	last_name: {
		EN: 'LAST NAME',
		JP: '姓（アルファベット順）',
		KR: '성',
		CN: '姓氏',
	},
	birthdate: {
		EN: 'DATE OF BIRTH',
		JP: '生年月日を入力してください。 ',
		KR: '생년월일',
		CN: '出生日期',
	},
	passport_number: {
		EN: 'PASSPORT NUMBER',
		JP: 'パスポート番号（アルファベットと数字）*',
		KR: '여권 번호',
		CN: '护照号码',
	},
	passport_country: {
		EN: 'PASSPORT COUNTRY',
		JP: 'パスポート/ID（国）',
		KR: '여권 국가',
		CN: '护照国家',
	},
	purpose: {
		EN: 'PURPOSE OF CNMI TRIP',
		JP: 'CNMI旅行の目的',
		KR: 'CNMI 여행의 목적',
		CN: '旅行目的',
	},
	PERSON_1: {
		EN: 'Passenger 1 (Self)',
		JP: '人物 1',
		KR: '1번',
		CN: '第一人',
	},
	PERSON_2: {
		EN: 'Passenger 2',
		JP: '人物 2',
		KR: '2번',
		CN: '第二人',
	},
	PERSON_3: {
		EN: 'Passenger 3',
		JP: '人物 3',
		KR: '3번',
		CN: '第三人',
	},
	PERSON_4: {
		EN: 'Passenger 4',
		JP: '人物 4',
		KR: '4번',
		CN: '第四人',
	},
	PERSON_5: {
		EN: 'Passenger 5',
		JP: '人物 5',
		KR: '5번',
		CN: '第五人',
	},
	PERSON_6: {
		EN: 'Passenger 6',
		JP: '人物 6',
		KR: '6번',
		CN: '第六人',
	},
	PERSON_7: {
		EN: 'Passenger 7',
		JP: '人物 7',
		KR: '7번',
		CN: '第七人',
	},
	person_1_passport: {
		EN: 'PASSENGER 1 - PASSPORT NO',
		JP: '人物 1 パスポート番号',
		KR: '1번 여권 번호',
		CN: '第一人 的 护照号码',
	},
	person_1_passport_country: {
		EN: 'PASSENGER 1 - COUNTRY OF ISSUANCE',
		JP: '人物 1 発行国',
		KR: '1번 여권 발급 국가',
		CN: '第一人 的 护照国家',
	},
	person_1_first_name: {
		EN: 'PASSENGER 1 - FIRST NAME',
		JP: '人物 1 名',
		KR: '1번 이름',
		CN: '第一人 的 名字',
	},
	person_1_middle_name: {
		EN: 'PASSENGER 1 - MIDDLE NAME',
		JP: '人物 1 ミドルネーム',
		KR: '1번 중간 이름',
		CN: '第一人 的 中间名',
	},
	person_1_last_name: {
		EN: 'PASSENGER 1 - LAST NAME',
		JP: '人物 1 姓',
		KR: '1번 성',
		CN: '第一人 的 姓氏',
	},
	person_1_birthdate: {
		EN: 'DATE OF BIRTH',
		JP: 'の生年月日',
		KR: '생년월일',
		CN: '出生日期',
	},
	person_1_gender: {
		EN: 'GENDER',
		JP: '性別',
		KR: '성별',
		CN: '性别',
	},
	person_2_passport: {
		EN: 'PASSENGER 2 - PASSPORT NO',
		JP: '人物 2 パスポート番号',
		KR: '2번 여권 번호',
		CN: '第二人 的 护照号码',
	},
	person_2_passport_country: {
		EN: 'PASSENGER 2 - COUNTRY OF ISSUANCE',
		JP: '人物 2 発行国',
		KR: '2번 여권 발급 국가',
		CN: '第二人 的 护照国家',
	},
	person_2_first_name: {
		EN: 'PASSENGER 2 - FIRST NAME',
		JP: '人物 2 の名前',
		KR: '2번 이름',
		CN: '第二人 的 名字',
	},
	person_2_middle_name: {
		EN: 'PASSENGER 2 - MIDDLE NAME',
		JP: '人物 2 ミドルネーム',
		KR: '2번 중간 이름',
		CN: '第二人 的 中间名',
	},
	person_2_last_name: {
		EN: 'PASSENGER 2 - LAST NAME',
		JP: '人物 2 の姓',
		KR: '2번 성',
		CN: '第二人 的 姓氏',
	},
	person_2_birthdate: {
		EN: 'PASSENGER 2 - DATE OF BIRTH',
		JP: '人物 2 の生年月日',
		KR: '2번 생년월일',
		CN: '第二人 的 出生日期',
	},
	person_2_gender: {
		EN: 'PASSENGER 2 - GENDER',
		JP: '人物 2 性別',
		KR: '2번 성별',
		CN: '第二人 的 性别',
	},
	person_3_passport: {
		EN: 'PASSENGER 3 - PASSPORT NO',
		JP: '人物 3 パスポート番号',
		KR: '3번 여권 번호',
		CN: '第三人 的 护照号码',
	},
	person_3_passport_country: {
		EN: 'PASSENGER 3 - COUNTRY OF ISSUANCE',
		JP: '人物 3 発行国',
		KR: '3번 여권 발급 국가',
		CN: '第三人 的 护照国家',
	},
	person_3_first_name: {
		EN: 'PASSENGER 3 - FIRST NAME',
		JP: '人物 3 の名前',
		KR: '3번 이름',
		CN: '第三人 的 名字',
	},
	person_3_middle_name: {
		EN: 'PASSENGER 3 - MIDDLE NAME',
		JP: '人物 3 ミドルネーム',
		KR: '3번 중간 이름',
		CN: '第三人 的 中间名',
	},
	person_3_last_name: {
		EN: 'PASSENGER 3 - LAST NAME',
		JP: '人物 3 の姓',
		KR: '3번 성',
		CN: '第三人 的 姓氏',
	},
	person_3_birthdate: {
		EN: 'PASSENGER 3 - DATE OF BIRTH',
		JP: '人物 3 の生年月日',
		KR: '3번 생년월일',
		CN: '第三人 的 出生日期',
	},
	person_3_gender: {
		EN: 'PASSENGER 4 - GENDER',
		JP: '人物 3 性別',
		KR: '3번 성별',
		CN: '第四人 的 性别',
	},
	person_4_passport: {
		EN: 'PASSENGER 4 - PASSPORT NO',
		JP: '人物 4 パスポート番号',
		KR: '4번 여권 번호',
		CN: '第四人 的 护照号码',
	},
	person_4_passport_country: {
		EN: 'PASSENGER 4 - COUNTRY OF ISSUANCE',
		JP: '人物 4 発行国',
		KR: '4번 여권 발급 국가',
		CN: '第四人 的 护照国家',
	},
	person_4_first_name: {
		EN: 'PASSENGER 4 - FIRST NAME',
		JP: '人物 4 の名前',
		KR: '4번 이름',
		CN: '第四人 的 名字',
	},
	person_4_middle_name: {
		EN: 'PASSENGER 4 - MIDDLE NAME',
		JP: '人物 4 ミドルネーム',
		KR: '4번 중간 이름',
		CN: '第四人 的 中间名',
	},
	person_4_last_name: {
		EN: 'PASSENGER 4 - LAST NAME',
		JP: '人物 4 の姓',
		KR: '4번 성',
		CN: '第四人 的 姓氏',
	},
	person_4_birthdate: {
		EN: 'PASSENGER 4 - DATE OF BIRTH',
		JP: '人物 4 生年月日',
		KR: '4번 생년월일',
		CN: '第四人 的 出生日期',
	},
	person_4_gender: {
		EN: 'PASSENGER 4 - GENDER',
		JP: '人物 4 性別',
		KR: '4번 성별',
		CN: '第四人 的 性别',
	},
	person_5_passport: {
		EN: 'PASSENGER 5 - PASSPORT NO',
		JP: '人物 5 パスポート番号',
		KR: '5번 여권 번호',
		CN: '第五人 的 护照号码',
	},
	person_5_passport_country: {
		EN: 'PASSENGER 5 - COUNTRY OF ISSUANCE',
		JP: '人物 5 発行国',
		KR: '5번 여권 발급 국가',
		CN: '第五人 的 护照国家',
	},
	person_5_first_name: {
		EN: 'PASSENGER 5 - FIRST NAME',
		JP: '人物 5 の名前',
		KR: '5번 이름',
		CN: '第五人 的 名字',
	},
	person_5_middle_name: {
		EN: 'PASSENGER 5 - MIDDLE NAME',
		JP: '人物 5 ミドルネーム',
		KR: '5번 중간 이름',
		CN: '第五人 的 中间名',
	},
	person_5_last_name: {
		EN: 'PASSENGER 5 - LAST NAME',
		JP: '人物 5 の姓',
		KR: '5번 성',
		CN: '第五人 的 姓氏',
	},
	person_5_birthdate: {
		EN: 'PASSENGER 5 - DATE OF BIRTH',
		JP: '人物 5 生年月日',
		KR: '5번 생년월일',
		CN: '第五人 的 出生日期',
	},
	person_5_gender: {
		EN: 'PASSENGER 5 - GENDER',
		JP: '人物 5 性別',
		KR: '5번 성별',
		CN: '第五人 的 性别',
	},
	person_6_passport: {
		EN: 'PASSENGER 6 - PASSPORT NO',
		JP: '人物 6 パスポート番号',
		KR: '6번 여권 번호',
		CN: '第六人 的 护照号码',
	},
	person_6_passport_country: {
		EN: 'PASSENGER 6 - COUNTRY OF ISSUANCE',
		JP: '人物 6 発行国',
		KR: '6번 여권 발급 국가',
		CN: '第六人 的 护照国家',
	},
	person_6_first_name: {
		EN: 'PASSENGER 6 - FIRST NAME',
		JP: '人物 6 の名前',
		KR: '6번 이름',
		CN: '第六人 的 名字',
	},
	person_6_middle_name: {
		EN: 'PASSENGER 6 - MIDDLE NAME',
		JP: '人物 6 ミドルネーム',
		KR: '6번 중간 이름',
		CN: '第六人 的 中间名',
	},
	person_6_last_name: {
		EN: 'PASSENGER 6 - LAST NAME',
		JP: '人物 6 姓',
		KR: '6번 성',
		CN: '第六人 的 姓氏',
	},
	person_6_birthdate: {
		EN: 'PASSENGER 6 - DATE OF BIRTH',
		JP: '人物 6 生年月日',
		KR: '6번 생년월일',
		CN: '第六人 的 出生日期',
	},
	person_6_gender: {
		EN: 'PASSENGER 6 - GENDER',
		JP: '人物 6 性別',
		KR: '6번 성별',
		CN: '第六人 的 性别',
	},
	person_7_passport: {
		EN: 'PASSENGER 7 - PASSPORT NO',
		JP: '人物 7 パスポート番号',
		KR: '7번 여권 번호',
		CN: '第七人 的 护照号码',
	},
	person_7_passport_country: {
		EN: 'PASSENGER 7 - COUNTRY OF ISSUANCE',
		JP: '人物 7 発行国',
		KR: '7번 여권 발급 국가',
		CN: '第七人 的 护照国家',
	},
	person_7_first_name: {
		EN: 'PASSENGER 7 - FIRST NAME',
		JP: '人物 7 名',
		KR: '7번 이름',
		CN: '第七人 的 名字',
	},
	person_7_middle_name: {
		EN: 'PASSENGER 7 - MIDDLE NAME',
		JP: '人物 7 ミドルネーム',
		KR: '7번 중간 이름',
		CN: '第七人 的 中间名',
	},
	person_7_last_name: {
		EN: 'PASSENGER 7 - LAST NAME',
		JP: '人物 7 姓',
		KR: '7번 성',
		CN: '第七人 的 姓氏',
	},
	person_7_birthdate: {
		EN: 'PASSENGER 7 - DATE OF BIRTH',
		JP: '人物 7 生年月日',
		KR: '7번 생년월일',
		CN: '第七人 的 出生日期',
	},
	person_7_gender: {
		EN: 'PASSENGER 7 - GENDER',
		JP: '人物 7 性別',
		KR: '7번 성별',
		CN: '第七人 的 性别',
	},
	address: {
		EN: 'ADDRESS WHILE IN CNMI: (Name of Hotel/Village of Residence)',
		JP: '北マリアナ諸島での滞在先：宿泊先のホテルノビレッジ名',
		KR: 'CNMI 체류지 주소: (거주 호텔/빌리지 이름)',
		CN: '在CNMI的地址：（酒店/村名）',
	},

	has_alcohol: {
		EN: '(F) ALCOHOL (DISTILLED- 77 TOTAL FLUID OUNCES, BEER OR MALT - 288 TOTAL FLUID OUNCES, WINE OR SAKE -128 TOTAL FLUID OUNCES)',
		JP: '(F) アルコール（蒸留酒-合計77液体オンス、ビールまたは麦芽-合計288液体オンス、ワインまたは酒-合計128液体オンス）',
		KR: '알코올(증류주 - 총 77액량 온스, 맥주 또는 맥아 - 총 288액량 온스, 와인 또는 사케 - 총 128액량 온스)',
		CN: '(F) 酒精(蒸馏-总共77液体盎司，啤酒或麦芽-总共288液体盎司，葡萄酒或清酒-总共128液体盎司)',
	},
	has_animal: {
		EN: '(E) LIVE SERVICE ANIMAL',
		JP: '（B）生きた奉仕動物',
		KR: '보조견 혹은 보조 동행 동물',
		CN: '(E) 服务动物',
	},
	has_cigarettes: {
		EN: "(H) CIGARETTES (30PACK, US BRAND AND/OR 10 PACKS OF LABEL CIGARETES NOT COMPLYING WITH CIGARETES LABELING AND ADVERTISING ACT OR NOT LISTED ON THE ATTORNEY GENERAL'S DIRECTORY OF APPROVED CIGARETES BRANDS. IF YOU POSSESS MORE THAN LISTED, DECLARE ON THE ITEMS AND QUANTITIES IN THE TABLE BELOW)",
		JP: '(H) タバコ（30パック、米国ブランドおよび/またはシガレットのラベルや広告法に準拠していないラベルのシガレットの10パック、または司法長官の承認済みシガレットブランドディレクトリに記載されていないシガレットの場合。指定された量よりも多くを所持している場合は、以下の表でアイテムと数量を宣言してください）',
		KR: '담배(30갑, 미국 브랜드 및/또는 담배 라벨링 및 광고법을 준수하지 않거나 법무장관의 승인 담배 브랜드 목록에 등재되지 않은 라벨 담배 10갑. 나열된 것보다 더 많이 소지하는 경우 품목에 신고하고 아래 표의 수량)',
		CN: '(H) 香烟（30包，美国品牌和/或10包未遵守香烟标签和广告法或未列入检察长批准的香烟品牌目录。如果您持有的数量超过所列，请在下表中声明物品和数量)',
	},
	has_excess_currency: {
		EN: '(J) CURRENCY OR MONETARY INSTRUMENTS OVER USD $ 10,000 OR FOREIGN EQUIVALENT (FROM ANY COUNTRY , NOT LIMITED TO CNY, EUR, HKD, JPY, KRW, PHP, RUB, THB, TWD)',
		JP: '(J) USD $ 10,000以上またはその外国通貨に相当する金額の通貨または金融商品（任意の国から、CNY、EUR、HKD、JPY、KRW、PHP、RUB、THB、TWDなどに限定されません）',
		KR: '이상의 통화 또는 화폐 수단 또는 이에 상응하는 외국 화폐(CNY, EUR, HKD, JPY, KRW, PHP, RUB, THB, TWD에 국한되지 않음)',
		CN: '(J) 超过10,000美元的货币或货币工具或外币等值物（来自任何国家，不限于人民币，欧元，港币，日元，韩元，菲律宾比索，俄罗斯卢布，泰铢，新台币',
	},
	excess_currency: {
		EN: 'Amount (0-9)',
		JP: '額 (0-9)',
		KR: '양 (0-9)',
		CN: '数量 (0-9)',
	},
	currency: {
		EN: 'Currency',
		JP: '通貨',
		KR: '통화',
		CN: '货币',
	},
	has_harmful_objects: {
		EN: '(A) CONTROLLED SUBSTANCES, EXPLOSIVES OR WEAPONS',
		JP: '（A）規制物質、爆発物、または武器 *',
		KR: '(A) 규제 물질, 폭발물 또는 무기',
		CN: '(A) 受控制的物质、爆炸物或武器',
	},
	has_merchandise: {
		EN: '(I) COMMERCIAL MERCHANDISE (ITEMS FOR SALE, SAMPLES FOR SOLICITING ORDERS, GOODS THAT ARE NOT CONSIDERED PERSONAL EFFECTS)',
		JP: '(I) 商業用商品（販売用品、注文募集用のサンプル、個人用品と見なされない商品）',
		KR: '상업용 상품(판매용 품목, 주문 요청용 샘플, 개인 소지품으로 간주되지 않는 상품)',
		CN: '(I) 商业商品（待售物品，征集订单样品，不属于个人物品的货物',
	},
	has_plants: {
		EN: '(C) PLANTS OR PARTS OF PLANTS, INCLUDING FRESH FRUITS AND VEGETABLES OR ARTICLES MADE OF PLANT MATERIALS',
		JP: '（C）植物または植物の部品、新鮮な果物や野菜を含む植物素材で作られた物品',
		KR: '과일, 식물, 식품',
		CN: '(C) 植物或植物部分，包括新鲜水果和蔬菜，或由植物材料制成的物品',
	},
	has_soil: {
		EN: '(D) SOIL MATERIALS OR SAMPLES OR BIOLOGICAL SPECIMENS',
		JP: '(D) 土壌材料またはサンプル、または生物学的標本',
		KR: '토양 물질이나 시료 또는 생물학적 표본',
		CN: '(D) 土壤材料或样品或生物标本',
	},
	has_tobacco: {
		EN: 'G) TOBACCO (LOOSE TOBACCO NOT TO EXCEED ONE POUND)',
		JP: '(G) タバコ（散らかしたタバコは1ポンドを超えない）',
		KR: '담배(1파운드를 초과하지 않는 느슨한 담배)',
		CN: '(G) 烟草（散烟草不得超过一磅)',
	},
	has_wildlife: {
		EN: '(B) ANIMALS OR PARTS OF ANIMALS OR ARTICLES MANUFACTURED FROM WILDLIFE',
		JP: '（B）動物または動物の部品、または野生動物から製造された物品',
		KR: '육가공품을 포함하여, 동물 혹은 동물 일부',
		CN: '(B) 动物、动物部分或由野生动物制造的物品',
	},
	country: {
		EN: 'SELECT COUNTRY OF RESIDENCE',
		JP: '居住国を選択してください',
		KR: '현재 거주국가를 체크해주세요',
		CN: '选择居住国家',
	},
	region: {
		EN: 'SELECT REGION',
		JP: '地域の選択',
		KR: '지역 선택',
		CN: '选择地区',
	},
	china_region: {
		EN: 'SELECT REGION',
		JP: '地域の選択',
		KR: '지역 선택',
		CN: '选择地区',
	},
	japan_region: {
		EN: 'SELECT REGION',
		JP: '地域の選択',
		KR: '지역 선택',
		CN: '选择地区',
	},
	korea_region: {
		EN: 'SELECT REGION',
		JP: '地域の選択',
		KR: '지역 선택',
		CN: '选择地区',
	},
	accomodations: {
		EN: 'WHILE IN THE CNMI, I PLAN TO DO THE FOLLOWING: (MARK ALL THAT APPLY)',
		JP: 'CNMI滞在中、私は以下のことを計画しています：（該当するものをすべて選択）',
		KR: '사이판에서의 체류 중 계획한 활동: (모두 선택)',
		CN: '在北马里亚纳群岛期间，我计划执行以下操作：（标记所有适用项',
	},
	family_count: {
		EN: 'NUMBER OF FAMILY MEMBERS TRAVELING WITH YOU (including self)',
		JP: 'あなたを含む家族の人数を入力してください。',
		KR: '동행하는 가족인원 수 (본인 포함)',
		CN: '与您一同旅行的家庭成员数量',
	},
	occupation: {
		EN: 'SELECT OCCUPATION',
		JP: '職業を選択',
		KR: '직업 선택',
		CN: '选择 职业',
	},
	person_1_age: {
		EN: 'PASSENGER 1 - AGE (SELF)',
		JP: '人物 1 年齢 (自分)',
		KR: '사람 1 나이 (본인)',
		CN: '第一人 的 年纪',
	},
	person_2_age: {
		EN: 'PASSENGER 2 - AGE',
		JP: '人物 2 年齢',
		KR: '사람 2 나이',
		CN: '第二人 的 年纪',
	},
	person_3_age: {
		EN: 'PASSENGER 3 - AGE',
		JP: '人物 3 年齢',
		KR: '사람 3 나이',
		CN: '第三人 的 年纪',
	},
	person_4_age: {
		EN: 'PASSENGER 4 - AGE',
		JP: '人物 4 年齢',
		KR: '사람 4 나이',
		CN: '第四人 的 年纪',
	},
	person_5_age: {
		EN: 'PASSENGER 5 - AGE',
		JP: '人物 5 年齢',
		KR: '사람 5 나이',
		CN: '第五人 的 年纪',
	},
	person_6_age: {
		EN: 'PASSENGER 6 - AGE',
		JP: '人物 6 年齢',
		KR: '사람 6 성별',
		CN: '第六人 的 年纪',
	},
	person_7_age: {
		EN: 'PASSENGER 7 - AGE',
		JP: '人物 7 年齢',
		KR: '사람 7 나이',
		CN: '第七人 的 年纪',
	},
	person_type: {
		EN: 'I AM A',
		JP: '私は',
		KR: '본인은',
		CN: '我是',
	},
	reasons: {
		EN: 'WHAT CONVINCED YOU TO TRAVEL TO THE CNMI? (MARK ALL THAT APPLY)',
		JP: 'CNMIへの旅行を決めた理由は何ですか？（該当するものをすべて選択）',
		KR: '사이판 여행을 결정하게 된 이유: (모두 선택)',
		CN: '您为什么决定前往北马里亚纳群岛联邦',
	},
	staying_location: {
		EN: 'WHERE WILL YOU STAY WHILE IN THE CNMI?',
		JP: 'CNMIでの滞在先はどこですか？',
		KR: 'CNMI에서의 체류 장소는 어디입니까?',
		CN: '在北马里亚纳群岛联邦期间您将住在哪里？',
	},
	travel_arrangement: {
		EN: 'DESCRIBE HOW YOUR TRAVEL WAS ARRANGED',
		JP: '旅行の手配方法を説明してください',
		KR: '여행을 어떻게 준비했나요?',
		CN: '您的旅行是如何安排的',
	},
	trip_count: {
		EN: 'THIS TRIP TO CNMI IS MY',
		JP: 'CNMIへのこの旅行は私の',
		KR: 'CNMI 가는 이번여행은',
		CN: '这次去北马里亚纳群岛联邦是我的',
	},
	trip_length_rota: {
		EN: 'LENGTH OF STAY IN ROTA',
		JP: 'ロタでの滞在期間',
		KR: '로타에서의 숙박 기간',
		CN: '在罗塔的停留时间',
	},
	trip_length_saipan: {
		EN: 'LENGTH OF STAY IN SAIPAN',
		JP: 'サイパンでの滞在期間',
		KR: '사이판 체류 기간',
		CN: '在塞班岛的停留时间',
	},
	trip_length_tinian: {
		EN: 'LENGTH OF STAY IN TINIAN',
		JP: 'テニアンでの滞在期間',
		KR: '티니안에서의 숙박 기간',
		CN: '在天宁岛的停留时间',
	},
	Day_Trip: {
		EN: 'Day Trip',
		JP: '日帰り',
		KR: '일일 여행',
		CN: '一日游',
	},
	'1_Night': {
		EN: '1 Night',
		JP: '1泊',
		KR: '1 박',
		CN: '一夜',
	},
	'2_Nights': {
		EN: '2 Nights',
		JP: '2泊',
		KR: '2 박',
		CN: '二夜',
	},
	'3_Nights': {
		EN: '3 Nights',
		JP: '3泊',
		KR: '3 박',
		CN: '三夜',
	},
	'4_Nights': {
		EN: '4 Nights',
		JP: '4泊',
		KR: '4 박',
		CN: '四夜',
	},
	'5_Nights': {
		EN: '5 Nights',
		JP: '5泊',
		KR: '5 박',
		CN: '五夜',
	},
	'6_Nights': {
		EN: '6 Nights',
		JP: '6泊',
		KR: '6 박',
		CN: '六夜',
	},
	'7_-_10 Nights': {
		EN: '7 - 10 Nights',
		JP: '7〜10泊',
		KR: '7 - 10 박',
		CN: '7 至 10 夜',
	},
	'11+_Nights': {
		EN: '11+ Nights',
		JP: '11泊以上',
		KR: '11 박 이상',
		CN: '十一夜 + ',
	},
	'N/A': {
		EN: 'N/A',
		JP: 'N/A',
		KR: '없음',
		CN: '不适用',
	},
	EACH_ARRIVING: {
		EN: 'Each arriving traveler or responsible family member must complete a customs declaration. Information on the customs declaration is required to administer customs, quarantine, statistical, wildlife, and other laws of the CNMI. It is authorized by legislation and will be disclosed only to agencies administering these areas and authorized to receive it under CNMI law. Each arriving traveler or responsible family member must provide the following information (only ONE written declaration per family is required). The term “family” is defined as “members of a family residing in the same household who are related by blood, marriage, domestic relationship, or adoption“.',
		JP: 'すべての到着旅客または責任を負う家族の一員は、税関申告書を提出しなければなりません。税関申告書の情報は、CNMI の税関、検疫、統計、野生生物保護、その他の法律を施行するために必要です。この申告は法令により承認されており、CNMI 法に基づき情報の受領が認められている機関にのみ開示されます。到着旅客または責任を負う家族の一員は、以下の情報を入力する必要があります (家族 1 世帯につき 1 枚の申告書で可)。「家族」とは、「血縁、婚姻、事実婚、養子縁組により同一世帯に居住する家族員」を指します。',
		KR: '각 도착 여행자 또는 책임 가족 구성원은 세관 신고서를 작성해야 합니다. 세관 신고서에 대한 정보는 CNMI의 세관, 검역, 통계, 야생 동물 및 기타 법률을 관리하기 위해 필요합니다. 법률에 의해 승인되어 이러한 지역을 관리하는 기관에만 공개되며 CNMI 법률에 따라 받을 수 있는 권한이 부여됩니다. 각 도착 여행자 또는 책임 가족 구성원은 다음 정보를 제공해야 합니다(가족당 하나의 서면 신고서만 필요합니다). 가족이란 "혈연, 결혼, 가정 관계 또는 입양으로 인한 친족"으로 정의됩니다.',
		CN: '每位到达的旅客或负责的家庭成员必须填写一份海关申报单。海关申报单上的信息是为了执行关税、检疫、统计、野生动植物及北马里亚纳群岛联邦（CNMI）其他法律而必需的。此申报由立法授权，并且只会向负责这些领域并根据CNMI法律被授权接收此信息的机构披露。每位到达的旅客或负责的家庭成员必须提供以下信息（每个家庭只需填写一份书面申报）。术语“家庭”定义为“同一住所居住的、通过血缘、婚姻、家庭关系或收养关联的家庭成员”。',
	},
	DIVISION_CUSTOM: {
		EN: 'The Division of Customs Biosecurity is responsible for protecting the Commonwealth of the Northern Mariana Islands (CNMI) against the illegal importation of prohibited items. Officers have the authority to question you and to examine you and your personal property. If you are one of the travelers selected for an examination, you will be treated with courtesy and professionalism.',
		JP: '税関動植物検疫課は、北マリアナ諸島連邦 (CNMI) を禁止物品の不法輸入から守る任務を負っています。係官は、質問をする権限を持ち、本人および持ち物検査を行うことができます。検査対象に選ばれた旅行者であっても、丁寧で専門的な対応を受けられます。',
		KR: '생물보안국은 금지 물품의 불법적인 수입으로부터 북마리아나 제도의 영연방(CNMI)을 보호할 책임이 있습니다. 경찰관들은 여러분을 심문하고 여러분과 여러분의 개인 재산을 조사할 권한이 있습니다. 만약 여러분이 검사를 받기 위해 선택된 여행객 중 한 명이라면, 여러분은 예의와 전문성을 갖춘 대우를 받을 것입니다.',
		CN: '海关生物安全处负责保护北马里亚纳群岛联邦（CNMI）免受非法进口禁止物品的侵害。官员有权质询您，并检查您和您的个人财产。如果您是被选中进行检查的旅客，您将得到礼貌和专业的对待。',
	},
	COMPLETING_DECLARATION: {
		EN: 'In completing this declaration form, you are asked to be truthful. Residents/Intended Residents and US Citizens must declare all articles acquired abroad and are bringing into the CNMI. Visitors (Non-Residents) must declare the value (U.S. dollars) of all articles that will remain in the CNMI.',
		JP: 'この申告書を記入する際には、正直な申告が求められます。居住者/居住予定者および米国市民は、海外で購入して CNMI に持ち込むすべての物品を申告する必要があります。訪問者 (非居住者) は、CNMI に残すすべての物品の価額 (米国ドル) を申告する必要があります。',
		KR: '이 신고서 양식을 작성하는 데 진실성을 요구합니다. 거주자/거주자 및 미국 시민권자는 해외에서 취득한 모든 기사를 CNMI에 신고해야 합니다. 방문자(비거주자)는 CNMI에 남아 있는 모든 기사의 가치(미화)를 신고해야 합니다.',
		CN: '在填写此申报表时，您被要求诚实。居民/预期居民和美国公民必须申报所有在国外获取并带入CNMI的物品。游客（非居民）必须申报所有将留在CNMI的物品的价值（美元）',
	},
	ALL_ARRIVING: {
		EN: 'All arriving passengers must truthfully declare all articles, including gifts. Passengers are also reminded that 1.) Excise Tax will be determined by the officers and collected, if applicable. 2.) Agricultural and Wildlife Products—To prevent the entry of dangerous agricultural pests and prohibited wildlife, the following are restricted: fruits, vegetables, plants, plant products, soil, meat, meat products, birds, snails, and other live animals or animal products. Failure to declare such items can result in penalties and the items may be subject to seizure. And 3.) Importation of controlled substances, obscene or pornographic materials and hazardous items are prohibited.',
		JP: '到着旅客全員は、贈り物も含め、すべての物品を正直に申告しなければなりません。また、旅客は次の点にも注意が必要です。1.) 関税は係官が判断し、該当する場合、徴収されます。2.) 農産物および野生生物製品 - 危険な農作物害虫や禁止されている野生生物の侵入を防ぐため、果物、野菜、植物、植物製品、土壌、肉、肉製品、鳥、カタツムり、その他の生きた動物や動物製品の持ち込みは制限されています。これらの物品を申告しなかった場合、罰金が科せられ、没収される可能性もあります。3.) 麻薬などの規制薬物、わいせつ物、危険物の持ち込みは禁止されています。',
		KR: '도착하는 모든 승객은 선물을 포함한 모든 물품을 진실되게 신고해야 합니다. 승객들은 또한 1) 소비세는 해당되는 경우 경찰관이 결정하고 징수한다는 점을 명심해야 합니다. 2) 농산물 및 야생동물 제품—위험한 농업 해충 및 금지된 야생동물의 유입을 방지하기 위해 과일, 채소, 식물, 식물 제품, 토양, 육류, 육류 제품, 조류, 달팽이 및 기타 살아있는 동물 또는 동물 제품이 제한됩니다. 이러한 품목을 신고하지 않으면 벌금이 부과될 수 있으며 품목은 압류 대상이 될 수 있습니다. 그리고 3) 통제 물질, 음란하거나 포르노적인 물질 및 위험한 품목의 수입이 금지됩니다.',
		CN: '所有到达的乘客必须诚实申报所有物品，包括礼物。乘客还需注意：1）消费税将由官员确定并在适用情况下收取。2）农业和野生动物产品——为了防止危险的农业害虫和禁止的野生动物进入，以下物品受到限制：水果、蔬菜、植物、植物产品、土壤、肉类、肉类产品、鸟类、蜗牛以及其他活体动物或动物产品。未申报这些物品可能会导致处罚，且这些物品可能会被没收。3）进口受控物质、淫秽或色情材料和危险物品是禁止的。',
	},
	BY_CLICK: {
		EN: 'By clicking on the box below, I consent to answer to all questions truthfully. I understand that this form is subject to change based on CNMI legislative or policy reasons or federal guidelines.',
		JP: '以下のボックスをクリックすると、すべての質問に正直に答えることに同意します。このフォームは、CNMI の立法または政策上の理由、または連邦政府のガイドラインに基づき変更される可能性があることを理解しています。',
		KR: '아래 상자를 클릭함으로써 모든 질문에 진실하게 답변하는 것에 동의합니다. 이 양식은 CNMI 입법 또는 정책적 이유 또는 연방 지침에 따라 변경될 수 있음을 이해합니다.',
		CN: '通过点击下面的框，我同意诚实回答所有问题。我理解此表格可能因CNMI立法或政策原因或联邦指导方针而变更。',
	},
	AIR: {
		EN: 'AIR (Airline)',
		JP: '航空機',
		KR: '항공',
		CN: '飞机',
	},
	SEA: {
		EN: 'SEA (Ship or Boat)',
		JP: '船舶情報',
		KR: '해상',
		CN: '海',
	},
	SELECT: {
		EN: '- Select -',
		JP: '-選択-',
		KR: '선택',
		CN: '选择',
	},
	Male: {
		EN: 'Male',
		JP: '男性',
		KR: '남성',
		CN: '男',
	},
	Female: {
		EN: 'Female',
		JP: '女性',
		KR: '여성',
		CN: '女',
	},
	Business: {
		EN: 'Business',
		JP: 'ビジネス目的',
		KR: '사업',
		CN: '商务',
	},
	Pleasure: {
		EN: 'Pleasure',
		JP: '楽しみ',
		KR: '개인 여행',
		CN: '乐趣',
	},
	Intended_Resident: {
		EN: 'Intended Resident',
		JP: '意図された居住者',
		KR: '거주 예정자',
		CN: '拟定居民',
	},
	Returning_Resident: {
		EN: 'Returning Resident',
		JP: '帰国者',
		KR: '귀국 거주자',
		CN: '返乡居民',
	},
	Flight_Crew_Member: {
		EN: 'Flight Crew Member',
		JP: '運航乗務員',
		KR: '승무원',
		CN: '机组成员',
	},
	Other: {
		EN: 'Other',
		JP: 'その他',
		KR: '기타',
		CN: '其他',
	},
	Commercial: {
		EN: 'Commercial (Airlines)',
		JP: '商業',
		KR: '상업용',
		CN: '私人',
	},
	Private: {
		EN: 'Private (Personal)',
		JP: '私設',
		KR: '개인용',
		CN: '商业',
	},
	I_AM_BRINGING: {
		EN: 'I AM (WE ARE) BRINGING TO THE CNMI',
		JP: 'CNMIに持参します',
		KR: '세관 신고 사항',
		CN: '我(我们) 带入北马里亚纳群岛联邦',
	},
	YES: {
		EN: 'YES',
		JP: 'はい',
		KR: '예',
		CN: '是',
	},
	NO: {
		EN: 'NO',
		JP: 'いいえ',
		KR: '아니요',
		CN: '不',
	},
	1: {
		EN: 'Passenger 1 (self)',
		JP: '1',
		KR: '1',
		CN: '1',
	},
	2: {
		EN: 'Passenger 2',
		JP: '2',
		KR: '2',
		CN: '2',
	},
	3: {
		EN: 'Passenger 3',
		JP: '3',
		KR: '3',
		CN: '3',
	},
	4: {
		EN: 'Passenger 4',
		JP: '4',
		KR: '4',
		CN: '4',
	},
	5: {
		EN: 'Passenger 5',
		JP: '5',
		KR: '5',
		CN: '5',
	},
	6: {
		EN: 'Passenger 6',
		JP: '6',
		KR: '6',
		CN: '6',
	},
	7: {
		EN: 'Passenger 7',
		JP: '7',
		KR: '7',
		CN: '7',
	},
	Japan: {
		EN: 'Japan',
		JP: '日本',
		KR: '일본',
		CN: '日本',
	},
	Korea: {
		EN: 'Korea',
		JP: '韓国',
		KR: '대한민국',
		CN: '韩国',
	},
	China: {
		EN: 'China',
		JP: '中国',
		KR: '중국',
		CN: '中国',
	},
	Guam: {
		EN: 'Guam',
		JP: 'グアム',
		KR: '괌',
		CN: '关岛',
	},
	Palau: {
		EN: 'Palau',
		JP: 'パラオ',
		KR: '팔라우',
		CN: '帕劳',
	},
	Philippines: {
		EN: 'Philippines',
		JP: 'フィリピン',
		KR: '필리핀',
		CN: '菲律宾',
	},
	Australia: {
		EN: 'Australia',
		JP: 'オーストラリア',
		KR: '호주',
		CN: '澳洲',
	},
	Singapore: {
		EN: 'Singapore',
		JP: 'シンガポール',
		KR: '싱가폴',
		CN: '新加坡',
	},
	Hong_Kong: {
		EN: 'Hongkong',
		JP: '香港',
		KR: '홍콩',
		CN: '香港',
	},
	Canada: {
		EN: 'Canada',
		JP: 'カナダ',
		KR: '캐나다',
		CN: '加国',
	},
	Malaysia: {
		EN: 'Malaysia',
		JP: 'マレーシア',
		KR: '말레이시아',
		CN: '马来西亚',
	},
	Taiwan: {
		EN: 'Taiwan',
		JP: '台湾',
		KR: '대만',
		CN: '台湾',
	},
	Europe: {
		EN: 'Europe',
		JP: 'ヨーロッパ',
		KR: '유럽',
		CN: '欧洲',
	},
	Russia: {
		EN: 'Russia',
		JP: 'ロシア',
		KR: '러시아',
		CN: '俄罗斯',
	},
	Visitor_to_the_CNMI: {
		EN: 'Visitor to the CNMI',
		JP: 'CNMIへの訪問者',
		KR: 'CNMI 방문객',
		CN: '北马里亚纳群岛联邦的访客',
	},
	Returning_CNMI_Resident: {
		EN: 'Returning CNMI Resident',
		JP: 'CNMI 居住者帰国者',
		KR: 'CNMI로 귀국하는 거주인',
		CN: '返回北马里亚纳群岛联邦的居民',
	},
	Intended_Resident_moving_to_the_CNMI_for_over_30_days: {
		EN: 'Intended Resident moving to the CNMI for over 30 days',
		JP: 'CNMIに30日以上滞在する予定の居住者',
		KR: 'CNMI에서 30일 이상 거주 예정',
		CN: '计划移民到北马里亚纳群岛联邦超过30天',
	},
	'Visitor_returning_from_a_day/overnight_trip_to_another_island': {
		EN: 'Visitor returning from a day/overnight trip to another island',
		JP: '別の島への日帰り/一泊旅行から帰国する訪問者',
		KR: '다른 섬에서의 하루/일박 여행해서 돌아오는 방문객',
		CN: '从另一座岛屿的日/夜游返回的游客',
	},
	Hotel: {
		EN: 'Hotel',
		JP: 'ホテル',
		KR: '호텔',
		CN: '旅馆',
	},
	Cruise_Ship: {
		EN: 'Cruise Ship',
		JP: 'クルーズ船',
		KR: '크루즈',
		CN: '游轮',
	},
	Military_Ship: {
		EN: 'Military Ship',
		JP: '軍艦',
		KR: '군함',
		CN: '军舰',
	},
	Timeshare: {
		EN: 'Timeshare',
		JP: 'タイムシェア',
		KR: '타임쉐어',
		CN: '分时度假',
	},
	'Bed_&_Breakfast': {
		EN: 'Bed & Breakfast',
		JP: 'ベッド＆ブレックファースト',
		KR: '배드앤 브랙퍼스트',
		CN: '床和早餐',
	},
	Vacation_Rental: {
		EN: 'Vacation Rental',
		JP: 'バケーションレンタル',
		KR: '베케이션 렌탈',
		CN: '度假出租',
	},
	'Friends/Relatives': {
		EN: 'Friends/Relatives',
		JP: '友人/親戚',
		KR: '친구/친지',
		CN: '朋友/亲戚',
	},
	'1st': {
		EN: '1st',
		JP: '1回目',
		KR: '첫 번쨰',
		CN: '第一次',
	},
	'2nd': {
		EN: '2nd',
		JP: '2回目',
		KR: '두 번째',
		CN: '第二次',
	},
	'3rd': {
		EN: '3rd',
		JP: '3回目',
		KR: '세 번째',
		CN: '第三次',
	},
	'4th': {
		EN: '4th',
		JP: '4回目',
		KR: '네 번째',
		CN: '第四次',
	},
	'5th': {
		EN: '5th',
		JP: '5回目',
		KR: '다섯 번쨰',
		CN: '第五次',
	},
	'6th+': {
		EN: '6th+',
		JP: '6回以上',
		KR: '여섯 번 이상',
		CN: '第六次+',
	},
	'Pleasure/Vacation': {
		EN: 'Pleasure/Vacation',
		JP: '楽しみ/バケーション',
		KR: '즐거움/휴가',
		CN: '假期',
	},
	'Meeting/Conference': {
		EN: 'Meeting/Conference',
		JP: '会議/カンファレンス',
		KR: '미팅/컨퍼런스',
		CN: '会议/大会',
	},
	'Visit_Friends/Relatives': {
		EN: 'Visit_Friends/Relatives',
		JP: '友人/親戚を訪問',
		KR: '친구/친척 방문',
		CN: '认识朋友/亲戚',
	},
	Sports_Event: {
		EN: 'Sports Event',
		JP: 'スポーツイベント',
		KR: '스포츠 이벤트',
		CN: '体育项目',
	},
	School_Trip: {
		EN: 'School Trip',
		JP: '学校の旅行',
		KR: '학교 여행',
		CN: '校园旅行',
	},
	Company_Trip: {
		EN: 'Company Trip',
		JP: '会社の旅行',
		KR: '회사 여행',
		CN: '公司旅行',
	},
	Honeymoon: {
		EN: 'Honeymoon',
		JP: '新婚旅行',
		KR: '신혼 여행',
		CN: '蜜月',
	},
	Get_Married: {
		EN: 'Get Married',
		JP: '結婚式',
		KR: '결혼',
		CN: '结婚',
	},
	Golf: {
		EN: 'Golf',
		JP: 'ゴルフ',
		KR: '골프',
		CN: '高尔夫球',
	},
	Dive: {
		EN: 'Dive',
		JP: 'ダイビング',
		KR: '다이빙',
		CN: '跳水',
	},
	Travel_Agent: {
		EN: 'Travel Agent',
		JP: '旅行代理店',
		KR: '여행사',
		CN: '旅行社',
	},
	Short_Flight_Time: {
		EN: 'Short Flight Time',
		JP: '短い飛行時間',
		KR: '단거리 비행 시간',
		CN: '飞行时间短',
	},
	Climate: {
		EN: 'Climate',
		JP: '気候',
		KR: '기후',
		CN: '气候',
	},
	Internet: {
		EN: 'Internet',
		JP: 'インターネット',
		KR: '인터넷',
		CN: '互联网',
	},
	Previous_Trip: {
		EN: 'Previous Trip',
		JP: '前回の旅行',
		KR: '이전 여행',
		CN: '上次旅行',
	},
	// end customs and mva form
	// start steps
	STEP_1: {
		EN: 'Step 1:',
		JP: 'ステップ１:',
		KR: '1단계:',
		CN: '步驟 1:',
	},
	STEP_2: {
		EN: 'Step 2:',
		JP: 'ステップ２:',
		KR: '2단계:',
		CN: '步驟 2:',
	},
	SELECT_LANGUAGE: {
		EN: 'SELECT LANGUAGE',
		JP: '言語を選択する',
		KR: '언어 선택',
		CN: '选择语言',
	},
	SELECT_ACCOUNT_TYPE: {
		EN: 'SELECT ACCOUNT TYPE ',
		JP: 'アカウントの種類を選択する',
		KR: '계정 유형 선택',
		CN: '选择账户类型',
	},
	GUEST_ACCESS: {
		EN: 'Guest Access:',
		JP: 'ゲストアクセス:',
		KR: '게스트 액세스:',
		CN: '访客访问:',
	},
	GUEST_ACCESS_DESCRIPTION: {
		EN: 'Continue as a guest without creating an account.',
		JP: 'アカウントを作成せずにゲストとして続行する.',
		KR: '계정을 만들지 않고 게스트로 계속 진행합니다.',
		CN: '以访客继续 无需创建账户.',
	},
	FREQUENT_FLYER: {
		EN: 'Frequent Flyer:',
		JP: 'フリークエントフライヤー:',
		KR: '상용 고객 우대/ Frequent Flyer:',
		CN: '常旅客:',
	},
	FREQUENT_FLYER_SIGNUP: {
		EN: 'Sign Up as a Frequent Flyer:',
		JP: 'フリークエントフライヤーとして登録する:',
		KR: 'Frequent Flyer로 가입하기:',
		CN: '注册成为常旅客:',
	},
	FREQUENT_FLYER_DESCRIPTION: {
		EN: 'Sign up to easily fill out future trip declarations.',
		JP: '登録し、今後の旅行の申告書を簡単に入力する.',
		KR: '미래 여행 신고서를 쉽게 작성하기 위해 가입하세요.',
		CN: '注册填写 行程声明.',
	},
	GUEST_ACCESS_ONE: {
		EN: 'Click Guest.',
		JP: '「ゲスト」をクリックする.',
		KR: 'Guest를 클릭합니다.',
		CN: '点击“访客”.',
	},
	GUEST_ACCESS_TWO: {
		EN: 'Fill out the forms.',
		JP: 'フォームを入力する.',
		KR: '양식을 작성합니다.',
		CN: '填写表格.',
	},
	GUEST_ACCESS_THREE: {
		EN: 'Save your QR code.',
		JP: 'QRコードを保存する.',
		KR: 'QR 코드를 저장합니다.',
		CN: '保存您的二维码.',
	},
	GUEST_ACCESS_FOUR: {
		EN: 'Present your QR code to a customs officer at baggage claim.',
		JP: '手荷物受取所で税関職員にQRコードを提示する.',
		KR: '수하물 찾는 곳에서 세관 직원에게 QR 코드를 제시합니다.',
		CN: '出示您的二维码给海关官员在行李领取处.',
	},
	FREQUENT_FLYER_ONE: {
		EN: 'Enter Your Email.',
		JP: 'メールアドレスを入力する.',
		KR: '이메일을 입력합니다.',
		CN: '输入你的电子邮箱.',
	},
	FREQUENT_FLYER_TWO: {
		EN: 'One-Time-Password (OTP) sent to your email.',
		JP: 'ワンタイムパスワード（OTP)が電子メールに送信される.',
		KR: '이메일로 일회용 비밀번호(OTP)가 전송됩니다.',
		CN: 'OTP(一次性密码) 已发送至您的电子邮箱.',
	},
	FREQUENT_FLYER_THREE: {
		EN: 'Enter OTP.',
		JP: 'OTPを入力する.',
		KR: 'OTP를 입력합니다.',
		CN: '输入 OTP(一次性密码).',
	},
	FREQUENT_FLYER_FOUR: {
		EN: 'Create your first trip.',
		JP: '新規の旅行を作成する.',
		KR: '첫 여행을 만듭니다.',
		CN: '创建您的第一次旅行.',
	},
	FREQUENT_FLYER_FIVE: {
		EN: 'Fill out the declaration forms.',
		JP: '申告書へ入力する.',
		KR: '신고서를 작성합니다.',
		CN: '填写申报表.',
	},
	FREQUENT_FLYER_SIX: {
		EN: 'Save your QR code.',
		JP: 'QRコードを保存する.',
		KR: 'QR 코드를 저장합니다.',
		CN: '保存您的二维码.',
	},
	FREQUENT_FLYER_SEVEN: {
		EN: 'Present your QR code to a customs officer at baggage claim.',
		JP: '手荷物受取所で税関職員にQRコードを提示する.',
		KR: '수하물 찾는 곳에서 세관 직원에게 QR 코드를 제시합니다.',
		CN: '出示您的二维码给海关官员在行李领取处.',
	},
	FREQUENT_FLYER_REGISTRATION: {
		EN: 'Complete the simple registration.',
		JP: '簡単な登録を進める.',
		KR: '간단한 등록을 완료합니다.',
		CN: '填写登记表格.',
	},
	WE_LOOK_FORWARD: {
		EN: 'We look forward to your visit to the Marianas.',
		JP: '皆様のマリアナへのご訪問を心よりお待ちしております。',
		KR: '당신의 마리아나 방문을 기대하고 환영합니다.',
		CN: '我们期待您来马里亚纳群岛旅游.',
	},
	CONTINUE_FREQUENT_FLYER: {
		EN: 'Continue as Frequent Flyer',
		JP: 'フリークエントフライヤーとして継続する',
		KR: 'Frequent Flyer로 계속',
		CN: '继续作为常旅客',
	},
	LOGIN_SIGNUP_FREQUENT_FLYER: {
		EN: 'Login or Signup as Frequent Flyer:',
		JP: 'フリークエントフライヤーとしてログインまたは登録する:',
		KR: 'Frequent Flyer로 로그인 또는 가입하기:',
		CN: '登录或 注册成为常旅客:',
	},
	BY_CLICKING_EMAIL: {
		EN: 'By clicking Sign in with Email Address, you agree with the Terms of Use and Privacy Notice',
		JP: '「電子メールアドレスでログイン」をクリックし、利用規約とプライバシーに関する通知に同意',
		KR: '이메일 주소로 로그인하면 이용 약관 및 개인정보 보호정책에 동의하게 됩니다',
		CN: '点击使用电子邮件地址登录, 您同意使用条款和隐私声明',
	},
	INSTRUCTIONS: {
		EN: 'INSTRUCTIONS',
		JP: '説明書',
		KR: '지침',
		CN: '指示',
	},
	EDIT: {
		EN: 'EDIT',
		JP: '編集',
		KR: '편집하다',
		CN: '编辑',
	},
	CONFIRM_EMAIL: {
		EN: 'CONFIRM EMAIL',
		JP: 'Eメール確認',
		KR: '이메일 확인',
		CN: '确认电子邮件',
	},
	SELECT_DATE: {
		EN: 'Click to select a date',
		JP: 'クリックして日付を選択します',
		KR: '날짜를 선택하려면 클릭하세요.',
		CN: '单击以选择日期',
	},
	SEND_OTP_CONFIRM: {
		EN: 'Send One-Time-Password (OTP)',
		JP: 'ワンタイムパスワード（OTP）を送信する',
		KR: '일회용 비밀번호(OTP) 보내기',
		CN: '发送一次性密码 (OTP)',
	},
	// end steps
};

export default LANGUAGE;
